import React, { useEffect, useState, Fragment } from 'react'
import { makeStyles, Typography, Grid, CardMedia, Button } from '@material-ui/core'
import { motion, useAnimation } from 'framer-motion'
import { BannerZoom } from '../../Utils/AnimationEffects'
import { useInView } from 'react-intersection-observer'
import renderHTML from 'react-render-html'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { getValueByAttribute } from '../../Utils/DataExtract'
import idx from 'idx'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import { Link as RLink } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  headingText: {
    color: '#454545',
    fontWeight: 'bold',
    fontSize: '1.7rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      textAlign: 'center'
    }
  },
  desc: {
    color: '#454545',
    fontSize: '1rem',
    paddingRight: theme.spacing(2),
    marginBottom: 10,
    marginTop: 15,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
      textAlign: 'center',
      fontSize: '0.85rem',
    }
  },
  teamMemberName: {
    textAlign: 'left',
    lineHeight: '1rem',
    color: '#CC0000',
    fontWeight: 'Bold',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  media: {
    height: 0,
    paddingTop: '70%',
    backgroundSize: '58%',
    backgroundPositionX: '60%',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '72.25%',
      backgroundSize: '60%',
      backgroundPositionX: '50%',
    }
  },
  textBlock: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginTop: theme.spacing(2)
    }
  },
  container: {
    padding: theme.spacing(5),
    height: '100%'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingRight: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(0),
    }
  },

  storyLink: {
    borderRadius: 35,
    color: "#CC0000",
    borderColor: '#CC0000',
    textTransform: 'none',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  },
  readMoreCont: {
    paddingTop: '1.2rem',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  },
  rightArrow: {
    borderRadius: '50px',
    border: '1px solid #444',
    color: '#cc0000',
    background: 'white',
    marginLeft: '5px',
  },
  moreLink: {
    textDecoration: 'none',
    marginTop: theme.spacing(1)
  }
}))

export default function DitloV1(props) {
  const { component, desktop } = props
  const gvalues = idx(component, (_) => _.attributes.values)
  const testimonials = idx(gvalues, (_) => _.testimonials)
  const controls = useAnimation()
  const [ref, inView] = useInView({ triggerOnce: false })
  const [readMoreFlag, setReadMoreFlag] = useState([])


  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        controls.start('visible')
      }, 300)
    }
  }, [controls, inView])

  const classes = useStyles()

  const toggleReadMore = (key) => {
    setReadMoreFlag(readMoreFlag.includes(key) ? readMoreFlag.filter(keyin => key!== keyin) : [...readMoreFlag, key])
  }

  return (
    <>
      {testimonials && testimonials.filter(testimonial => !(testimonial.archived && testimonial.archived[0])).map((values, key) => {
        return (
          <Fragment key={key}>
            <div
              style={{
                backgroundImage: `url(${desktop ? getValueByAttribute(values, 'desktop_background_image') : getValueByAttribute(values, 'mobile_background_image')
                  })`,
                backgroundSize: desktop ? 'cover' : '100%',
                backgroundRepeat: 'no-repeat',
                backgroundColor: '#efefef',
                minHeight: 350,
                marginBottom: 0,
                marginTop: 10,
                cursor: 'auto',

              }}
              ref={ref}
            >
              <Grid
                container
                alignItems='center'
                alignContent='center'
                className={classes.container}
              >
                {getValueByAttribute(values, 'team_member_image') &&
                  <Grid item xs={12} md={4}>
                    <div>
                      <CardMedia image={getValueByAttribute(values, 'team_member_image')} className={classes.media} />
                    </div>
                  </Grid>
                }

                <Grid item xs={12} md={7} className={classes.textBlock}>
                  {!desktop ? (
                    <motion.div
                      className={classes.textContainer}
                      animate={controls}
                      initial='hidden'
                      {...BannerZoom.mobile}
                    >
                      <Typography className={classes.headingText}>{renderHTML(getValueByAttribute(values, 'heading'))}</Typography>
                      {getValueByAttribute(values, 'team_member_name') && (
                        <Typography className={classes.teamMemberName}>
                          {getValueByAttribute(values, 'team_member_name')}
                        </Typography>
                      )}
                      {getValueByAttribute(values, 'designation') && (
                        <Typography>
                          {getValueByAttribute(values, 'designation')}
                        </Typography>
                      )}
                      {getValueByAttribute(values, 'description') && (
                        <Typography className={classes.desc}>
                          {getValueByAttribute(values, 'description')}
                        </Typography>
                      )}
                      <br />
                      {getValueByAttribute(values, 'read_more_content') && (
                        <Button variant="outlined" className={classes.storyLink} disableElevation onClick={() => toggleReadMore(key)}>
                          {getValueByAttribute(values, 'read_more_label')}
                          {readMoreFlag && readMoreFlag.includes(key) ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                        </Button>
                      )}
                    </motion.div>
                  ) : (
                    <motion.div
                      animate={controls}
                      initial='hidden'
                      {...BannerZoom.mobile}
                    >
                      <Typography className={classes.headingText}>{renderHTML(getValueByAttribute(values, 'heading'))}</Typography>
                      {getValueByAttribute(values, 'team_member_name') && (
                        <Typography className={classes.teamMemberName}>
                          {getValueByAttribute(values, 'team_member_name')}
                        </Typography>
                      )}
                      {getValueByAttribute(values, 'designation') && (
                        <Typography>
                          {getValueByAttribute(values, 'designation')}
                        </Typography>
                      )}
                      {getValueByAttribute(values, 'description') && (
                        <Typography className={classes.desc}>
                          {getValueByAttribute(values, 'description')}
                        </Typography>
                      )}
                      <br />
                      {getValueByAttribute(values, 'read_more_content') && (
                        <Button variant="outlined" className={classes.storyLink} disableElevation onClick={() => toggleReadMore(key)}>
                          {getValueByAttribute(values, 'read_more_label')}
                          {readMoreFlag && readMoreFlag.includes(key) ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                        </Button>
                      )}
                    </motion.div>
                  )}
                </Grid>
              </Grid>
            </div>
            {getValueByAttribute(values, 'read_more_content') && readMoreFlag && readMoreFlag.includes(key) && (
              <div className={classes.readMoreCont}>
                {renderHTML(getValueByAttribute(values, 'read_more_content'))}
              </div>
            )}
          </Fragment>
        )
      })}
      {getValueByAttribute(gvalues, 'page_link') && (
        <Grid item sm={8} xs={11} className={classes.storyBox}>
          <Typography className={classes.moreLink}>
            <RLink to={getValueByAttribute(gvalues, 'page_link').slug}>{getValueByAttribute(gvalues, 'link_label')}<ArrowRightAltIcon className={classes.rightArrow} /></RLink>
          </Typography>
        </Grid>
      )}
    </>
  )
}
