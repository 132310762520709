import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import 'react-html5video/dist/styles.css';
import apiConfig from '../../../apiConfig';
import DOMPurify from 'dompurify';
const useStyles = makeStyles((theme) => ({
  videoElement: {
    objectFit: 'initial',
    width: '100%',
    height: '80%',
    padding: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
  },
}));
const VideoInFullPage = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { imagesBucket } = apiConfig;

  const vidsrc = imagesBucket + '/' + DOMPurify.sanitize(searchParams.get('vidsrc')).replace(/[^a-zA-Z0-9-.]/g, '');
  const vttSrc = imagesBucket + '/' + DOMPurify.sanitize(searchParams.get('track')).replace(/[^a-zA-Z0-9-.]/g, '');
  const posterSrc = imagesBucket + '/' + DOMPurify.sanitize(searchParams.get('poster')).replace(/[^a-zA-Z0-9-.]/g, '');
  const classes = useStyles();
  const ref = useRef();
  const playError = (error) => {
    ref.current.currentTime = 0;
    ref.current.load();
  };
  return (
    <video
      playsInline
      className={classes.videoElement}
      autoPlay
      poster={posterSrc}
      crossOrigin='anonymous'
      controls={true}
      disablePictureInPicture
      controlsList='nodownload'
      onError={playError}
      ref={ref}
    >
      <source src={vidsrc} />
      {<track label='English' kind='captions' srcLang='en' src={vttSrc} />}
    </video>
  );
};

export default VideoInFullPage;
