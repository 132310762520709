import React, { Fragment, useState } from 'react'
import { makeStyles, Grid, Card, CardMedia, CardContent, Typography } from '@material-ui/core'
import {getValueByAttribute} from '../../Utils/DataExtract'
import idx from 'idx'

const useStyles = makeStyles((theme) => ({
    featureContainer: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            marginTop: 0,
            paddingBottom: theme.spacing(4),
            paddingTop: theme.spacing(2),
        }
    },
    card: {
        display: 'flex',
        border: 'none',
        height: 200,
        background: 'transparent',
        position: 'relative',
        padding: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            height: 130,
            padding: theme.spacing(0),
        }
    },
    cardWithSep: {
        display: 'flex',
        border: 'none',
        height: 200,
        background: 'transparent',
        padding: theme.spacing(3),
        [theme.breakpoints.up('lg')]: {
            '&::before': {
                height: 122,
                position: 'absolute',
                content: '""',
                borderLeft: '1px solid #707070',
                marginLeft: -30,
                marginTop: 17
            }
        },
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(0),
        },
        [theme.breakpoints.down('xs')]: {
            height: 130,
            position: 'relative',
            '&::before': {
                height: 81,
                position: 'absolute',
                content: '""',
                borderLeft: '1px solid #707070',
                right: '56%',
                top: -32,
                transform: 'rotate(90deg)',
            }
        }
    },
    media: {
        width: '60%',
        backgroundSize: '54%',
        [theme.breakpoints.down('xs')]: {
            width: '50%',
            backgroundSize: '40%',
        }
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        paddingTop: '5%'
    },
    heading: {
        fontSize: '2.5rem',
        fontWeight: 'bold',
        lineHeight: '3rem',
        textAlign: 'left',
        color: '#CC0000',
        [theme.breakpoints.down('xs')]: {
            fontSize: '2rem'
        }
    },
    onlyHeading: {
        fontSize: '2.5rem',
        fontWeight: 'bold',
        lineHeight: '3rem',
        textAlign: 'left',
        color: '#CC0000',
        [theme.breakpoints.down('xs')]: {
            fontSize: '2rem',
            textAlign: 'center',
            marginBottom: 10
        }
    },
    subHeading: {
        fontSize: '1rem',
        lineHeight: '1rem',
        color: '#333333',
        textAlign: 'left',
        paddingTop: 25,
        [theme.breakpoints.down('xs')]: {
            paddingTop: 15,
        }
    },
    seperator: {
        position: 'absolute'
    },
    contentArea: {
        padding: 0,
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2),
            paddingLeft: 0
        }
    },
    tSubHeading: {
        fontSize: '1rem',
        // lineHeight: '1rem',
        // color: '#707070',
        textAlign: 'left',
        // paddingTop: 25,
        [theme.breakpoints.down('xs')]: {
            // paddingTop: 15,
        }
    }
}))

const ShowCase = props => {
    const { component, desktop } = props
    const values = idx(component, (_) => _.attributes.values)
    const features = getValueByAttribute(values, 'features', true)
    const classes = useStyles()
    const [variant] = useState(getValueByAttribute(features[0], 'icon') ? 'image' :'text')
    return (
        <Fragment>
            <Grid container className={classes.featureContainer}>
                {features && features.length > 0 && features.map((feature, index) => {
                    const iconImage = getValueByAttribute(feature, 'icon')
                    const heading = getValueByAttribute(feature, 'heading')
                    const description = getValueByAttribute(feature, 'description')
                    return (
                        <Grid item md={4} xs={12} key={index}>
                            {variant === 'image' ? (
                                <Card className={index > 0 ? classes.cardWithSep : classes.card} elevation={0}>
                                    {iconImage && <CardMedia image={iconImage} className={classes.media} />}
                                    <div className={classes.details}>
                                        <CardContent className={classes.contentArea}>
                                            <Typography className={classes.heading}>{heading}</Typography>
                                            <Typography className={classes.subHeading}>{description}</Typography>
                                        </CardContent>
                                    </div>
                                </Card>
                            ) : (
                                <Card className={index > 0 ? classes.cardWithSep : classes.card} elevation={0}>
                                    <Grid container alignContent={'center'} alignItems={'center'} spacing={desktop ? 0 : 4}>
                                        <Grid item xs={3} md={3}>
                                            <Typography className={classes.onlyHeading}>{heading}</Typography>
                                        </Grid>
                                        <Grid item xs={9} md={9}>
                                            <Typography className={classes.tSubHeading}>{description}</Typography>
                                        </Grid>
                                    </Grid>
                            </Card>
                            )}
                        </Grid>
                    )
                })}
            </Grid>
        </Fragment>
    )
}

export default ShowCase
