import React from 'react'
import { makeStyles, Typography, CardMedia, Chip, Card, CardActionArea, CardContent } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import Moment from 'react-moment'
import { getValueByAttribute } from '../Utils/DataExtract'

const useStyles = makeStyles((theme) => ({
	media: {
		height: 280,
		backgroundColor: '#d3d3d3',
		position: 'relative',
		[theme.breakpoints.down('md')]: {
			height: 280,
		},
		[theme.breakpoints.down('sm')]: {
			height: 350,
		},
		[theme.breakpoints.down('xs')]: {
			height: 250,
		},
	},
	card: {
		borderRadius: 0,
	},
	category: {
		borderRadius: 0,
		background: '#cc0000',
		color: '#fff',
		fontSize: '0.9rem',
		fontWeight: 'bold',
		position: 'absolute',
		bottom: -20,
		left: 22,
		minWidth: 80,
		padding: 22
	},
	blogDescription: {
		lineHeight: '2rem',
		maxHeight: '4rem',
		marginTop: '20px !important',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		wordWrap: 'break-word',
		[theme.breakpoints.down('sm')]: {
			whiteSpace: 'break-spaces',
		},
		[theme.breakpoints.down('xs')]: {
			whiteSpace: 'break-spaces',
		},
	},
	blogDate: {
		lineHeight: '2rem',
	},
}))

const RenderPostItem = (props) => {
	const { post } = props
	const classes = useStyles()

	const history = useHistory()

	const cardClick = (url) => {
		history.push(url)
	}

	return (
		<>
			<Card elevation={0} className={classes.card} onClick={() => cardClick(post.url)}>
				<CardActionArea>
					<CardMedia
						className={classes.media}
						image={
							post.feature_type === 'image' && post.feature_data ? getValueByAttribute(post.feature_data, 'image_url') : post.feature_data ? getValueByAttribute(post.feature_data, 'video_poster') : ''
						}
						title={
							post.feature_type === 'image' && post.feature_data ? getValueByAttribute(post.feature_data, 'alt') : post.feature_data ? getValueByAttribute(post.feature_data, 'alt') : ''
						}
					>
						<Chip label={post.category} className={classes.category} />
					</CardMedia>
					<CardContent>
						<Typography variant="h4" className={classes.blogDescription}>
							{post.title}
						</Typography>
						<Typography variant="body2" color="textSecondary" component="p" className={classes.blogDate}>
							{<Moment format={'MMMM D, YYYY'}>{post.published_time}</Moment>}
						</Typography>
					</CardContent>
				</CardActionArea>
			</Card>
		</>
	)
}

export default RenderPostItem
