import React from 'react'
import { makeStyles, Typography, Grid } from '@material-ui/core'
import TestimonialCard from './TestimonialCard'
import { getValueByAttribute } from '../../Utils/DataExtract'
import idx from 'idx'


const useStyles = makeStyles((theme) => ({
  storyBoxBase: {
    padding: theme.spacing(4)
  },
  heading: {
	textAlign: 'center',
	paddingBottom: '30px',
    marginBottom: theme.spacing(8),
    color: '#333333',
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(3)
    }
  }
}))

const TestimonialGrid = (props) => {
  const { component, desktop } = props
  const values = idx(component, (_) => _.attributes.values)
  const classes = useStyles()
  const cards = getValueByAttribute(values, 'cards', true)

  const background = `url(${encodeURI(
    getValueByAttribute(values, desktop ? 'background_image_for_desktop' : 'background_image_for_mobile', true, 0)
  )})`

  const backgroundStyle = {
    backgroundImage: background,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  }

  return (
    <div style={backgroundStyle} className={classes.storyBoxBase}>
      {getValueByAttribute(values, 'heading') && (
        <Typography variant={'h3'} className={classes.heading}>
          {getValueByAttribute(values, 'heading')}
        </Typography>
      )}
      {getValueByAttribute(values, 'sub_heading') && (
        <Typography className={classes.subHeading}>
          {getValueByAttribute(values, 'sub_heading')}
        </Typography>
      )}
      <Grid
        container
        className={classes.dataContainer}
        spacing={4}
      >
        {cards &&
          cards.length > 0 &&
          cards.map((card, index) => {
            return (
              <Grid item xs={12} md={getValueByAttribute(values, 'card_width') ? parseInt(getValueByAttribute(values, 'card_width')) : 4} key={index}>
                <TestimonialCard card={card} desktop={desktop} />
              </Grid>
            )
          })}
      </Grid>
    </div>
  )
}

export default TestimonialGrid
