import React, { useState } from 'react';
import { makeStyles, CardMedia, Typography, Button, Grid, Link, IconButton } from '@material-ui/core';
import Carousel from 'nuka-carousel';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import classNames from 'classnames';
import idx from 'idx';
import { getValueByAttribute } from '../../Utils/DataExtract';
import Video from '../../ComponentsList/Video/Video';

const useStyles = makeStyles((theme) => ({
  carouselBase: {
    paddingTop: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
    },
  },
  headerArea: {
    marginBottom: -20,
    [theme.breakpoints.down('sm')]: {
      // marginBottom: 20,
      paddingBottom: 30,
      paddingTop: 20,
    },
  },
  headerText: {
    textAlign: 'center',
    color: '#333333',
  },
  subText: {
    color: '#333333',
    textAlign: 'center',
  },
  card: {
    background: '#CC0000',
  },
  carouselHeading: {
    fontSize: 26,
    textAlign: 'left',
    marginBottom: '20px !important',
    color: '#fff',
    lineHeight: '2rem',
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
    },
    /* eslint-disable */
    ['@media(max-width:1150px)']: {
      fontSize: 20,
    },
    /* eslint-enable */
    [theme.breakpoints.down('sm')]: {
      fontSize: 22,
      lineHeight: '1.6rem',
      fontWeight: 'bold',
      marginBottom: 8,
    },
  },
  carouselPara: {
    textAlign: 'left',
    fontWeight: 'normal',
    fontSize: '1rem',
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: '1.6rem',
    },
  },
  textBox: {
    padding: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
      minHeight: 225,
    },
  },
  storyLink: {
    marginTop: 30,
    borderRadius: 35,
    color: '#FFFFFF',
    borderColor: '#FFFFFF',
    fontSize: 16,
    textTransform: 'none',
    [theme.breakpoints.down('sm')]: {
      // display: 'none',
      fontSize: 14,
      marginTop: 20,
    },
  },
  margingLeft: {
    marginLeft: 10,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  previousButton: {
    marginLeft: 100,
    border: '1px solid',
    color: '#333333',
    background: '#fff',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '&:hover': {
      background: 'rgba(256,256,256, 0.7)',
    },
  },
  nextButton: {
    marginRight: 100,
    border: '1px solid',
    color: '#333333',
    background: '#fff',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '&:hover': {
      background: 'rgba(256,256,256, 0.7)',
    },
  },
  previousIcon: {
    color: '#333333',
  },
  nextIcon: {
    color: '#333333',
  },
  pagingDots: {
    top: '30px !important',
  },
  image: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  cardImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
}));

const RenderCard = (props) => {
  const { classes, item, desktop, styles, currentSlideIndex, slideIndex } = props;
  const cardProps = {
    className: classes.card,
    alignItems: 'center',
    justify: 'center',
  };
  const [videoModelOpen, setVideoModelOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);

  const openVideoModel = (item) => {
    console.log('ITEM ', item);
    let videoData = {
      title: getValueByAttribute(item, 'video_title'),
      poster: getValueByAttribute(item, 'video_poster_image'),
      source: getValueByAttribute(item, 'video_source', true),
      tracks: getValueByAttribute(item, 'video_tracks', true),
      transcript: getValueByAttribute(item, 'video_transcript', true),
    };
    setCurrentVideo(videoData);
    setVideoModelOpen(true);
  };

  const closeVideoModel = () => {
    setVideoModelOpen(false);
    setCurrentVideo(null);
  };

  if (!desktop) {
    cardProps.direction = 'column-reverse';
  }
  return (
    <>
      <Grid container {...cardProps} style={desktop && styles ? styles : {}}>
        <Grid item xs={12} md={6} className={classes.textBox}>
          <Typography className={classes.carouselHeading} variant={'h3'}>
            {getValueByAttribute(item, 'heading')}
          </Typography>
          <Typography className={classes.carouselPara}>{getValueByAttribute(item, 'sub_heading')}</Typography>
          {/* showing the button on carousel only if user choose to */}
          {getValueByAttribute(item, 'show_button') !== false && (
            <Link target='_blank' href={getValueByAttribute(item, 'link')} tabIndex={currentSlideIndex === slideIndex ? 0 : -1}>
              <Button variant='outlined' className={classes.storyLink} disableElevation tabIndex={-1}>
                {getValueByAttribute(item, 'button_text', false, 0, 'Learn more')}
              </Button>
            </Link>
          )}

          {getValueByAttribute(item, 'video_source', true) !== undefined && (
            <Button
              variant='outlined'
              className={classNames(classes.storyLink, classes.margingLeft)}
              disableElevation
              tabIndex={-1}
              onClick={() => openVideoModel(item)}
            >
              {getValueByAttribute(item, 'video_play_button_label', false, 0, 'Watch Video')}
            </Button>
          )}
        </Grid>

        <Grid item xs={12} md={6} className={classes.image}>
          <CardMedia
            className={classes.cardImage}
            component='img'
            alt={getValueByAttribute(item, 'carousel_image_alt_text')}
            image={getValueByAttribute(item, 'carousel_image')}
            onClick={() => window.open(getValueByAttribute(item, 'link'))}
          />
        </Grid>
      </Grid>
      <Video open={videoModelOpen} video={currentVideo} close={closeVideoModel} />
    </>
  );
};

const RenderSlides = (props) => {
  const { carousel, classes, desktop, styles, currentSlideIndex, setCurrentSlideIndex } = props;
  const { slideStyles } = styles ? styles : { slideStyles: { height: 550 } };
  const carouselProps = {
    animation: 'zoom',
    cellSpacing: desktop ? 220 : 80,
    wrapAround: true,
    cellAlign: 'center',
    slidesToShow: 2,
    edgeEasing: 'easeLinear',
    // enableKeyboardControls: true,
    autoplay: false,
    framePadding: desktop ? '60px' : '0px',
    transitionMode: 'scroll3d',
    renderCenterLeftControls: ({ previousSlide }) => (
      <IconButton aria-label='Previous Slide' className={classes.previousButton} onClick={previousSlide}>
        <NavigateBeforeIcon className={classes.previousIcon} />
      </IconButton>
    ),
    renderCenterRightControls: ({ nextSlide }) => (
      <IconButton aria-label='Next Slide' className={classes.nextButton} onClick={nextSlide}>
        <NavigateNextIcon className={classes.nextIcon} />
      </IconButton>
    ),
    defaultControlsConfig: {
      pagingDotsContainerClassName: classNames(classes.pagingDots),
    },
    slideIndex: currentSlideIndex,
    afterSlide: (index) => {
      setCurrentSlideIndex(index);
    },
  };
  return (
    <Carousel {...carouselProps}>
      {carousel &&
        carousel.map((item, index) => {
          return (
            <RenderCard
              item={item}
              key={index}
              classes={classes}
              desktop={desktop}
              styles={slideStyles}
              currentSlideIndex={currentSlideIndex}
              slideIndex={index}
            />
          );
        })}
    </Carousel>
  );
};

const ResponsiveCarousel = (props) => {
  const { component, desktop } = props;
  const values = idx(component, (_) => _.attributes.values);
  const { carousel, styles } = values;
  const classes = useStyles();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  return (
    <>
      <div className={classes.carouselBase}>
        {values.heading && (
          <div className={classes.headerArea}>
            <Typography className={classes.headerText} variant={'h3'}>
              {getValueByAttribute(values, 'heading')}
            </Typography>
            <Typography className={classes.subText}>{getValueByAttribute(values, 'sub_heading')}</Typography>
          </div>
        )}
        <RenderSlides
          carousel={carousel}
          classes={classes}
          desktop={desktop}
          styles={styles}
          currentSlideIndex={currentSlideIndex}
          setCurrentSlideIndex={setCurrentSlideIndex}
        />
      </div>
    </>
  );
};

export default ResponsiveCarousel;
