export const FETCH_POST_DATA_IN_PROGRESS = 'FETCH_POST_DATA_IN_PROGRESS'
export const FETCH_POST_DATA_SUCCESS = 'FETCH_POST_DATA_SUCCESS'
export const FETCH_POST_DATA_FAILED = 'FETCH_POST_DATA_FAILED'
export const FETCH_SIMILAR_POSTS_IN_PROGRESS = 'FETCH_SIMILAR_POSTS_IN_PROGRESS'
export const FETCH_SIMILAR_POSTS_SUCCESS = 'FETCH_SIMILAR_POSTS_SUCCESS'
export const FETCH_SIMILAR_POSTS_FAILED = 'FETCH_SIMILAR_POSTS_FAILED'
export const FETCH_RECENT_POSTS_IN_PROGRESS = 'FETCH_RECENT_POSTS_IN_PROGRESS'
export const FETCH_RECENT_POSTS_SUCCESS = 'FETCH_RECENT_POSTS_SUCCESS'
export const FETCH_RECENT_POSTS_FAILED = 'FETCH_RECENT_POSTS_FAILED'
export const GET_POST_CATEGORIES_IN_PROGRESS = 'GET_POST_CATEGORIES_IN_PROGRESS'
export const GET_POST_CATEGORIES_SUCCESS = 'GET_POST_CATEGORIES_SUCCESS'
export const GET_POST_CATEGORIES_FAILED = 'GET_POST_CATEGORIES_FAILED'
export const GET_POSTS_BY_CATEGORIES_IN_PROGRESS = 'GET_POSTS_BY_CATEGORIES_IN_PROGRESS'
export const GET_POSTS_BY_CATEGORIES_SUCCESS = 'GET_POSTS_BY_CATEGORIES_SUCCESS'
export const GET_POSTS_BY_CATEGORIES_FAILED = 'GET_POSTS_BY_CATEGORIES_FAILED'
export const GET_CATEGORY_POSTS_IN_PROGRESS = 'GET_CATEGORY_POSTS_IN_PROGRESS'
export const GET_CATEGORY_POSTS_SUCCESS = 'GET_CATEGORY_POSTS_SUCCESS'
export const GET_CATEGORY_POSTS_FAILED = 'GET_CATEGORY_POSTS_FAILED'
export const GET_TAG_POSTS_IN_PROGRESS = 'GET_TAG_POSTS_IN_PROGRESS'
export const GET_TAG_POSTS_SUCCESS = 'GET_TAG_POSTS_SUCCESS'
export const GET_TAG_POSTS_FAILED = 'GET_TAG_POSTS_FAILED'
