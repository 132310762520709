import React,{useState} from 'react'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove';
import { makeStyles } from '@material-ui/core/styles'
import { getValueByAttribute } from '../../Utils/DataExtract'
import idx from 'idx'
import renderHTML from 'react-render-html'

const useStyles = makeStyles((theme) => ({
  descLink:{
    fontWeight: 'bolder',
},
headingStyle: {
  paddingLeft: theme.spacing(1),
  color: '#333333',
  textAlign: 'left',
  [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
      paddingLeft: 0,
  }
},
  expandIcon:{
    color:'',
  },
  root: {
    maxWidth: '100%',
    paddingLeft: '0px !important',
  },
  heading: {
    color: '#333',
    paddingLeft: '0px !important',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 'bolder',
    letterSpacing: '0.8px',
    lineHeight: '25px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem',
      letterSpacing: '0.5px',
  },
  },
  hr: {
    width: 90,
    textAlign: 'left',
    color: '#cc0000',
    marginLeft: 0,
    height: 3,
    background: '#cc0000',
    border: 0,
    display: 'flex',
    marginTop: 20,
    marginBottom: 28,
    [theme.breakpoints.down('xs')]: {
      marginTop: '20px !important'
    }
  },
  accordionArea: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
  }
}))

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    marginBottom: 7,

    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto',
      marginBottom: 7
    }
  },
  expanded: {}
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    //paddingLeft: '30px',
    minHeight: 78,
    borderBottom: '1px solid #707070',

    '&$expanded': {
      minHeight: 78,
      borderBottom: 'none'
    }
  },
  content: {
    backgroundColor: 'transperent',
    marginLeft: '-15px',
    
    '&$expanded': {
      margin: '12px 0',
      marginLeft: '-15px',
    },
    '& p': {

    },
    '& expandIcon':{
      color:'yellow'
    }
  },
  expanded: {}
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    paddingLeft: 0,
    paddingRight: '0px',
    paddingTop: '12px',
    paddingBottom: '20px',
    borderBottom: '1px solid #707070',
    '& p': {
      color: '#333',
      fontSize:'1rem',
      lineHeight:'30px',
      paddingRight: '32px',
      paddingBottom: '20px',
    },
    [theme.breakpoints.down('xs')]: {
      '& p': {
        fontSize: '1rem',
        lineHeight: '2rem'
      },
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    }
  }
}))(MuiAccordionDetails)

export default function CustomizedAccordions(props) {
  const [expanded, setExpanded] = useState(false);
  const { component } = props
  const accordion = idx(component, (_) => _.attributes.values.accordion)
  const values = idx(component, (_) => _.attributes.values)
  const classes = useStyles()

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (<>
    {getValueByAttribute(values, 'heading') && <Typography className={classes.headingStyle} variant={"h3"}>{getValueByAttribute(values, 'heading')}</Typography>}
    <div className={classes.accordionArea} id={getValueByAttribute(values, 'heading').split(" ")[0].toLowerCase()}>
      <div className={classes.root}>
        {accordion &&
          accordion.map((item, key) => (
            <Accordion key={key} expanded={expanded === key} onChange={handleChange(key)}>
              <AccordionSummary
                expandIcon={expanded === key?<RemoveIcon className={classes.expandIcon}/>:<AddIcon/>}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Typography className={classes.heading} variant={'h4'}>
                  {getValueByAttribute(item, 'accordion_title')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{getValueByAttribute(item, 'accordion_body')}
                {getValueByAttribute(item, 'link') && <><br/><br/><div className={classes.descLink}>{renderHTML(getValueByAttribute(item, 'link'))}</div></>}
                </Typography>
                
              </AccordionDetails>
            </Accordion>
          ))}
      </div>
    </div></>
  )
}
