import React, { useEffect } from 'react'
import { makeStyles, Typography, Divider } from '@material-ui/core'
import ImageBox from './../ImageBox/ImageBox'
import { Link as RLink } from 'react-router-dom'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { motion, useAnimation } from 'framer-motion'
import { TextBoxEffects } from '../../Utils/AnimationEffects'
import { useInView } from 'react-intersection-observer'
import renderHTML from 'react-render-html'

const useStyles = makeStyles((theme) => ({
  textBox: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      marginTop: '0 !important'
    }
  },
  headingStyle: {
    color: '#333333',
    textAlign: 'left',
    paddingBottom: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
      paddingBottom: 0
    }
  },
  descStyle: {
    color: '#333333',
    textAlign: 'left',
    letterSpacing: 0,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 10,
      fontSize: '1rem',
      lineHeight: '30px'
    }
  },
  dividerStyle: {
    border: '2px solid #CC0000',
    marginLeft: 5,
    width: 90,
    height: 0,
    [theme.breakpoints.down('xs')]: {
      border: '2px solid #CC0000',
      marginLeft: 2
    }
  },
  link: {
    textAlign: 'center',
    marginBottom: theme.spacing(6)
  },
  linkButton: {
    border: '1px solid',
    color: '#CC0000',
    borderRadius: 20,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    textDecoration: 'none',
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    }
  },
  rightArrow: {
    verticalAlign: 'middle'
  }
}))

const TextBox = (props) => {
  const { values } = props
  const { heading, subHeading, desc, seperator, styling, imageBox, linkToPage } = values
  const classes = useStyles()
  const controls = useAnimation()
  const [ref, inView] = useInView({ triggerOnce: false })

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        controls.start('visible')
      }, 300)
    }
  }, [controls, inView])

  const deafultHeadingStyle = {}

  const deafultDescStyle = {}

  const defaultDividerStyle = {}

  const defaultTextBoxStyle = {}

  const headingStyle =
    styling && styling.heading
      ? Object.assign({}, deafultHeadingStyle, styling.heading)
      : deafultHeadingStyle
  const descStyle =
    styling && styling.desc
      ? Object.assign({}, deafultDescStyle, styling.desc)
      : deafultDescStyle
  const dividerStyle =
    styling && styling.divider
      ? Object.assign({}, defaultDividerStyle, styling.divider)
      : defaultDividerStyle
  const textBoxStyle =
    styling && styling.textBox
      ? Object.assign({}, defaultTextBoxStyle, styling.textBox)
      : defaultTextBoxStyle

  return (
    <>
      <div ref={ref}>
        <motion.div
          animate={controls}
          initial='hidden'
          {...TextBoxEffects.text}
          className={classes.bannerText}
        >
          <div className={classes.textBox} style={textBoxStyle}>
            {heading && (
              <Typography
                style={headingStyle}
                className={classes.headingStyle}
                variant={'h3'}
              >
                {renderHTML(heading)}
              </Typography>
            )}
            {subHeading && (
                <Typography variant={"h4"} className={classes.subHeading}>{subHeading}</Typography>
            )}
            {seperator && (
              <Divider style={dividerStyle} className={classes.dividerStyle} />
            )}
            {desc && (
              <div className={classes.descStyle} style={descStyle}>
              {/* <Typography style={descStyle} className={classes.descStyle}> */}
                {renderHTML(desc)}
              {/* </Typography> */}
              </div>
            )}
          </div>
        </motion.div>
        <motion.div
          animate={controls}
          initial='hidden'
          {...TextBoxEffects.image}
          className={classes.bannerText}
        >
          {imageBox && <ImageBox values={imageBox} />}
        </motion.div>
        <motion.div
          animate={controls}
          initial='hidden'
          {...TextBoxEffects.text}
          className={classes.bannerText}
        >
          {
          linkToPage && (
            <div className={classes.link}>
              <RLink className={classes.linkButton} to={linkToPage.slug}>
                {linkToPage.label}
                <ArrowRightIcon className={classes.rightArrow} />
              </RLink>
            </div>
          )}
        </motion.div>
      </div>
      {/* <div> {renderHTML(someHTML)}</div> */}
    </>
  )
}

export default TextBox
