import React, { useEffect, useState } from 'react';
import { makeStyles, Typography, Grid, Button } from '@material-ui/core';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { getValueByAttribute } from '../../Utils/DataExtract';
import idx from 'idx';
import { Link as RLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  linktoArchive: {
    textDecoration: 'none',
    color: '#555',

    '&:hover': {
      textDecoration: 'none',
      color: '#555 !important',
    },
  },
  moreStories: {
    marginTop: '10px',
    color: '#333',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 'bolder',
    letterSpacing: '0.8px',
    lineHeight: '25px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem',
      letterSpacing: '0.5px',
    },
  },
  tmImg: {
    minHeight: '100%',
    backgroundPosition: 'top right',

    [theme.breakpoints.up('lg')]: {
      backgroundSize: 'contain',
    },
    [theme.breakpoints.down('lg')]: {
      backgroundSize: 'cover',
    },
    [theme.breakpoints.down('md')]: {
      backgroundSize: 'contain',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundSize: '52%',
      backgroundPosition: 'right top',
      minHeight: 261,
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: 259,
      backgroundSize: '100%',
    },
  },
  border: {
    borderBottom: '1px solid #707070',
    [theme.breakpoints.down('sm')]: {
      borderBottom: 'none',
    },
  },
  ditloWrapRight: {
    minHeight: '297px',
    display: 'flex',
    flexDirection: 'row-reverse',
    backgroundColor: '#f7f7f7',
    margin: '20px 0',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'inherit',
    },
  },
  headingStyle: {
    paddingLeft: theme.spacing(1),
    color: '#333333',
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
      paddingLeft: 0,
    },
  },
  viewAll: {
    textDecoration: 'none',
    backgroundColor: '#CC0000',
    borderRadius: '50%',
    height: '130px',
    width: '130px',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '27px 30px',
    marginBottom: '0px',
    fontWeight: 'bolder',

    [theme.breakpoints.down('md')]: {
      margin: '27px 4px',
    },

    '&:hover': {
      color: '#fff !important',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '50px auto',
      borderRadius: 0,
      minWidth: '300px',
      height: '50px',
    },
  },
  description: {
    marginTop: '13px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '29px',
      lineHeight: '0.8rem',
      textAlign: 'left',
    },
  },
  circle: {
    borderRadius: '50%',
    boxSizing: 'border-box',
    margin: '20px',
    marginBottom: '0px',
    cursor: 'pointer',
    // border: '10px solid #fff',
    '&:before': {
      content: ' ',
      position: 'absolute',
      zIndex: '1',
      top: '25px',
      left: '25px',
      right: '25px',
      bottom: '25px',
      // border: '20px solid #ffea00',
    },
    '&:hover': {
      // border: '10px solid #cc0000',
    },
  },
  imgGrid: {
    display: 'flex',
    paddingBottom: '40px',
    // flexWrap:'nowrap',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '0px',
      flexDirection: 'column',
    },
  },
  storyCircle: {
    margin: '0 10px',
    alignItems: 'center',
    fontSize: '15px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'initial',
      alignItems: 'center',
      // justifyContent: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '0',
    },
  },

  desc: {
    color: '#333',
    fontSize: '1rem',
    paddingRight: theme.spacing(2),
    marginBottom: 10,
    marginTop: 15,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      marginBottom: 10,
      textAlign: 'left',
      lineHeight: '22px',
      fontSize: '1rem',
    },
  },
  teamMemberName: {
    textAlign: 'left',
    fontSize: '18px',
    lineHeight: '1rem',
    color: '#CC0000',
    fontWeight: 'Bold',
  },
  secondaryTeamMemberName: {
    fontWeight: 'bolder',
  },
  media: {
    height: 0,
    paddingTop: '70%',
    backgroundSize: '58%',
    backgroundPositionX: '60%',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '72.25%',
      backgroundSize: '60%',
      backgroundPositionX: '50%',
    },
  },
  textBlock: {
    padding: '30px',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0px',
    },
  },
  container: {
    padding: theme.spacing(5),
    height: '100%',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingRight: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(0),
    },
  },

  storyLink: {
    width: 'max-content',
    borderRadius: 35,
    color: '#CC0000',
    borderColor: '#CC0000',
    textTransform: 'none',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('md')]: {},
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  readMoreCont: {
    paddingTop: '1.2rem',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
}));

export default function TargetTechDitlo(props) {
  const { component, desktop } = props;
  const values = idx(component, (_) => _.attributes.values);
  const url = values.url[0];
  const stories = values.individual_story;
  const controls = useAnimation();
  const [inView] = useInView({ triggerOnce: false });
  const [readMoreFlag, setReadMoreFlag] = useState(false);

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        controls.start('visible');
      }, 300);
    }
  }, [controls, inView, stories]);

  const classes = useStyles();

  const toggleReadMore = () => {
    setReadMoreFlag(!readMoreFlag);
  };
  return (
    <div
      style={{ marginTop: desktop ? 40 : 30, marginBottom: desktop ? '50px' : '30px' }}
      className={stories.length > 1 ? classes.border : ''}
      id={getValueByAttribute(values, 'heading') ? getValueByAttribute(values, 'heading').split('')[0].toLowerCase() : 'ditlo'}
    >
      {getValueByAttribute(values, 'heading') && (
        <Typography className={classes.headingStyle} variant={'h3'}>
          {getValueByAttribute(values, 'heading')}
        </Typography>
      )}
      {stories.length > 0 &&
        stories.map(
          (story) =>
            (!story.archived[0] || story.archived[0] === null) && (
              <Grid container className={classes.ditloWrapRight}>
                {story.team_member_img[0] && (
                  <Grid item xs={12} md={6}>
                    <div
                      style={{
                        backgroundImage: `url(${
                          desktop
                            ? story.featured_img_desktop[0]
                              ? story.featured_img_desktop[0]
                              : story.team_member_img[0]
                            : story.featured_img_mobile[0]
                            ? story.featured_img_mobile[0]
                            : story.mobile_img[0]
                        })`,
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: '#f7f7f7',
                        marginBottom: 0,
                        cursor: 'auto',
                        // backgroundPosition: !desktop ? 'right top' : '',
                      }}
                      className={classes.tmImg}
                    ></div>
                  </Grid>
                )}
                <Grid item xs={12} md={6} className={classes.textBlock}>
                  {story.team_member_name[0] && <Typography className={classes.teamMemberName}>{story.team_member_name[0]}</Typography>}

                  {story.team_member_designation[0] && (
                    <Typography style={{ fontWeight: '200' }}>
                      {story.team_member_designation[0]}
                      {story.team_member_department[0] && <span>, {story.team_member_department[0]}</span>}
                    </Typography>
                  )}

                  {/* {story.team_member_name[0] && (
            <Typography className={classes.teamMemberName}>
                {story.team_member_name[0]}
            </Typography>
            )} */}

                  {/* {(story.team_member_designation[0] story.team_member_department[0]) &&
            <Typography style={{fontWeight: '200'}}>
                {story.team_member_designation[0]}, {story.team_member_department[0]}
            </Typography>
            } */}
                  {story.team_member_desc[0] && <Typography className={classes.desc}>{story.team_member_desc[0]}</Typography>}
                  <br />
                  {story.button_text[0] && (
                    <Button variant='outlined' className={classes.storyLink} disableElevation onClick={toggleReadMore}>
                      <RLink
                        style={{ textDecoration: 'none' }}
                        color='textSecondary'
                        to={story.direct_story_url && story.direct_story_url.length > 0 ? story.direct_story_url[0] : `${url}#${story.team_member_name[0]}`}
                      >
                        {story.button_text[0]}
                        {!readMoreFlag ? <ArrowRightIcon /> : <ArrowRightIcon />}
                      </RLink>
                    </Button>
                  )}
                </Grid>
              </Grid>
            )
        )}
      {stories.length > 1 ? (
        <Typography variant={'h4'} className={classes.moreStories}>
          More stories
        </Typography>
      ) : (
        ''
      )}
      <Grid container className={classes.imgGrid}>
        {stories.length > 0 &&
          stories.map(
            (story, index) =>
              story.archived[0] &&
              index < 4 && (
                <Grid item lg={2} md={3} xs={12}>
                  <RLink
                    className={classes.linktoArchive}
                    to={story.direct_story_url && story.direct_story_url.length > 0 ? story.direct_story_url[0] : `${url}#${story.team_member_name[0]}`}
                  >
                    <div className={classes.storyCircle}>
                      <img alt={story.team_member_name[0]} src={story.team_member_img[0]} width='130' height='130' className={classes.circle} />
                      <div className={classes.description}>
                        <Typography className={classes.secondaryTeamMemberName}>{story.team_member_name}</Typography>
                        <span style={{ fontWeight: '400', fontSize: '15px' }}>
                          {' '}
                          {story.team_member_designation}
                          <br />
                          {story.team_member_department}
                        </span>
                      </div>
                    </div>
                  </RLink>
                </Grid>
              )
          )}
        {stories.length > 1 && !stories[0].direct_story_url ? (
          <RLink md={1} lg={2} className={classes.viewAll} to={`${url}`} color='textSecondary'>
            All stories {!desktop ? <ArrowRightIcon /> : ''}
          </RLink>
        ) : (
          ''
        )}
      </Grid>
    </div>
  );
}
