import React, { useState } from 'react'
import { makeStyles, Card, CardMedia, Typography, Button } from '@material-ui/core'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import idx from 'idx'
import { motion } from 'framer-motion'
import Video from '../../ComponentsList/Video/Video'
import {getValueByAttribute} from '../../Utils/DataExtract'

const SmoothLeftToRight = {
    initial: {
        x: -20,
        y: 0,
        opacity: 0
    },
    animate: {
        x: 0,
        y: 0,
        opacity: 1
    },
    transition: { duration: 0.7 }
}

const useStyles = makeStyles((theme) => ({
    heroBaseStyle: {
        width: '100%',
        height: 550,
        position: 'relative',
        borderRadius: 0,
        background: '#ad0101',
        [theme.breakpoints.down('sm')]: {
            height: 301,
        }
    },
    cardMedia: {
        height: '100%',
    },
    overlay: {
        position: 'absolute',
        top: '19%',
        left: '5%',
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            top: '12%',
            left: '6%',
            width: '39%'
        }
    },
    heroText: {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '3.5rem',
        lineHeight: '5rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem',
            lineHeight: '2.7rem',
        }
    },
    bigText: {
        fontSize: '5.25rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '2.14rem'
        }
    },
    playButton: {
        borderRadius: 30,
        width: '60%',
        marginTop: '5%',
        fontSize: 16,
        '&:focus': {
            outline: '1px dotted'
        },
        textTransform: 'none',
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
            width: '100%',
            height: 35
        }
    },
    playButtonMob: {
        borderRadius: 30,
        width: '60%',
        marginTop: '5%',
        fontSize: 16,
        '&:focus': {
            outline: '1px dotted'
        },
        textTransform: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
            fontSize: 12,
            width: '100%',
            height: 35
        }
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        position: 'relative',
        width: '70%',
        marginLeft: '15%',
        marginTop: 90,
        height: 'auto',
        textTransform: 'none',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            height: 'auto',
            marginLeft: '5%',
        }
    },
    subText: {
        fontSize: '1.3rem',
        width: 290,
        marginTop: 20,
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
            marginTop: 10,
            width: 300,
            lineHeight: '1.4rem'
        }
    }
}));

const HeroWithVideo = props => {
    const { component, desktop } = props
    const values = idx(component, (_) => _.attributes.values)
    const cta_hide = idx(component, (_) => _.attributes.values.cta_hidden_mobile)
	const video_details  = getValueByAttribute(values, 'video_details',0,0)
	
    const [heroModelOpen, setHeroModelOpen] = useState(false)
	const classes = useStyles()
	const [currentVideo, setCurrentVideo] = useState(null)

    const closeVideoModel = () => {
		setHeroModelOpen(false)
        setCurrentVideo(null)
	}
	const openVideoModel = (video_details) => {
		
        let videoData = {
            title: getValueByAttribute(video_details, 'title'),
            poster: getValueByAttribute(video_details, 'poster_image'),
            source: getValueByAttribute(video_details, 'video_file', true),
            tracks: getValueByAttribute(video_details, 'closed_caption_file', true),
            transcript: getValueByAttribute(video_details, 'transcript_file', true),
		}
        setCurrentVideo(videoData)
		setHeroModelOpen(true)
		
    }

    return (
        <>
        <Card className={classes.heroBaseStyle}>
			
		{desktop ? (getValueByAttribute(values, 'desktop_background_image') &&
                <CardMedia className={classes.cardMedia} image={getValueByAttribute(values, 'desktop_background_image')} />) 
			
			: (getValueByAttribute(values, 'mobile_background_image') &&
			<CardMedia className={classes.cardMedia} image={getValueByAttribute(values, 'mobile_background_image')} />)
			
			}

            <div className={classes.overlay}>
                <motion.div
                    {...SmoothLeftToRight}
                >
                <Typography className={classes.heroText} variant={"h2"}>{getValueByAttribute(values, 'heading')} <br /> <span className={classes.bigText}>{getValueByAttribute(values, 'sub_heading')}</span></Typography>
                <Typography color={"primary"} className={classes.subText}>{getValueByAttribute(values, 'description')}</Typography>
                {getValueByAttribute(values, 'video_play_button_text') && <Button id={'hero-play-button'} size="medium" variant="outlined" color="primary" className={cta_hide && cta_hide[0] ? classes.playButtonMob : classes.playButton} endIcon={<PlayArrowIcon />} disableElevation onClick={() => openVideoModel(video_details)}>
					{getValueByAttribute(values, 'video_play_button_text')}
                </Button>}
                </motion.div>
            </div>
        </Card>
        <Video open={heroModelOpen} video={currentVideo} close={closeVideoModel}/>
        </>
    )
}

export default HeroWithVideo