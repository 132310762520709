import React, { Fragment, useState } from 'react'
import { makeStyles, Grid, Card, CardMedia, Typography } from '@material-ui/core'
import { DefaultPlayer as Video } from 'react-html5video'
import 'react-html5video/dist/styles.css'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import AriaModal from 'react-aria-modal'
import Fade from '@material-ui/core/Fade'
import IconButton from '@material-ui/core/IconButton'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'

const useStyles = makeStyles((theme) => ({
    videoTileArea: {
        background: '#F7F7F7',
        padding: theme.spacing(10),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2),
        },
    },
    gridTile: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        background: 'transparent',
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(3),
        }
    },
    tileDesc: {
        fontSize: 16,
        color: '#707070'
    },
    descArea: {
        marginTop: 30
    },
    media: {
        height: 317,
        position: 'relative'
    },
    playButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
        height: 50,
        background: '#fff'
    },
    playButtonIcon: {
        opacity: '0.3'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        position: 'relative',
        width: '60%',
        marginLeft: '20%',
        marginTop: 90,
        height: 'auto',
        [theme.breakpoints.down('xs')]: {
            width: '90%',
            height: 'auto',
            marginLeft: '5%',
        }
    },
    closeButton: {
        top: -40,
        right: -40,
        position: 'absolute',
        '&:focus': {
            // outline: '1px dotted'
        },
        [theme.breakpoints.down('xs')]: {
            top: -15,
            right: -15,
            width: 30,
            height: 30,
        }
    },
    videoElement: {
        padding: theme.spacing(5),
        background: '#efefef',
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2),
        }
    }
}))

const VideoTile = props => {
    const { values } = props
    const { tiles, spacing, backgroundImage } = values
    const classes = useStyles()
    const [videoModelOpen, setVideoModelOpen] = useState(false)
    const [currentVideo, setCurrentVideo] = useState(null)

    const openVideoModel = (currentVideo) => {
        setCurrentVideo(currentVideo)
        setVideoModelOpen(true)
    }

    const closeVideoModel = () => {
        setVideoModelOpen(false)
        setCurrentVideo(null)
    }

    const areaStyle = {
        background: backgroundImage ? `url(${backgroundImage})` : '#F7F7F7',
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat'
    }

    return (
        <Fragment>
            {tiles && tiles.length > 0 && (
                <div className={classes.videoTileArea} style={areaStyle}>
                        <Grid container spacing={spacing} justify="center" alignItems="center">
                            {tiles.map((tile, tileIndex) => {
                                return (
                                    <Grid key={tileIndex} item xs={12} md={tile.width}>
                                        <Card className={classes.gridTile} elevation={0} >
                                            <CardMedia
                                                className={classes.media}
                                                image={tile.poster}
                                            >
                                                <IconButton aria-label="play" className={classes.playButton} onClick={() => openVideoModel(tile.video)}>
                                                    <PlayArrowIcon className={classes.playButtonIcon} />
                                                </IconButton>
                                            </CardMedia>
                                            <div className={classes.descArea}>
                                                <Typography className={classes.tileDesc}>
                                                    {tile.desc}
                                                </Typography>
                                            </div>
                                        </Card>
                                    </Grid>
                                )
                            })}
                        </Grid>
                </div>
            )}
            {videoModelOpen && (
                <div className={classes.videoArea}>
                <AriaModal
                    titleText={currentVideo.title}
                    onExit={() => setVideoModelOpen(false)}
                    verticallyCenter={true}
                >
                    <Fade in={videoModelOpen}>
                        <Fragment>
                            <div className={classes.paper}>
                                <IconButton aria-label="close video" color="primary" onClick={closeVideoModel} className={classes.closeButton}>
                                    <HighlightOffIcon />
                                </IconButton>
                                {currentVideo && currentVideo &&
                                    <Video
                                        className={classes.videoElement}
                                        autoPlay
                                        width={"100%"}
                                        height={"100%"}
                                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen', 'Captions']}
                                        poster={currentVideo.poster ? currentVideo.poster : ''}
                                        onCanPlayThrough={() => {
                                            // Do stuff
                                        }}
                                        crossOrigin={'anonymous'}
                                    >
                                        <source src={currentVideo.source && currentVideo.source[0] ? currentVideo.source[0] : ''} />
                                        {currentVideo.tracks &&  currentVideo.tracks.length > 0 && (
                                            <track label="English" kind="subtitles" srcLang="en" src={currentVideo.tracks[0]} /> 
                                        )}
                                    </Video>
                                }
                            </div>
                        </Fragment>
                    </Fade>
                </AriaModal>
                </div>
            )}
        </Fragment>
    )
}

export default VideoTile
