import React, { useEffect, useState } from 'react'
import { makeStyles, Typography, Grid, CardMedia, Button } from '@material-ui/core'
import { motion, useAnimation } from 'framer-motion'
import { BannerZoom } from '../../Utils/AnimationEffects'
import { useInView } from 'react-intersection-observer'
import renderHTML from 'react-render-html'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import {getValueByAttribute} from '../../Utils/DataExtract'
import idx from 'idx'

const useStyles = makeStyles((theme) => ({
  headingText: {
    color: '#454545',
    fontWeight: 'bold',
    fontSize: '1.7rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      textAlign: 'center'
    }
  },
  desc: {
    color: '#454545',
    fontSize: '1rem',
    paddingRight: theme.spacing(2),
    marginBottom: 10,
    marginTop: 15,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
      textAlign: 'center',
      fontSize: '0.85rem',
    }
  },
  teamMemberName: {
    textAlign: 'left',
    lineHeight: '1rem',
	color: '#CC0000',
	fontWeight: 'Bold',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  media: {
    height: 0,
    paddingTop: '70%',
    backgroundSize: '58%',
    backgroundPositionX: '60%',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '72.25%',
      backgroundSize: '60%',
      backgroundPositionX: '50%',
    }
  },
  textBlock: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginTop: theme.spacing(2)
    }
  },
  container: {
    padding: theme.spacing(5),
    height: '100%'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingRight: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(0),
    }
  },
 
  storyLink: {
    borderRadius: 35,
    color: "#CC0000",
    borderColor: '#CC0000',
    textTransform: 'none',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  },
  readMoreCont: {
	  paddingTop: '1.2rem',
	[theme.breakpoints.down('sm')]: {
	  paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
	}
  }
}))

export default function Ditlo(props) {
	const {component, desktop} = props
	const values = idx(component, (_) => _.attributes.values)
  const controls = useAnimation()
  const [ref, inView] = useInView({ triggerOnce: false })
  const [readMoreFlag, setReadMoreFlag] = useState(false)


  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        controls.start('visible')
      }, 300)
    }
  }, [controls, inView])

  const classes = useStyles()

const toggleReadMore = () => {
	setReadMoreFlag(!readMoreFlag)
}

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${desktop ? getValueByAttribute(values, 'desktop_background_image') : getValueByAttribute(values, 'mobile_background_image')
            })`,
          backgroundSize: desktop ? 'cover' : '100%',
          backgroundRepeat: 'no-repeat',
          backgroundColor: '#efefef',
          minHeight: 350,
          marginBottom:  0,
          cursor: 'auto',

        }}
        ref={ref}
      >
        <Grid
          container
          alignItems='center'
          alignContent='center'
          className={classes.container}
        >
          {getValueByAttribute(values, 'team_member_image') &&
            <Grid item xs={12} md={4}>
              <div>
                <CardMedia image={getValueByAttribute(values, 'team_member_image')} className={classes.media} />
              </div>
            </Grid>
          }
          
          <Grid item xs={12} md={7} className={classes.textBlock}>
            {!desktop ? (
              <motion.div
                className={classes.textContainer}
                animate={controls}
                initial='hidden'
                {...BannerZoom.mobile}
              >
                 <Typography className={classes.headingText}>{renderHTML(getValueByAttribute(values, 'heading'))}</Typography>
				  {getValueByAttribute(values, 'team_member_name') && (
                  <Typography className={classes.teamMemberName}>
                    {getValueByAttribute(values, 'team_member_name')}
                  </Typography>
                  )}
				  {getValueByAttribute(values, 'designation') && (
                  <Typography>
                    {getValueByAttribute(values, 'designation')}
                  </Typography>
                )}
                  {getValueByAttribute(values, 'description') && (
                    <Typography className={classes.desc}>
                      {getValueByAttribute(values, 'description')}
                    </Typography>
                  )}
                  <br />
				  {getValueByAttribute(values, 'read_more_content') && (
					  <Button variant="outlined" className={classes.storyLink} disableElevation onClick={toggleReadMore}>
                      {getValueByAttribute(values, 'read_more_label')}
					  {!readMoreFlag ? (<ArrowDropDownIcon />) : (<ArrowDropUpIcon />)}
                    </Button>
				  )}
              </motion.div>
            ) : (
                <motion.div
                  animate={controls}
                  initial='hidden'
                  {...BannerZoom.mobile}
                >
                  <Typography className={classes.headingText}>{renderHTML(getValueByAttribute(values, 'heading'))}</Typography>
				  {getValueByAttribute(values, 'team_member_name') && (
                  <Typography className={classes.teamMemberName}>
                    {getValueByAttribute(values, 'team_member_name')}
                  </Typography>
                  )}
				  {getValueByAttribute(values, 'designation') && (
                  <Typography>
                    {getValueByAttribute(values, 'designation')}
                  </Typography>
                )}
                  {getValueByAttribute(values, 'description') && (
                    <Typography className={classes.desc}>
                      {getValueByAttribute(values, 'description')}
                    </Typography>
                  )}
                  <br />
				  {getValueByAttribute(values, 'read_more_content') && (
					  <Button variant="outlined" className={classes.storyLink} disableElevation onClick={toggleReadMore}>
                      {getValueByAttribute(values, 'read_more_label')}
					  {!readMoreFlag ? (<ArrowDropDownIcon />) : (<ArrowDropUpIcon />)}
                    </Button>
				  )}
                </motion.div>
              )}
          </Grid>
        </Grid>
      </div>
	  {getValueByAttribute(values, 'read_more_content') && readMoreFlag && (
		  	<div className={classes.readMoreCont}>
				{renderHTML(getValueByAttribute(values, 'read_more_content'))}
			</div>
	  )}
      
    </>
  )
}
