import React, { useLayoutEffect, useEffect, useState } from 'react'
import { makeStyles, Typography, Grid, Button, } from '@material-ui/core'
import { motion,useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import idx from 'idx'
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { BannerZoom } from '../../Utils/AnimationEffects'
import renderHTML from 'react-render-html'

const useStyles = makeStyles((theme) => ({
  nametext:{
    fontWeight: 'bolder',
    fontSize:'18px',
    [theme.breakpoints.down('sm')]: {
      color: '#cc0000',
      fontSize:'22px',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize:'18px',
}
  },
  triangle:{
    margin: '0 auto',
    color: '#cc0000',
    fontSize:'46px',
  },
  tmdetails:{
    textAlign:'center',
    [theme.breakpoints.down('sm')]: {
      textAlign:'left',
      paddingLeft:'66px',
  },
  [theme.breakpoints.down('sm')]: {
    textAlign:'left',
    paddingLeft:theme.spacing(2)
}},
  imgMob:{
    backgroundSize:'contain',
    minHeight: '272px',
    width:'100%',
    [theme.breakpoints.down('sm')]: {
      backgroundSize: 'contain',
      width:'34%',
    },
    [theme.breakpoints.down('xs')]: {
      backgroundSize: '100%',
      minHeight: '130px',
    }
  },
  whatIdoMob:{
    textAlign:'left',
    fontWeight:'bolder',
  },
  close:{
    cursor: 'pointer',
    position: 'absolute',
    color: '#cc0000',
    right: '17px',
    top: '16px',
    fontWeight: 'bolder',
    [theme.breakpoints.down('sm')]: {
      // display: 'none',
    }
  },
  imgDetails:{
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width:'18%',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
      paddingRight: theme.spacing(0),
      padding: '20px',
      width: '100%',
      flexDirection: 'row',
    }
  },
  tm:{
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  tmClicked:{
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',

    '&::after': {
      content: '""',
      width: 0,
      height: 0,
      borderLeft: '20px solid transparent',
      borderRight: '20px solid transparent',
      borderBottom: '20px solid #EFEFEF',
      position: 'absolute',
      bottom: -16,
      left: '35%',
      animation: `$showArrow 1000ms ${theme.transitions.easing.easeInOut}`,
      
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      }
  },
  },
  "@keyframes showArrow": {
    "0%": {
        opacity: 0,
    },
    "100%": {
        opacity: 1,
    }
  },
  whatIdo: {
    color: '#444',
    fontSize: '2rem',
    fontWeight: 'bolder',
  },
  greyContainer: {
    position: 'relative',
    display: 'flex',
    padding: '40px 0px;',
    paddingRight: '110px',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    backgroundColor: '#eee',
    margin: '50px auto',
    marginTop: '15px',

    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(0),
      marginTop: '0',
      display: 'block',
      padding: '0px',
    },
  },
  imageCircles: {
    padding: '0px 6%',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    }
  },
  circle: {
    borderRadius: '50%',
    boxSizing: 'border-box',
    margin: '20px',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '32%',
      height:'80%',
      marginLeft: 0,
      marginRight: 0,
    },
    [theme.breakpoints.down('xs')]: {
      height:'72%',
    },
    // border: '10px solid #fff',
    '&:before': {
      content: " ",
      position: 'absolute',
      zIndex: '1',
      top: '25px',
      left: '25px',
      right: '25px',
      bottom: '25px',
      // border: '20px solid #ffea00',
    },
    '&:hover': {
      // border: '10px solid #cc0000',

    },
  },
  designation: {
    fontSize: '1.1rem',
    color: '#333',
    lineHeight:'19px',
    fontWeight: '200',
    [theme.breakpoints.down('sm')]: {
      color: '#555555',
      fontSize:'1rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize:'16px',
    }
  },
  playButton: {
    color: 'white',
    backgroundColor: '#cc0000',
    borderRadius: 30,
    minWidth: '80px',
    maxWidth: 170,
    marginBottom: '3%',
    fontSize: 16,
    border: 'none',
    padding: '6px 33px',
    marginRight: '20px',
    '&:hover': {
      border: 'none',
      backgroundColor: '#cc0000',
    },
    '& span': {
      color: 'white!important'
    },
    textTransform: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      width: '100%',
      height: 35
    }
  },
  unselectedButton: {
    color: '#333',
    backgroundColor: '#fff',
    borderRadius: 30,
    minWidth: '80px',
    maxWidth: 170,
    marginBottom: '3%',
    fontSize: 16,
    padding: '6px 33px',
    marginRight: '20px',
    border: '2px solid #cc0000 !important',

    '& span': {
      color: '#333!important',
    },
    textTransform: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      width: '100%',
      height: 35
    }
  },
  filterContainer: {
    width:'30%',
    display: 'flex',
    alignItems: 'baseline',
    flexDirection: 'unset',
    padding: '30px 0px 50px 0px',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  filter: {
    display: 'inline-block',
    color: '#555555',
    fontWeight: 'bold',
    marginRight: '30px !important',
    fontSize: '1rem',
  },
  ditloMainTitle: {
    color: '#333333',
  },
  headingText: {
    color: '#454545',
    fontWeight: 'bold',
    fontSize: '1.7rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      textAlign: 'center'
    }
  },
  desc: {
    whiteSpace:'break-spaces',
    color: '#555555',
    lineHeight: '30px',
    fontSize: '18px',
    paddingRight: theme.spacing(2),
    marginBottom: 10,
    marginTop: 15,
    [theme.breakpoints.down('sm')]: {
      // paddingRight: 0,
      marginTop: 30,
      marginBottom: 10,
      textAlign: 'left',
      fontSize: '16px',
    }
  },
  teamMemberName: {
    fontSize: '24px',
    textAlign: 'center',
    lineHeight: '30px',
    color: '#CC0000',
    fontWeight: 'Bold',

    [theme.breakpoints.down('sm')]: {
      fontSize: '22px',
      textAlign:'left'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    }
  },
  media: {
    height: 0,
    paddingTop: '71%',
    backgroundSize: '66%',
    [theme.breakpoints.down('sm')]: {
      backgroundSize: '60%',
      backgroundPositionX: '50%',
    }
  },
  textBlock: {
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      width: '100%',
      marginTop: theme.spacing(2)
    }
  },
  container: {
    backgroundColor: '#eeeeee',
    height: '100%',
    alignItems: 'start',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '20px',
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingRight: 'theme.spacing(8)',
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px',
    }
  },
  storyLink: {
    borderRadius: 35,
    color: "#CC0000",
    borderColor: '#CC0000',
    textTransform: 'none',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  },
  readMoreCont: {
    paddingTop: '1.2rem',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  }
}))

export default function NewDitlo(props) {
  const { component, desktop } = props
  const ditlos = idx(component, (_) => _.attributes.values.archived_ditlo)
  const [flag, setFlag] = useState(0)
  const years = []
  let yearPicked = ''
  let personSelected = {}
  let selectedPerson = {}
  let rowNo = ''
  let mobile = window.matchMedia("(max-width: 768px)").matches 
  let smallDevices = window.matchMedia("(max-width: 1025px) and (min-width: 769px)").matches
  const [closeCard, setCloseCard] = useState(false)

  const [scale,setScale] = useState(
    window.matchMedia("(max-width: 1025px) and (min-width: 769px)").matches ? '2': window.matchMedia("(max-width: 768px)").matches ? '1': '3'
  )

  ditlos.forEach(ditlo => {
    ditlo.year && years.indexOf(ditlo.year[0]) === -1 && years.push(ditlo.year[0])
  })
  let selectedTM = window.location.hash.replace(/[^ a-zA-Z.]/g, '').trim()

  if(selectedTM){
  ditlos.forEach((ditlo) =>{
    if(ditlo.team_member_name[0].replace(/[^ a-zA-Z.]/g, '').replace(/\s/g, "") === selectedTM) {
      yearPicked = ditlo.year[0]
    }
  })
}
else
yearPicked = yearPicked = ditlos[0].year[0] 

  const [setYear, settingsetYear] = useState(yearPicked)

  let tempDits = ditlos.filter(function (ditlo) {
    return ditlo.year && ditlo.year[0].toString() === setYear
  });
  const [selectedDitlos,setSelectedDitlos] = useState(tempDits)


  if(selectedTM && !closeCard && flag === 0){
    selectedDitlos.forEach((ditlo,index) =>{
      if(ditlo.team_member_name[0].replace(/[^ a-zA-Z.]/g, '').replace(/\s/g, "") === selectedTM) {
        selectedPerson = ditlo
        rowNo = Math.floor(index / scale)
      }
    })
    personSelected = {name:selectedPerson.team_member_name[0],desc:selectedPerson.team_member_desc[0],designation:selectedPerson.team_member_designation[0],img:selectedPerson.team_member_img[0],dept:selectedPerson.team_member_department,rowNumber:rowNo}
    yearPicked = selectedPerson.year.toString()
  }
  else {
    personSelected = {name:"",desc:"",designation:"", img:"",rowNumber:"", dept:''}
    yearPicked = ditlos[0].year[0]
  }
 
  useEffect(() => {
    if(mobile){
      setScale(1)
    }
    else if(smallDevices){
      setScale(2)
    }
    else{
      setScale(3)
    }
  },[mobile, smallDevices]);

 

const [person, setPerson] = useState(personSelected)


  let tempDitlosFirst = [];
  
  ditlos.forEach(ditlo=>{
    if(ditlo.year && ditlo.year[0].toString() === setYear)
    tempDitlosFirst.push(ditlo)
  })
  
  const controls = useAnimation()
  const [ref, inView] = useInView({ triggerOnce: false })
  

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        controls.start('visible')
      }, 300)
    }
  })

  const classes = useStyles()

  const closeCardFunc = _ =>{
    setCloseCard(true)
  }

  /* eslint-disable */

  useLayoutEffect(() => {
    let tempPerson =[]
    if(person.name){
      setTimeout(() => {
        tempPerson = ditlos.filter(function (ditlo) {
          person.name.replace(/[^ a-zA-Z.]/g, '').replace(/\s/g, "")
          return ditlo.team_member_name[0] === person.name
        });        
        setPerson({...person,dept:tempPerson[0].team_member_department[0],designation:tempPerson[0].team_member_designation[0],read_more_content:tempPerson[0].read_more_content[0],img:tempPerson[0].team_member_img[0]})
      }, 300);
    }
      
  }, [person.name,person.rowNumber]) 
  /* eslint-disable */

  const yearSelection = year =>{
    settingsetYear(year.toString())
    let tempDitlos = ditlos.filter(function (ditlo) {
      return ditlo.year && ditlo.year[0].toString() === year
    });
    setCloseCard(true)
    setSelectedDitlos(tempDitlos)
    setFlag(-1)
  }

  return (
    <Grid item xs={12} style={{ margin: '0 auto',marginBottom: '50px', width: '100%' }}>
      <hr />
      <Grid item xs={12} className={classes.filterContainer}><Typography variant={'h6'} className={classes.filter} style={{ paddingBottom: 26 }}>Filter by year:</Typography>
        <div style={{ display: 'flex' }}
        >
          {years.map(year => (<>
            <Button
              className={
                year.toString() === setYear ? classes.playButton : classes.unselectedButton
              }
              onClick={_ => {yearSelection(`${year}`)
                
              }}
            >
              {year}
            </Button>
          </>))}
        </div>
      </Grid>
      
      <Typography variant="h3"
        className={classes.ditloMainTitle} >
        A Day in the life of
      </Typography>
      <Grid container spacing={2} className={classes.imageCircles}>
        {selectedDitlos && selectedDitlos.map((ditlo, i) => { 
          const row = Math.floor(i / scale)
          const lenItems = Object.keys(selectedDitlos).length
          return (<>
            <Grid item xs={12} md={6} lg={4} className={ditlo.team_member_name[0] === person.name && !closeCard ? classes.tmClicked : classes.tm } 
            onClick={e =>{setPerson({...person,rowNumber:row,name:ditlo.team_member_name[0]});setCloseCard(false);(ditlo.team_member_name[0] === person.name && !closeCard) && setCloseCard(true)}}>
              <img alt={ditlo.team_member_name[0]} key={i} src={ditlo.team_member_img[0]}
              width="150" height="150" className={classes.circle} />
              <div
              className={classes.textContainer}
              initial='hidden'
            >
        {ditlo.team_member_name[0] && (
                <Typography style={{ color: '#333'}} className = {classes.nametext}>
                 {ditlo.team_member_name[0]}
                </Typography>
                )}
        {ditlo.team_member_designation[0] && (
                <Typography style={{ color: '#333'}}>
                  {ditlo.team_member_designation[0]}
                </Typography>
              )}
            </div>
            </Grid>
           {((((i+1) % scale === 0) && !closeCard) && row === person.rowNumber)&& (
              <Grid xs={12} md={12} lg={12}>
                  <motion.div
                className={classes.greyContainer}
                animate={controls}
                initial='hidden'
                {...BannerZoom.mobile}
              >
                <CloseIcon className={classes.close} onClick={_=>closeCardFunc()}/>
                {(
                  <>
                  <div className={classes.imgDetails}>
                  <div
                      key = {i}
                      style={{
                        backgroundImage: `url(${person.img})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundColor: '#efefef',
                      marginBottom:  0,
                      cursor: 'auto',
                     
                    }} className={classes.imgMob}
                    ref={ref}
                  >
                  </div>
                  <div className={classes.tmdetails}>
                    {person.name && (
                          <Typography className={classes.teamMemberName}>
                            {person.name}
                          </Typography>
                          )}
                           {person.designation && (
                          <Typography className={classes.designation}>
                            {person.designation}
                          </Typography>
                          )}
                  {person.dept && (
                          <Typography className={classes.designation}>
                            {person.dept}
                          </Typography>
                          )}
                      </div>
                   </div>
                  <div item xs={12} md={7} className={classes.textBlock}>
                  {!desktop ? (
                    <div
                      className={classes.textContainer}
                      initial='hidden'
                      ref={ref}
                    >
                      {/* <Typography className={classes.whatIdoMob}>What I do</Typography> */}
                        {person.read_more_content && (
                          <Typography className={classes.desc}>
                           {renderHTML(person.read_more_content)}
                          </Typography>
                        )}
                        <br />
                        <KeyboardArrowUpIcon onClick={_=>closeCardFunc()} className={classes.triangle}/>
                    </div>
                  ) : (
                    <div 
                  >
                      {/* <Typography className={classes.whatIdo}>What I do</Typography> */}
                      {person.read_more_content && (
                          <Typography className={classes.desc}>
                            {renderHTML(person.read_more_content)}
                          </Typography>
                        )}
                        <br />
                     </div>
                    )}
                </div></>
                )}</motion.div>
              </Grid>
            )}

{((lenItems-1 === i && (i+1) % scale !== 0) && (!closeCard && row === person.rowNumber))&& (
               <Grid xs={12} md={12} lg={12}>
               <motion.div
             className={classes.greyContainer}
             animate={controls}
             initial='hidden'
             {...BannerZoom.mobile}
           >
             <CloseIcon className={classes.close} onClick={_=>closeCardFunc()}/>
             {(
               <>
               <div className={classes.imgDetails}>
               <div
                   key = {i}
                   style={{
                     backgroundImage: `url(${person.img})`,
                   backgroundRepeat: 'no-repeat',
                   backgroundColor: '#efefef',
                   marginBottom:  0,
                   cursor: 'auto',
                  
                 }} className={classes.imgMob}
                 ref={ref}
               >
               </div>
               <div className={classes.tmdetails}>
                    {person.name && (
                          <Typography className={classes.teamMemberName}>
                            {person.name}
                          </Typography>
                          )}
                           {person.designation && (
                          <Typography className={classes.designation}>
                            {person.designation}
                          </Typography>
                          )}
                  {person.dept && (
                          <Typography className={classes.designation}>
                            {person.dept}
                          </Typography>
                          )}
                      </div>
                </div>
               <div item xs={12} md={7} className={classes.textBlock}>
               {!desktop ? (
                 <div
                   className={classes.textContainer}
                   initial='hidden'
                 >
                   {/* <Typography className={classes.whatIdoMob}>What I do</Typography> */}
                     {person.read_more_content && (
                       <Typography className={classes.desc}>
                        {renderHTML(person.read_more_content)}
                       </Typography>
                     )}
                     <br />
                     <KeyboardArrowUpIcon onClick={_=>closeCardFunc()} className={classes.triangle}/>
                 </div>
               ) : (
                 <div 
               >
                   {/* <Typography className={classes.whatIdo}>What I do</Typography> */}
                   {person.read_more_content && (
                       <Typography className={classes.desc}>
                         {renderHTML(person.read_more_content)}
                       </Typography>
                     )}
                     <br />
                  </div>
                 )}
             </div></>
             )}</motion.div>
           </Grid>
            )}

        </>
        )//end of return
                        })}</Grid></Grid>)}
