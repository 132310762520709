import React from 'react';
import { makeStyles, Card, CardMedia, Typography } from '@material-ui/core';
import idx from 'idx';
import { motion } from 'framer-motion';
import { SmoothLeftToRight } from '../../Utils/AnimationEffects';
import { getValueByAttribute } from '../../Utils/DataExtract';

const useStyles = makeStyles((theme) => ({
  heroBaseStyle: {
    width: '100%',
    height: 500,
    position: 'relative',
    borderRadius: 0,
    background: '#ad0101',
    [theme.breakpoints.down('sm')]: {
      height: 338,
      marginBottom: theme.spacing(3),
    },
  },
  cardMedia: {
    height: '100%',
  },
  overlay: {
    [theme.breakpoints.down('sm')]: {
      top: 90,
      padding: 12,
      width: '55%',
      // backdropFilter: 'blur(20px)',
      // background: 'rgba(0,0,0,0.1)',
    },
  },
  heroText: {
    fontWeight: 'bold',
    fontSize: '2.5rem',
    lineHeight: '4rem',
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
      lineHeight: '2.7rem',
    },
  },
  normalHeroText: {
    fontWeight: 'bold',
    fontSize: '3rem',
    lineHeight: '4rem',
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
    },
  },
  bigText: {
    fontSize: '4.9rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3.14rem',
    },
  },
  playButton: {
    borderRadius: 30,
    width: '50%',
    marginTop: '10%',
    fontSize: 16,
    height: 50,
    '&:focus': {
      outline: '1px dotted',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 9,
      width: 111,
      height: 35,
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    position: 'relative',
  },
  closeButton: {
    top: -40,
    right: -40,
    position: 'absolute',
    '&:focus': {
      // outline: '1px dotted'
    },
  },
  subText: {
    fontSize: 26,
    width: 384,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      width: '100%',
    },
  },
}));

const Hero = (props) => {
  const { component, desktop, sideMenu } = props;
  const values = idx(component, (_) => _.attributes.values);
  const classes = useStyles();
  const getPostionsData = (isDesktop, pData) => {
    const textLayerWidth = getValueByAttribute(pData, `text_layer_width_${isDesktop ? 'desktop' : 'mobile'}`)
      ? getValueByAttribute(pData, `text_layer_width_${isDesktop ? 'desktop' : 'mobile'}`)
      : 100;
    const position = getValueByAttribute(pData, `text_layer_position_${isDesktop ? 'desktop' : 'mobile'}`);
    return {
      top: position.top ? `${position.top}%` : '15%',
      left: position.left ? `${position.left}%` : '5%',
      width: `${textLayerWidth}%`,
    };
  };

  const defaultOverlayPos = {
    position: 'absolute',
    top: '15%',
    left: '5%',
    color: 'white',
  };

  let overlay = Object.assign({}, defaultOverlayPos, getPostionsData(desktop, values));
  return (
    <Card className={classes.heroBaseStyle} style={{ ...(sideMenu ? { height: 425 } : {}) }}>
      {desktop
        ? getValueByAttribute(values, 'background_image') && <CardMedia className={classes.cardMedia} image={getValueByAttribute(values, 'background_image')} />
        : getValueByAttribute(values, 'background_image_mobile') && (
            <CardMedia className={classes.cardMedia} image={getValueByAttribute(values, 'background_image_mobile')} />
          )}

      <div style={overlay} className={classes.overlay}>
        <motion.div {...SmoothLeftToRight}>
          {(getValueByAttribute(values, 'heading') || getValueByAttribute(values, 'sub_heading')) && (
            <Typography
              className={classes.heroText}
              style={{ color: getValueByAttribute(values, 'color') && getValueByAttribute(values, 'color') }}
              variant={'h2'}
            >
              {getValueByAttribute(values, 'heading')} {getValueByAttribute(values, 'heading') && <br />}{' '}
              <span className={classes.bigText}>{getValueByAttribute(values, 'sub_heading')}</span>
            </Typography>
          )}
          {getValueByAttribute(values, 'normal_sized_heading') && (
            <Typography className={classes.normalHeroText} variant={'h2'}>
              {getValueByAttribute(values, 'normal_sized_heading')}
            </Typography>
          )}
          <Typography color={'primary'} className={classes.subText} style={{ width: getValueByAttribute(values, 'full_width_for_hero_text') && '90%' }}>
            {getValueByAttribute(values, 'normal_text')}
          </Typography>
        </motion.div>
      </div>
    </Card>
  );
};

export default Hero;
