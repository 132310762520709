import React, { 
  forwardRef,
  Fragment,
  useEffect,
  useRef 
} from 'react'
import './styles/main.scss'
import { Helmet } from 'react-helmet'
import Layout from './components/Layout/Layout'
import { BrowserRouter } from 'react-router-dom'
import ScrollToTop from './components/Layout/ScrollToTop'

const Parsely = forwardRef((props, ref) => (
  <div ref={ref}></div>
));

function App() {
  const ref = useRef(null);
  useEffect(() => {
    if(ref.current) {
      const script = document.createElement("script");
      script.async = true;
      script.id = "parsely-cfg"
      script.src = "https://cdn.parsely.com/keys/sandbox.india.target.com/p.js";
      ref.current.appendChild(script)
    }
  }, [])
  return (
    <Fragment>
      <BrowserRouter>
        <Helmet>
          <meta charSet='utf-8' />
          <html lang='en' />
          <title>Target in India</title>
          <meta
            name='description'
            content='Target in india description'
          />
          <meta name='viewport' content='width=device-width, initial-scale=1' />
          {/* Add general page wide keywords below */}
          <meta
            name='keywords'
            content='Target in India'
          />
        </Helmet>
        <ScrollToTop>
          <Layout />
          <Parsely ref={ref} />
        </ScrollToTop>
      </BrowserRouter>
    </Fragment>
  )
}

export default App
