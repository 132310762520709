import React, { useEffect, useState } from 'react'
import { makeStyles, Typography, Grid, CardMedia, Button } from '@material-ui/core'
import { motion, useAnimation } from 'framer-motion'
import { BannerZoom } from '../../Utils/AnimationEffects'
import { useInView } from 'react-intersection-observer'
import renderHTML from 'react-render-html'
import Video from '../../ComponentsList/Video/Video'
import {getValueByAttribute} from '../../Utils/DataExtract'
import idx from 'idx'


const useStyles = makeStyles((theme) => ({
  
  desc: {
    color: '#454545',
    fontSize: '1.7rem',
    fontWeight: 'bold',
    paddingRight: theme.spacing(2),
    marginBottom: 10,
    marginTop: 15,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
      textAlign: 'center',
      fontSize: '1.2rem',
    }
  },
  teamMemberNameDesc: {
    textAlign: 'left',
	lineHeight: '1.6rem',
	color: '#454545',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  author: {
    color: '#CC0000',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    lineHeight: '3rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '1rem'
    }
  },
  media: {
    height: 0,
    paddingTop: '70.25%',
    backgroundSize: '55%',
    backgroundPositionX: '45%',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '72.25%',
      backgroundSize: '60%',
      backgroundPositionX: '50%',
    }
  },
  textBlock: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginTop: theme.spacing(2)
    }
  },
  container: {
    padding: theme.spacing(5),
    height: '100%'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingRight: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(0),
    }
  },
  storyLink: {
    borderRadius: 35,
    color: "#CC0000",
    borderColor: '#CC0000',
    textTransform: 'none',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  },
}))

export default function Story(props) {
	const {component, desktop} = props
	const values = idx(component, (_) => _.attributes.values)
  const isDesc = getValueByAttribute(values, 'description')
  const video_details  = getValueByAttribute(values, 'video_details',0,0)
const controls = useAnimation()
  const [ref, inView] = useInView({ triggerOnce: false })
  const [videoModelOpen, setVideoModelOpen] = useState(false)
  const [currentVideo, setCurrentVideo] = useState(null)
  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        controls.start('visible')
      }, 300)
    }
  }, [controls, inView])

  const classes = useStyles()

  
const openVideoModel = (values) => {
	let videoData = {
		title: getValueByAttribute(values, 'video_title'),
		poster: getValueByAttribute(values, 'video_poster'),
		source: getValueByAttribute(values, 'video_files', true),
		tracks: getValueByAttribute(values, 'closed_caption', true),
		transcript: getValueByAttribute(values, 'transcript_file', true),
	}
	setCurrentVideo(videoData)
	setVideoModelOpen(true)
}

const closeVideoModel = () => {
    setVideoModelOpen(false)
    setCurrentVideo(null)
}


  return (
    <>
      <div
        style={{
			backgroundImage: `url(${desktop ? getValueByAttribute(values, 'desktop_background_image') : getValueByAttribute(values, 'mobile_background_image')
			  })`,
			backgroundSize: desktop ? 'cover' : '100%',
			backgroundRepeat: 'no-repeat',
			backgroundColor: '#efefef',
			minHeight: 350,
			marginBottom: 0,
			cursor: 'auto',
		  }}
        ref={ref}
      >
        <Grid
          container
          alignItems='center'
          alignContent='center'
          className={classes.container}
        >
          {getValueByAttribute(values, 'author_image') &&
            <Grid item xs={12} md={4}>
              <div>
                <CardMedia image={getValueByAttribute(values, 'author_image')} className={classes.media} />
              </div>
            </Grid>
          }
		  <Grid item xs={12} md={7} className={classes.textBlock}>
            {!desktop ? (
              <motion.div
                className={classes.textContainer}
                animate={controls}
                initial='hidden'
                {...BannerZoom.mobile}
              >
				{getValueByAttribute(values, 'quote') && (
                  <Typography className={classes.desc}>
                    {renderHTML(getValueByAttribute(values, 'quote'))}
                  </Typography>
                )}
                {isDesc ? (
					  <div>
						  {<Typography className={classes.teamMemberNameDesc}>
						  <strong>{getValueByAttribute(values, 'author')}&nbsp;</strong>
						  {getValueByAttribute(values, 'description')}
						</Typography>}
					  </div>
				  ) : (
					<div>{
						<Typography className={classes.author}>{getValueByAttribute(values, 'author')} 
						</Typography>
					  }
				  </div>)}
				  <br />
				  {getValueByAttribute(values, 'video_details') && (
                    <Button variant="outlined" className={classes.storyLink} disableElevation onClick={() => openVideoModel(video_details)}>
                      {getValueByAttribute(values, 'video_play_button_label')}
                    </Button>
                  )}
              </motion.div>
            ) : (
                <motion.div
                  animate={controls}
                  initial='hidden'
                  {...BannerZoom.mobile}
                >
				  {getValueByAttribute(values, 'quote') && (
                    <Typography className={classes.desc}>
                      {renderHTML(getValueByAttribute(values, 'quote'))}
                    </Typography>
                  )}
                  
				  {isDesc ? (
					  <div>
						  {<Typography className={classes.teamMemberNameDesc}>
						  <strong>{getValueByAttribute(values, 'author')}&nbsp;</strong>
						  {getValueByAttribute(values, 'description')}
						</Typography>}
					  </div>
				  ) : (
					<div>{
						<Typography className={classes.author}>{getValueByAttribute(values, 'author')} 
						</Typography>
					  }
				  </div>)}
				  <br />
                  {getValueByAttribute(values, 'video_details') && (
                    <Button variant="outlined" className={classes.storyLink} disableElevation onClick={() => openVideoModel(getValueByAttribute(values, 'video_details', false, 0))}>
                      {getValueByAttribute(values, '_video_play_button_label')}
                    </Button>
                  )}
                </motion.div>
              )}
          </Grid>
        </Grid>
      </div>
      <Video open={videoModelOpen} video={currentVideo} close={closeVideoModel}/>
    </>
  )
}
