import merge from 'lodash/merge';

// configurations shared between most or all environments can be stored in commonConfig
const commonConfig = {
  apiHost: 'http://localhost:8081/api',
  imageHost: 'https://toss.perf.target.com/cmstitestpoc',
  xApiKey: '',
};

const envConfigs = {
  // dev is the default development environment set by .env.development and the default pipeline in .drone.yml
  dev: {
    apiHost: 'http://localhost:8080/api',
    imageHost: 'https://toss.perf.target.com/cmstitestpoc',
    imagesBucket: 'https://gfc.target.com/cmsti',
  },
  localPreview: {
    apiHost: 'http://localhost:8080',
    imageHost: 'https://toss.perf.target.com/cmstitestpoc',
    preview: true,
    imagesBucket: 'https://gfc.target.com/cmsti',
  },
  previewStage: {
    apiHost: 'https://tiicms-api.dev.target.com',
    imageHost: 'https://toss.perf.target.com/cmstitestpoc',
    preview: true,
    imagesBucket: 'https://gfc.target.com/cmsti',
  },
  previewProd: {
    apiHost: 'https://tiicms-api.prod.target.com',
    imageHost: 'https://toss.perf.target.com/cmstitestpoc',
    preview: true,
    imagesBucket: 'https://gfc.target.com/cmsti',
  },
  stg: {
    apiHost: 'https://stage-api.target.com/target_india_contents/v1',
    imageHost: 'https://toss.perf.target.com/cmstitestpoc',
    xApiKey: 'GO_PROXY_STAGE_KEY',
    imagesBucket: 'https://gfc.target.com/cmsti',
  },
  prod: {
    apiHost: 'https://api.target.com/target_india_contents/v1',
    imageHost: 'https://toss.perf.target.com/cmstitestpoc',
    xApiKey: 'f7ca5cd9fcf9a72129240674cad3f4dca5ea5f67',
    imagesBucket: 'https://gfc.target.com/cmsti',
  },
};

const appEnv = process.env.REACT_APP_ENV;
const config = envConfigs[appEnv];

// commonConfig and the config for the matching REACT_APP_ENV are combined.
// Values in the environment-specific config will overwrite commonConfig keys
// if they share the same name.
const apiConfig = merge(commonConfig, config);

export default apiConfig;
