import React, { useEffect } from 'react'
import { makeStyles, Typography, Grid } from '@material-ui/core'
import { useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import {getValueByAttribute} from '../../Utils/DataExtract'
import idx from 'idx'

const useStyles = makeStyles((theme) => ({
  ditloWrap:{
     position:'relative',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      backgroundColor: '#f7f7f7',
      margin: '10px 0 30px 0',
      [theme.breakpoints.down('sm')]: {
        marginTop: '0',
        flexDirection: 'inherit',
      }
  },
  desktopName:{
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    }
  },
  mobName:{
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      position: 'absolute',
      right: '23px',
      top: '142px',
    }
  },
  nameImg:{
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    minHeight: '290px',
    zIndex: 0,
    
    [theme.breakpoints.up('lg')]: {
      backgroundSize: 'contain',
    },
    [theme.breakpoints.down('lg')]: {
      backgroundSize: 'cover',
    },

    [theme.breakpoints.down('md')]: {
      backgroundSize: 'contain',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundSize: '52%',
      minHeight: '197px',
      // backgroundSize: 'cover',
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    [theme.breakpoints.down('xs')]: {
      backgroundSize: 'cover',
    }
  },
  headingText: {
    color: '#333',
    fontWeight: 'bold',
    fontSize: '1.7rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      textAlign: 'center'
    }
  },
  desc: {
    whiteSpace: 'break-spaces',
    color: '#333',
    fontSize: '15px',
    marginBottom: 10,
    marginTop: 15,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginBottom: 10,
      textAlign: 'left',
      fontSize: '1rem',
      lineHeight: '22px',
    }
  },
  teamMemberName: {
    textAlign: 'left',
    lineHeight: '1rem',
    color: '#CC0000',
    fontWeight: 'Bold',
  },
  media: {
    height: 0,
    paddingTop: '70%',
    backgroundSize: '58%',
    backgroundPositionX: '60%',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '72.25%',
      backgroundSize: '60%',
      backgroundPositionX: '50%',
    }
  },
  textBlock: {
    right: '100px',
    zIndex: '10',
    padding: '30px',
    textAlign: 'left',

    [theme.breakpoints.down('sm')]: {
      marginTop: '-33px',
      right: '0px',
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'end',
    }
  },
  container: {
    padding: theme.spacing(5),
    height: '100%'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingRight: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(0),
    }
  },
 
  storyLink: {
    borderRadius: 35,
    color: "#CC0000",
    borderColor: '#CC0000',
    textTransform: 'none',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  },
  readMoreCont: {
      paddingTop: '1.2rem',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  }
}))

export default function CIOQuote(props) {
    const {component, desktop} = props
    const values = idx(component, (_) => _.attributes.values)
    const controls = useAnimation()
    const [ref, inView] = useInView({ triggerOnce: false })

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        controls.start('visible')
      }, 300)
    }
  }, [controls, inView])

  const classes = useStyles()

  return (
      <Grid container className={classes.ditloWrap}>  
           {getValueByAttribute(values, 'desktop_image') &&
            <Grid item xs={12} md={6} className={classes.nameImg}   
        style={{
          backgroundImage: `url(${desktop ? getValueByAttribute(values, 'desktop_image') : getValueByAttribute(values, 'mobile_img')
            })`,
          backgroundRepeat: 'no-repeat',
          marginBottom:  0,
          cursor: 'auto',

        }}
        ref={ref}
      >  
            </Grid>
          }
           <div className={classes.mobName}>
             {getValueByAttribute(values, 'tm_name') && (
           <Typography className={classes.teamMemberName}>
               {getValueByAttribute(values, 'tm_name')}
           </Typography>
           )}
           {getValueByAttribute(values, 'tm_designation') && (
           <Typography>
               {getValueByAttribute(values, 'tm_designation')}
           </Typography>
           )}
             </div>
              <Grid item xs={12} md={6} className={classes.textBlock}>
           
           {getValueByAttribute(values, 'tm_desc') && (
               <Typography className={classes.desc}>
               {getValueByAttribute(values, 'tm_desc')}
               </Typography>
           )}
           <br />
           <div className={classes.desktopName}>
             {getValueByAttribute(values, 'tm_name') && (
           <Typography className={classes.teamMemberName}>
               {getValueByAttribute(values, 'tm_name')}
           </Typography>
           )}
           {getValueByAttribute(values, 'tm_designation') && (
           <Typography>
               {getValueByAttribute(values, 'tm_designation')}
           </Typography>
           )}
             </div>
           </Grid>
      </Grid>
  ) 
}

