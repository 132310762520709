import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
// import Accordion from '@material-ui/core/Accordion'
// import AccordionSummary from '@material-ui/core/AccordionSummary'
// import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeStyles } from '@material-ui/core/styles'
import { getValueByAttribute } from '../../Utils/DataExtract'
import idx from 'idx'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: '1rem'
  },
  hr: {
    width: 90,
    textAlign: 'left',
    color: '#cc0000',
    marginLeft: 0,
    height: 3,
    background: '#cc0000',
    border: 0,
    display: 'flex',
    marginTop: 20,
    marginBottom: 28,
    [theme.breakpoints.down('xs')]: {
      marginTop: '20px !important'
    }
  },
  accordionArea: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }
}))

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    marginBottom: 7,

    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto',
      marginBottom: 7
    }
  },
  expanded: {}
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#F4F4F4',
    paddingLeft: '30px',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56
    }
  },
  content: {
    backgroundColor: 'tranaperent',
    '&$expanded': {
      margin: '12px 0'
    },
    '& p': {
      // fontWeight: 'bold',
      // fontSize: '1.872rem',
      // color: '#333333'
    }
  },
  expanded: {}
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    paddingLeft: 0,
    paddingRight: '0px',
    paddingTop: '12px',
    paddingBottom: '20px',

    border: ' 1px solid #F4F4F4',
    '& p': {
      color: '#333333',
      paddingLeft: '32px',
      paddingRight: '32px'
    },
    [theme.breakpoints.down('xs')]: {
      '& p': {
        fontSize: '1rem',
        lineHeight: '2rem'
      },
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    }
  }
}))(MuiAccordionDetails)

export default function CustomizedAccordions(props) {
  const { component } = props
  const values = idx(component, (_) => _.attributes.values)
  const classes = useStyles()

  //   const [expanded, setExpanded] = React.useState('panel0')
  //   const handleChange = (panel) => (event, newExpanded) => {
  //     setExpanded(newExpanded ? panel : false)
  //   }

  return (
    <div className={classes.accordionArea}>
      <Typography variant={'h3'}>
        {getValueByAttribute(values, 'heading')}
      </Typography>
      <hr className={classes.hr} />
      <div className={classes.root}>
        {getValueByAttribute(values, 'accordion', true) &&
          getValueByAttribute(values, 'accordion', true).map((item, key) => (
            <Accordion key={key}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Typography className={classes.heading} variant={'h4'}>
                  {getValueByAttribute(item, 'header')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{getValueByAttribute(item, 'body')}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
      </div>
    </div>
  )
}
