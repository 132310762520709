import React, {useEffect} from 'react'
import { makeStyles, Divider } from '@material-ui/core'
import { motion, useAnimation } from 'framer-motion'
import { SmallZoomEntry } from '../../Utils/AnimationEffects'
import { useInView } from "react-intersection-observer"


const useStyles = makeStyles((theme) => ({
    divider: {
        color: '#C6C6C6',
        [theme.breakpoints.down('xs')]:{
            marginTop: '30px !important',
            marginBottom: '30px !important'
        }
    }
}))

const BlockDivider = (props) => {
    const classes = useStyles()
    const controls = useAnimation()
    const [ref, inView] = useInView({triggerOnce: false});

    useEffect(() => {
        if (inView) {
            setTimeout(() => {
                controls.start("visible");
            }, 300)
        }
    }, [controls, inView])

    const deafultDividerStyle = {
        marginTop: 10,
        marginBottom: 10,
        textAlign: 'center',
        marginLeft: '15%',
        marginRight: '15%'
    }

    return (
        <>
            <div ref={ref}>
                <motion.div                                     
                    animate={controls}
                    initial="hidden"
                    {...SmallZoomEntry}
                >
                    <Divider className={classes.divider} style={deafultDividerStyle} />
                </motion.div>
            </div>
        </>
    )
}

export default BlockDivider
