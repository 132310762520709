import React, { useEffect, Fragment, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import {
  Breadcrumb,
} from '../ComponentsList/Components'
import Grid from '@material-ui/core/Grid'
import apiConfig from '../../apiConfig'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import idx from 'idx'
import { Helmet } from 'react-helmet'
import renderHTML from 'react-render-html'
import { getValueByAttribute, getFeaturedImageURL } from '../Utils/DataExtract'
import { Card, CardMedia, Typography, Chip, Link, IconButton } from '@material-ui/core'
import Moment from 'react-moment'
import { Link as RLink } from 'react-router-dom'
import GetAppIcon from '@material-ui/icons/GetApp'
import NotFound from '../NotFound/NotFound'
import TwitterIcon from '@material-ui/icons/Twitter'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import LinkIcon from '@material-ui/icons/Link'
// import SimilarPosts from './SimilarPosts'
import { useHistory } from 'react-router-dom'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import FavoriteIcon from '@material-ui/icons/Favorite'
import { likePost } from '../../store/post/actionCreator'

const { preview } = apiConfig

const useStyles = makeStyles((theme) => ({
  copyMsg:{
    position: 'absolute',
    top: '31px',
    fontSize: '13px',
    fontWeight: 'bold',
    color: '#cc0000',
    left:'79px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  fullWidth: {
    width: '100%'
  },
  fullPad: {
    width: '100%',
    padding: theme.spacing(10),
    paddingRight: 0,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5)
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(7)
    }
  },
  postWidth: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    // paddingTop: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5)
      // paddingTop: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
      // paddingTop: theme.spacing(2),
    }
  },
  title: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5)
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    }
  },
  leftColumn: {
    width: '19%',
    [theme.breakpoints.down('md')]: {
      width: '28%',
      padding: theme.spacing(3)
    }
  },
  rightColumn: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0 !important'
    },
    [theme.breakpoints.down('sm')]: {
      padding: `0px !important`
    }
  },
  topSpace: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(3)
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2)
    }
  },
  category: {
    borderRadius: 0,
    background: '#cc0000',
    color: '#fff',
    fontSize: '1rem',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    '& :hover': {
      cursor: 'pointer'
    }
  },
  time: {
    paddingBottom: theme.spacing(1)
  },
  pipe: {
    fontWeight: 'bold',
    color: '#a3a3a3'
  },
  hashtags:{
    wordBreak: 'break-all',
  },
  postend: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5)
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    }
  },
  postendBottom: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5)
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    }
  },
  similarPosts: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5)
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    }
  },
  links: {
    textDecoration: 'none',
    marginRight: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
  tagsHeading: {
    paddingBottom: theme.spacing(3)
  },
  imageBox: {
    borderRadius: 0,
    position: 'relative',
    marginBottom: theme.spacing(2)
  },
  skipLink: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    width: 1,
    color: '#fff',
    position: 'absolute',
    '&:focus': {
        clip: 'auto',
        height: 33,
        top: 0,
        right: 0,
        width: 'auto',
        margin: 0,
        zIndex: 1500,
        outline: 0,
        textDecoration: 'underline',
        border: '1px dashed #d3d3d3',
        padding: 3,
    }
  },
  shareArea: {
    position: 'relative',
    right: 0,
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      top: 0,
      position: 'relative',
      display: 'flex',
      flexDirection: 'row'
    }
  },
  shareButtons: {
    // background: '#cc0000',
    margin: theme.spacing(1),
    marginRight: 0,
    width: 30,
    height: 30,
    '&:hover svg': {
      // color: '#cc0000',
    },
    '&:focus': {
      outline: 'none !important',
    },
    [theme.breakpoints.down('md')]: {
      width: 30,
      height: 30,
      marginRight: 0,
      marginBottom: 20,
    }
  },
  shareIcons: {
    color: '#cc0000',
    // '& :hover': {
    //   color: '#cc0000',
    // }
  },
  categoryClick: {
		'&:hover, &:focus': {
			backgroundColor: '#cc0000',
			color: '#fff'
		},
	},
  media: {
    maxHeight: 600,
    objectFit: 'cover',
    [theme.breakpoints.down('md')]: {
      maxHeight: 300,
    }
  },
  postTitle: {
    lineHeight: '2.6rem',
  },
  likeText: {
    paddingTop: theme.spacing(1.5),
    color: '#cc0000'
  },
  textComp: {
    '& a span': {
      color: '#cc0000 !important'
    }
  }
}))

const TextComponent = (props) => {
  const classes = useStyles()
  const { component } = props
  const { value } = component
  const { content } = value
  return (
    <Fragment>
      <div className={classes.textComp}>
        {renderHTML(content)}
      </div>
    </Fragment>
  )
}

const ImageComponent = (props) => {
  const classes = useStyles()
  const { component } = props
  const { value } = component
  return (
    <Fragment>
      <Card className={classes.imageBox} elevation={0}>
        <CardMedia component={'img'} className={classes.media} image={`${encodeURI(getValueByAttribute(value, 'url'))}`} alt={getValueByAttribute(value, 'alt')} />
      </Card>
    </Fragment>
  )
}

const VideoComponent = (props) => {
  const classes = useStyles()
  const { component } = props
  const { value } = component
  return (
    <Fragment>
      <Card className={classes.imageBox} elevation={0}>
        <CardMedia
          className={classes.media}
          component="video"
          src={getValueByAttribute(value, 'source')}
          title={getValueByAttribute(value, 'title')}
          poster={getValueByAttribute(value, 'poster')}
          controls
          controlsList="nodownload"
        />
      </Card>
    </Fragment>
  )
}

const availableComponent = {
  'rich_text': TextComponent,
  'image': ImageComponent,
  'video': VideoComponent,
}

const loadComponent = (attr) => {
  const ComponentLoader = availableComponent[attr.component.name]
  return (
    <Fragment>
      {ComponentLoader && <ComponentLoader {...attr}></ComponentLoader>}
    </Fragment>
  )
}

const isComponentFullWidth = (component) => {
  const fullWidth = idx(component, (_) => _.attributes.values.full_width[0])
  return fullWidth ? true : false
}

const isComponentFullWidthForMobile = (component, desktop) => {
  const fullWidth = idx(
    component,
    (_) => _.attributes.values.full_width_mobile[0]
  )
  return fullWidth && !desktop ? true : false
}

const RenderComponents = (props) => {
  const { components, sectionIndex, section, desktop } = props
  const classes = useStyles()
  return (
    <Fragment key={sectionIndex}>
      <Grid
        container
        style={{paddingBottom: 10}}
      >
        {components &&
          Object.keys(components).map((componentKey, pointer) => {
            return (
              <Grid
                item
                key={`component-${sectionIndex}-${componentKey}`}
                style={{
                  width: `${desktop ? section.percentages[pointer] : 100}%`,
                  // ...(section.percentages[pointer] !== 100 && pointer === 0 ? { paddingRight: 0 } : {})
                }}
                className={
                  isComponentFullWidth(components[componentKey]) ||
                    isComponentFullWidthForMobile(
                      components[componentKey],
                      desktop
                    )
                    ? classes.fullWidth
                    : classes.postWidth
                }
              >
                {loadComponent({
                  component: components[componentKey],
                  sectionIndex,
                  componentIndex: componentKey,
                  desktop,
                })}
              </Grid>
            )
          })}
      </Grid>
    </Fragment>
  )
}

const RenderPostLayout = (props) => {
  const { content, desktop, post, /*similarPosts*/} = props
  const history = useHistory()
  const classes = useStyles()
  const [postLiked, setPostLiked] = useState(false)

  const [copySuccess, setCopySuccess] = useState('');
  const [visible, setVisible] = useState(false)
  function copyToClipboard(e) {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopySuccess('copied');
    setVisible(true);
    setTimeout(() => {
      setVisible(false);
    }, 2000);
  };


  const getReadTimeContent = (readTime) => {
    let readTimeCont = ''
    if(post.read_time && post.read_time.duration) {
      readTimeCont = `${Math.ceil(readTime.duration)}-minute read`
    }
    return readTimeCont
  }

  const likeThePost = (url) => {
    setPostLiked(true)
    props.likePost(url)
  }

  const shareThePost = (action) => {
    switch (action) {
      case 'twitter':
        const twitter = `https://twitter.com/intent/tweet?text=${post.title}&url=${window.location.href}&related=`
        window.open(twitter)
        break;
      case 'linkedIn':
        const linkedIn = `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=${post.title}&ro=false&summary=&source=`
        window.open(linkedIn)
        break;
      case 'copyURL':
        // copy(window.location.href)
        break;
      default:
        break;
    }
  }

  return (
    <Fragment>
      <Grid
        container
        className={classes.fullWidth}
      >
        <Grid
          item
          style={{
            width: `${100}%`,
          }}
          className={classes.postWidth}
        >
          <div className={classes.topSpace}>
            <Breadcrumb component={{ attributes: { values: { navigation_links: [{ slug: '/', name: 'Home' }, { name: 'Blog', slug: '/blog' }, { name: post.breadcrumb_title ? post.breadcrumb_title : post.title, active: true}] } } }} />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center" className={classes.topSpace}>
            <Grid item md={10} sm={12} className={classes.title}>
              <Chip
                label={post.category}
                onClick={() => {history.push(`/blog/category/${post.category}`)}}
                classes={{
                  root: classes.category,
                  clickable: classes.categoryClick,
                }}
              />
              <Typography variant={'h3'} className={classes.postTitle}>{post.title}</Typography>
              <Typography className={classes.time}>
                <Moment format={'MMMM D, YYYY'}>{post.published_time}</Moment>
                <span className={classes.pipe}>&nbsp;&#x2758;&nbsp;</span>
                {getReadTimeContent(post.read_time)}
                {/* {post.read_time && post.read_time.duration && (<span>{post.read_time.humanizedDuration.charAt(0).toUpperCase() + post.read_time.humanizedDuration.slice(1)} read</span>)} */}
              </Typography>
             
              <div className={classes.shareArea}>
                <IconButton aria-label="Share this post on Twitter" className={classes.shareButtons} onClick={() => shareThePost('twitter')}>
                  <TwitterIcon className={classes.shareIcons} />
                </IconButton>
                <IconButton aria-label="Share this post on LinkedIn" className={classes.shareButtons} onClick={() => shareThePost('linkedIn')}>
                  <LinkedInIcon className={classes.shareIcons} />
                </IconButton>
                <IconButton style={{position:'relative'}} aria-label="Copy this post URL" onClick={copyToClipboard} className={classes.shareButtons} >
                  <LinkIcon className={classes.shareIcons} />
                </IconButton>
                {visible ? <div className={classes.copyMsg}>{copySuccess}</div> : ''}

                <IconButton  aria-label="Like the post" className={classes.shareButtons} onClick={() => likeThePost(post.url)} disabled={post.liked}>
                  {post.liked  || postLiked ? (
                    <FavoriteIcon className={classes.shareIcons} />
                  ) : (
                    <FavoriteBorderIcon className={classes.shareIcons} />
                  )}
                </IconButton>
                {post.no_of_likes && <Typography className={classes.likeText} variant="subtitle2">{post.no_of_likes}</Typography>}
              </div>
              
              {post.feature_type === 'image' && (
                <Fragment>
                  <Card className={classes.imageBox} elevation={0}>
                    <CardMedia component={'img'} className={classes.media} image={getFeaturedImageURL(post.feature_data, desktop, 'image')} alt={post.feature_data && post.feature_data.alt ? post.feature_data.alt : ''} />
                  </Card>
                </Fragment>
              )}
              {post.feature_type === 'video' && (
                <Fragment>
                  <Card className={classes.imageBox} elevation={0}>
                  <CardMedia
                    className={classes.media}
                    component="video"
                    src={getValueByAttribute(post.feature_data, 'video_url')}
                    controls
                    crossOrigin="anonymous"
                    disablePictureInPicture
                    controlsList="nodownload"
                    poster={getValueByAttribute(post.feature_data, 'video_poster')}
                  >
                    {getValueByAttribute(post.feature_data, 'video_caption') && (
                      <track label="English" kind="captions" srcLang="en" src={getValueByAttribute(post.feature_data, 'video_caption')} />
                    )}
                  </CardMedia>
                  {getValueByAttribute(post.feature_data, 'video_transcript') && (
                      <div>
                          <Link href={getValueByAttribute(post.feature_data, 'video_transcript')} target="_blank" className={classes.skipLink} aria-label="Download transcript"><GetAppIcon /></Link>
                      </div>
                  )}
                  </Card>
                </Fragment>
              )}
            </Grid>
            <Grid item md={10} sm={12}>
              <Grid
                item
                xs
              >
                {content &&
                  content.map((section, sectionIndex) => {
                    return (
                      <RenderComponents
                        key={sectionIndex}
                        components={section.components}
                        sectionIndex={sectionIndex}
                        section={section}
                        desktop={desktop}
                      />
                    )
                  })}
              </Grid>
            </Grid>
            {(post.author || post.author_location || post.author_location) &&  (
                <Grid item md={10} sm={12} xs={12} className={classes.postend}>
                  {post.author && <Typography><strong>{post.author}</strong></Typography>}
                  {post.author_designation && <Typography>{post.author_designation}</Typography>}
                  {post.author_location &&<Typography>{post.author_location}</Typography>}
                </Grid>
              )}
               <Grid item md={10} sm={12} xs={12} className={classes.postendBottom}>
              <div className={classes.shareArea}>
                <IconButton aria-label="Share this post on Twitter" className={classes.shareButtons} onClick={() => shareThePost('twitter')}>
                  <TwitterIcon className={classes.shareIcons} />
                </IconButton>
                <IconButton aria-label="Share this post on LinkedIn" className={classes.shareButtons} onClick={() => shareThePost('linkedIn')}>
                  <LinkedInIcon className={classes.shareIcons} />
                </IconButton>
                <IconButton  aria-label="Copy this post URL" className={classes.shareButtons} onClick={() => shareThePost('copyURL')}>
                  <LinkIcon className={classes.shareIcons} />
                </IconButton>
                <IconButton  aria-label="Like the post" className={classes.shareButtons} onClick={() => props.likePost(post.url)} disabled={post.liked}>
                  {post.liked ? (
                    <FavoriteIcon className={classes.shareIcons} />
                  ) : (
                    <FavoriteBorderIcon className={classes.shareIcons} />
                  )}
                </IconButton>
                {post.no_of_likes && <Typography className={classes.likeText} variant="subtitle2">{post.no_of_likes}</Typography>}
              </div>
              </Grid>
            {post.tags && post.tags.length > 0 && (
              <Grid item md={10} sm={12} xs={12} className={classes.postend}>
                <Typography variant={'h3'} className={classes.tagsHeading}>Tags</Typography>
                <Typography className={classes.hashtags}>
                {post.tags.map((tag, index) => {
                  return (
                    <RLink key={index} to={`/blog/tag/${tag}`} className={classes.links}>{`#${tag}`}</RLink>
                  )
                })}</Typography>
              </Grid>
            )}
            {/* {similarPosts && (
              <Grid item md={10} sm={12} xs={12} className={classes.similarPosts}>
                <SimilarPosts posts={similarPosts} desktop={desktop} />
              </Grid>
            )} */}
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  )
}

const BlogContainer = (props) => {
  const classes = useStyles()
  const { pathname } = useLocation()
  const currentPost = useSelector((state) => state.post.postCache[pathname])
  const configurations = useSelector((state) => state.configuration.getConfiguration.data)
  const analytics = _.find(configurations, { name: 'analytics_code' })
  const aCode = idx(analytics, (_) => _.published_value)
  const { inProgress } = useSelector((state) => state.post.getPostData)
  const { similarPostsCache } = useSelector((state) => state.post)
  const [open, setOpen] = useState(false)
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const handleCloseBackdrop = () => {
    setOpen(false)
  }

  useEffect(() => {
    setOpen(inProgress)
  }, [inProgress])

  return (
    <Fragment>
      <Backdrop
        className={classes.backdrop}
        open={open}
        onClick={handleCloseBackdrop}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      {!preview && aCode && aCode.code && aCode.src && (
        <Helmet>
          <title>{currentPost && currentPost.title ? currentPost.title : 'Target in india'}</title>
          <meta
            name='title'
            content={currentPost && currentPost.title ? currentPost.title : 'Target in india'}
          />
          <meta
            name='description' 
            content={currentPost && currentPost.title ? currentPost.title : 'Target in india'}
          />
          <meta property="og:image" content="https://toss.perf.target.com/TIICMSPROD/7fb825d0-1ef3-4a74-a6ec-bd9cf49e6763.png"/>
          <script src={aCode.src} async></script>
          <script type="text/javascript">
            {aCode.code}
          </script>
        </Helmet>
      )}
      <Helmet>
        {currentPost && (
          <script type="application/ld+json">{`{
            "@context": "http://schema.org/",
            "@type": "NewsArticle",
            "headline": "${currentPost.title}",
            "url": "https://india.perf.target.com${pathname}",
            "thumbnailUrl": "${getFeaturedImageURL(currentPost.feature_data, desktop, 'image')}",
            "datePublished": "${currentPost.published_time}",
            "articleSection": "${currentPost.category}",
            "creator": "${currentPost.author}",
            "keywords": ${JSON.stringify(currentPost.tags)}
          }`}</script>
          )}
      </Helmet>
      <div className={classes.fullWidth}>
        {currentPost && currentPost!==404 && (
          <RenderPostLayout
            likePost={props.likePost}
            desktop={desktop}
            content={preview ? currentPost.content : currentPost.published_content}
            post={currentPost}
            similarPosts={similarPostsCache[currentPost.category] ? similarPostsCache[currentPost.category] : null}
          />
        )}
        {currentPost && currentPost === 404 && 
          <NotFound type={'Post'} />
        }
      </div>
    </Fragment>
  )
}

const mapDispatchToProps = { likePost }

export default connect(null, mapDispatchToProps)(BlogContainer)
