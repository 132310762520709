import {
  FETCH_SITE_CONF_IN_PROGRESS,
  FETCH_SITE_CONF_SUCCESS,
  FETCH_SITE_CONF_FAILED,
  SEARCH_IN_PROGRESS,
  SEARCH_SUCCESS,
  SEARCH_FAILED,
} from './actionType'
import axios from 'axios'
import apiConfig from '../../apiConfig'
import { formatSearchResults } from '../../components/Utils/DataExtract'

export function getSiteConfigurationInProgress() {
  return {
    type: FETCH_SITE_CONF_IN_PROGRESS,
  }
}

export function getSiteConfigurationSuccess(data) {
  return {
    type: FETCH_SITE_CONF_SUCCESS,
    data
  }
}

export function getSiteConfigurationFailed(error) {
  return {
    type: FETCH_SITE_CONF_FAILED,
    error
  }
}

export function searchInProgress(keyword) {
  return {
    type: SEARCH_IN_PROGRESS,
    keyword
  }
}

export function searchSuccess(keyword, noOfResults, formattedResults) {
  return {
    type: SEARCH_SUCCESS,
    keyword,
    noOfResults,
    formattedResults
  }
}

export function searchFailed(keyword, error) {
  return {
    type: SEARCH_FAILED,
    keyword,
    error
  }
}

export function getSiteConfiguration() {
  return function(dispatch) {
    dispatch(getSiteConfigurationInProgress())
    const config = {
      method: 'get',
      url: `${apiConfig.apiHost}/configurations`,
      headers: { 
        'Content-Type': 'application/json',
      },
    }
    if(!apiConfig.preview) {
      config.params = {
        key: apiConfig.xApiKey
      }
    }
    return axios(config)
      .then(response => {
        dispatch(getSiteConfigurationSuccess(response.data))
      })
      .catch(error => {
        dispatch(getSiteConfigurationFailed(error))
      })
  }
}

export function handleGlobalSearch(keyword, page, catfilter) {
  return function(dispatch) {
    dispatch(searchInProgress(keyword))
    const categories = catfilter.length > 0 ? catfilter.map((cat) => {
			return `&categories[]=${cat}`
		}).join('') : ''
    const config = {
      method: 'get',
      url: `${apiConfig.apiHost}/search?key=${apiConfig.xApiKey}&query=${keyword.trim()}&page=${page}${categories}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
			.then(function (response) {
				// handle success
				const { noOfResults, formattedResults } = formatSearchResults(response.data)
        dispatch(searchSuccess(keyword, noOfResults, formattedResults))
			})
			.catch(function (error) {
				dispatch(searchFailed(keyword, error))
			})
  }
}