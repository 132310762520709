import React, { Fragment, useRef, useState } from 'react';
import { makeStyles, IconButton, Link } from '@material-ui/core';
import AriaModal from 'react-aria-modal';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Fade from '@material-ui/core/Fade';
import GetAppIcon from '@material-ui/icons/GetApp';
import LinkIcon from '@material-ui/icons/Link';
import copy from 'copy-to-clipboard';

const useStyles = makeStyles((theme) => ({
  videoElement: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(5),
    paddingBottom: 0,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
      paddingBottom: 0,
    },
  },
  model: {
    width: '75%',
    [theme.breakpoints.down('sm')]: {
      width: '93%',
    },
  },
  closeButton: {
    top: 0,
    right: 0,
    position: 'absolute',
    zIndex: 2,
    color: '#333333',
    // background: 'rgba(0,0,0,0.2)',
    '&:focus': {
      // outline: '1px dotted'
    },
    [theme.breakpoints.down('sm')]: {
      color: '#fff',
      top: -30,
      right: -30,
      // width: 30,
      // height: 30,
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    position: 'relative',
    width: '72%',
    marginLeft: '14%',
    marginTop: 90,
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      height: 'auto',
      marginLeft: '5%',
    },
  },
  skipLink: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    width: 1,
    color: '#333333',
    position: 'absolute',
    '&:focus': {
      clip: 'auto',
      height: 33,
      bottom: 35,
      right: 0,
      width: 'auto',
      margin: 0,
      zIndex: 1500,
      outline: 0,
      textDecoration: 'underline',
      border: '1px dashed #d3d3d3',
      padding: 3,
    },
  },
  copyLink: {
    textAlign: 'center',
    '& button': {
      // paddingTop: '7px',
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
      '&:focus': {
        outline: '1px solid #d3d3d3',
      },
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      paddingBottom: '6px',
    },
  },
  copyMsg: {
    // position: 'absolute',
    // top: '31px',
    fontSize: '13px',
    fontWeight: 'bold',
    color: '#cc0000',
    left: '79px',
  },
}));

const Video = (props) => {
  const { open, video, close } = props;
  const classes = useStyles();
  const ref = useRef();
  const playError = (error) => {
    ref.current.currentTime = 0;
    ref.current.load();
  };
  const [copySuccess, setCopySuccess] = useState('');
  const [visible, setVisible] = useState(false);
  const pattern = /cmsti\/(.*)/;
  const videoPoster = video?.poster ? video.poster : 'https://gfc.target.com/cmsti/7fb825d0-1ef3-4a74-a6ec-bd9cf49e6763.png';

  function copyToClipboard(e) {
    copy(
      `${window.location.protocol}//${window.location.host}/videostory?vidsrc=${video.source?.[0].match(pattern)[1]}&track=${
        video.tracks?.[0].match(pattern)[1]
      }&poster=${videoPoster.match(pattern)[1]}&title=${video.title?.replace(/ /g, '%20')}`
    );
    setCopySuccess('copied');
    setVisible(true);
    setTimeout(() => {
      setVisible(false);
    }, 2000);
  }
  return (
    <>
      {open && (
        <div className={classes.videoArea}>
          <AriaModal titleText={video.title} onExit={() => close()} verticallyCenter={true} dialogClass={classes.model}>
            <Fade in={open}>
              <Fragment>
                <div className={classes.paper}>
                  <IconButton aria-label='close video' color='primary' onClick={close} className={classes.closeButton}>
                    <HighlightOffIcon />
                  </IconButton>
                  {video && video && (
                    <video
                      playsInline
                      className={classes.videoElement}
                      autoPlay
                      poster={video.poster ? video.poster : ''}
                      crossOrigin='anonymous'
                      controls={true}
                      disablePictureInPicture
                      controlsList='nodownload'
                      onError={playError}
                      ref={ref}
                    >
                      <source src={video.source && video.source[0] ? video.source[0] : ''} />
                      {video.tracks && video.tracks.length > 0 && <track label='English' kind='captions' srcLang='en' src={video.tracks[0]} />}
                    </video>
                  )}
                  {video.transcript && video.transcript[0] && (
                    <div>
                      <Link href={video.transcript[0]} target='_blank' className={classes.skipLink} aria-label='Download transcript'>
                        <GetAppIcon />
                      </Link>
                    </div>
                  )}
                  <div className={classes.copyLink}>
                    <IconButton onClick={copyToClipboard}>
                      <LinkIcon />
                    </IconButton>
                    {visible ? <div className={classes.copyMsg}>{copySuccess}</div> : ''}
                  </div>
                </div>
              </Fragment>
            </Fade>
          </AriaModal>
        </div>
      )}
    </>
  );
};

export default Video;
