import React, { useEffect } from 'react'
import { makeStyles, Typography, Grid } from '@material-ui/core'
import { Link as RLink, useHistory } from 'react-router-dom'
import { motion, useAnimation } from 'framer-motion'
import { BannerZoom } from '../../Utils/AnimationEffects'
import { useInView } from 'react-intersection-observer'
import {getValueByAttribute} from '../../Utils/DataExtract'
import idx from 'idx'

const useStyles = makeStyles((theme) => ({
  headingSec: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '1.7rem',
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      textAlign: 'center'
    }
  },
  descSec: {
    color: '#fff',
    fontSize: '1rem',
    lineHeight: '2rem',
    paddingRight: theme.spacing(2),
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 26,
      textAlign: 'center',
      fontSize: '0.85rem',
      lineHeight: '1.5rem'
    }
  },
  linkButtonSec: {
    border: '1px solid #fff',
    background: '#fff',
    borderRadius: 20,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    color: '#CC0000',
    textDecoration: 'none',
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    }
  },
  textBlock: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginTop: theme.spacing(2)
    }
  },
  container: {
    padding: theme.spacing(5),
    height: 450
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingRight: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
	  paddingRight: theme.spacing(0),
	  paddingTop: theme.spacing(3),
    }
  },
  dummySpace: {
    minHeight: 200,
    width: '100%',
  },


}))

export default function CampusRecruitment(props) {
	const {component, desktop} = props
	const values = idx(component, (_) => _.attributes.values)

  const controls = useAnimation()
  const [ref, inView] = useInView({ triggerOnce: false })
  const history = useHistory()

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        controls.start('visible')
      }, 300)
    }
  }, [controls, inView])

  const classes = useStyles()

  const onClick = (link) => {
    if(link && link.slug) {
      history.push(link.slug)
    }
  }

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${getValueByAttribute(values, desktop ? 'background_image_for_desktop' : 'background_image_for_mobile')})`,
          backgroundSize: desktop ? 'cover' : '100%',
          backgroundRepeat: 'no-repeat',
          backgroundColor: '#cc0000',
          minHeight: 450,
          marginBottom: 30,
          cursor: 'pointer',

        }}
        ref={ref}
        onClick={() => onClick(getValueByAttribute(values, 'link'))}
      >
        <Grid
          container
          alignItems='center'
          alignContent='center'
          className={classes.container}
        >

          {!desktop &&
            <Grid item xs={12} md={4}>
              <div className={classes.dummySpace}></div>
            </Grid>
          }
          <Grid item xs={12} md={6} className={classes.textBlock}>
            {!desktop ? (
              <motion.div
                className={classes.textContainer}
                animate={controls}
                initial='hidden'
                {...BannerZoom.mobile}
              >
                <Typography className={classes.headingSec}>{getValueByAttribute(values, 'heading')}</Typography>
                {getValueByAttribute(values, 'description') && (
                  <Typography className={classes.descSec}>
                    {getValueByAttribute(values, 'description')}
                  </Typography>
                )}
                {getValueByAttribute(values, 'link') && (
                  <div>
                    <RLink className={classes.linkButtonSec} to={getValueByAttribute(values, 'link') ? getValueByAttribute(values, 'link').slug : ''}>
                      {getValueByAttribute(values, 'link_label')}
                    </RLink>
                  </div>
                )}

              </motion.div>
            ) : (
                <motion.div
                  animate={controls}
                  initial='hidden'
                  {...BannerZoom.mobile}
                >
                  <Typography className={classes.headingSec}>{getValueByAttribute(values, 'heading')}</Typography>
                  {getValueByAttribute(values, 'description') && (
                  <Typography className={classes.descSec}>
                    {getValueByAttribute(values, 'description')}
                  </Typography>
                )}
                 
                  <br />
                  {getValueByAttribute(values, 'link') && (
                  <div>
                    <RLink className={classes.linkButtonSec} to={getValueByAttribute(values, 'link') ? getValueByAttribute(values, 'link').slug : ''}>
                      {getValueByAttribute(values, 'link_label')}
                    </RLink>
                  </div>
                )}
                </motion.div>
              )}
          </Grid>
        </Grid>
      </div>
    </>
  )
}
