import idx from 'idx'
import { stripHtml } from "string-strip-html"

/** Get data from values */
export const getValueByAttribute = (data, name, isArray, index = 0, defaultVal = '') => {
    return isArray ? data[name] : data[name] ? data[name][index] : defaultVal
}

export const getPageMenuStructure = (data, pathName) => {
    const pathPieces = pathName.split('/')
    const parentName = `/${pathPieces.length > 1 ? pathPieces[1] : ''}`
    const headerMenu = data && data.find((menu) => menu.name = 'header_menu')
    const currentTree = (headerMenu 
                        && headerMenu.published_value && headerMenu.published_value.items && headerMenu.published_value.items.length > 0 )
                        ? headerMenu.published_value.items.find((menu) => menu.slug === parentName)
                        : null
    return {
        menu: currentTree,
        currentPage: pathName,
        currentExpanded: `${parentName}${pathPieces[2] ? `/${pathPieces[2]}`: ''}`
    }
}

const getQuery = () => {
    if (typeof window !== 'undefined') {
      return new URLSearchParams(window.location.search)
    }
    return new URLSearchParams()
}
  
export const getQueryParamByKey = (key) => {
    return getQuery().get(key)
}

export const formatSearchResults = (result) => {
    const noOfResults = idx(result, (_) => _.hits.total.value) ?  idx(result, (_) => _.hits.total.value) : 0
    const formattedResults = []

    const hits = idx(result, (_) => _.hits.hits)

    if (hits && hits.length > 0) {
        hits.map(hit => {
            const individualRecord = {}
            switch (hit._index) {
                case 'pages':
                    individualRecord.title = idx(hit, (_) => _._source.title) ? idx(hit, (_) => _._source.title) : 'No Title'
                    individualRecord.url = idx(hit, (_) => _._source.slug) ? idx(hit, (_) => _._source.slug) : '#'
                    individualRecord.image = idx(hit, (_) => _._source.published_layout[0].components[1].attributes.values.mobile_background_image[0])
                        ? idx(hit, (_) => _._source.published_layout[0].components[1].attributes.values.mobile_background_image[0])
                        : idx(hit, (_) => _._source.published_layout[1].components[1].attributes.values.background_image_mobile[0])
                        ? idx(hit, (_) => _._source.published_layout[1].components[1].attributes.values.background_image_mobile[0])
                        : ''
                    individualRecord.description = hit.highlight ? Object.values(hit.highlight).filter(match => {
                        match = match.filter(ind => ind.indexOf('https://') !== 0)
                        return match.join(' ')
                    }).join(' ') : ' '
                    break;
                case 'posts':
                    individualRecord.title = idx(hit, (_) => _._source.title)
                    individualRecord.url = idx(hit, (_) => _._source.url) ? idx(hit, (_) => _._source.url) : '#'
                    individualRecord.image = idx(hit, (_) => _._source.feature_data.image_url[0])
                        ? idx(hit, (_) => _._source.feature_data.image_url[0])
                        : idx(hit, (_) => _._source.feature_data.video_poster[0])
                        ? idx(hit, (_) => _._source.feature_data.video_poster[0]) : ''
                    individualRecord.description = hit.highlight ? Object.values(hit.highlight).filter(match => {
                        match = match.filter(ind => ind.indexOf('https://') !== 0)
                        return match.join(' ')
                    }).join(' ') : ' '
                    break;
                default:
                    break;
            }

            individualRecord.description = stripHtml(individualRecord.description.replace(/<em>/g, '[STARTS]').replace(/<\/em>/g, '[ENDS]')).result.replace(/\[STARTS\]/g, '<em>').replace(/\[ENDS\]/g, '</em>').replace(/nbsp;/g, '')
            formattedResults.push(individualRecord)
            return hit
        })
    }
    return {noOfResults, formattedResults}
}

export const getFeaturedImageURL = (feature, desktop, type) => {
    let url = ''
    if (feature && type === 'image') {
      url = (!desktop && feature.image_thumbnail && feature.image_thumbnail.length > 0)
        ? feature.image_thumbnail[0]
        : (feature.image_url && feature.image_url.length > 0)
        ? feature.image_url[0]
        : ''
    }

    if (feature && type === 'video') {
        url = (!desktop && feature.video_thumbnail && feature.video_thumbnail.length > 0)
        ? feature.video_thumbnail[0]
        : (feature.video_poster && feature.video_poster.length > 0)
        ? feature.video_poster[0]
        : ''
    }
    return url
  }