export const pageTrack = () => {
    setTimeout(() => {
        let PARSELY = window.PARSELY || null;
        if(PARSELY) {
            PARSELY.beacon.trackPageView()
            PARSELY.spa = PARSELY.spa || {};
            PARSELY.spa.autotrack = false;
        }
    }, 700)
    
}