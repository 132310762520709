import React, {Fragment} from 'react'
import {withRouter} from 'react-router-dom'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ScrollUpButton from 'react-scroll-up-button'

class ScrollOnPage extends React.Component {
    componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
        window.scrollTo(0, 0)
      }
    }
  
    render() { 
        return (
            <Fragment>
                {this.props.children}
                <ScrollUpButton
                ContainerClassName="backToTopContainer"
                TransitionClassName="backToTopTransition"
                EasingType="easeOutCubic"
                StopPosition={17}
                AnimationDuration={1000}
              >
                <ArrowUpwardIcon style={{color: '#CC0000', fontSize:'2rem'}} />
              </ScrollUpButton>
            </Fragment> 
        )
    }
  }
  
export default withRouter(ScrollOnPage)