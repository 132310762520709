import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import idx from 'idx'
import {getValueByAttribute} from '../../Utils/DataExtract'


const useStyles = makeStyles((theme) => ({
    map: {
        textAlign: 'center',
        width: '100%',
        height: 700,
        [theme.breakpoints.down('xs')]: {
            height: 350
        },
        paddingTop: theme.spacing(4)
    },
    pointer: {
        background: '#fff',
        padding: 6,
        width: 147,
        borderRadius: 7,
        fontSize: 12,
        color: '#cc0000',
        fontWeight: 'bold'
    },
    mapHeading: {
        textAlign: 'left'
    }
}))

const Map = (props) => {
    const { component } = props
    const values = idx(component, (_) => _.attributes.values)
    const classes = useStyles()
    return (
        <>
            <div className={classes.map}>
                <Typography variant={"h3"} className={classes.mapHeading}>
                    {getValueByAttribute(values, 'heading')}
                </Typography>
                <iframe
                    title={getValueByAttribute(values, 'map_title')}
                    width="100%"
                    height="80%"
                    src={getValueByAttribute(values, 'map_url')}
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0">
                </iframe>
            </div>
        </>
    )
}

export default Map
