import React, { useEffect, useState } from 'react'
import {
    makeStyles,
    Typography,
    Grid,
    Button,
    Box,
    Card,
    CardMedia,
    CardContent,
} from '@material-ui/core'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import { motion, useAnimation } from 'framer-motion'
import { SmallZoomEntry } from '../../Utils/AnimationEffects'
import { useInView } from "react-intersection-observer"
import Video from '../../ComponentsList/Video/Video'
import idx from 'idx'
import { getValueByAttribute } from '../../Utils/DataExtract'

const useStyles = makeStyles((theme) => ({
    wrapper:{
        marginTop: '40px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '0',
        },
    },
    alterHeading:{
        marginTop:'40px',
        [theme.breakpoints.down('sm')]: {
            marginTop:'20px',
        }
    },
    alterText:{
        fontSize:'24px',
        textAlign:'center',
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            fontSize:'18px',
            padding: '10px',
        }
    },
    storyWrapperTwo:{
        justifyContent:'space-around',
    },
    storyWrapperThree:{
        justifyContent:'center',
        paddingTop: '0px',
    },
    playButton: {
        color: 'white',
        backgroundColor: '#cc0000',
        borderRadius: 30,
        minWidth: '80px',
        maxWidth: 170,
        marginBottom: '3%',
        fontSize: 16,
        border: 'none',
        padding: '8px 18px',
        '&:hover': {
        border: 'none',
        backgroundColor: '#cc0000',
        },
        '& span': {
            color: 'white!important'
        },
        textTransform: 'none',
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
            width: '100%',
            height: 35
        }
    },
    storyBoxBase: {
        width: '100%',
        position: 'relative',
        borderRadius: 0,
        background: '#efefef',
        marginBottom: theme.spacing(2),
    },
    storiesContainer: {
        marginLeft: '10%',
        marginRight: '10%'
    },
    storyCard: {
        background: 'transparent'
    },
    headerSection: {
        // marginTop: 60,
        [theme.breakpoints.down('md')]: {
            marginTop: 35,
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: 20,
        }
    },
    headerText: {
        textAlign: 'center',
    },
    subText: {
        textAlign: 'center',
        paddingBottom: '20px',
        [theme.breakpoints.down('md')]: {
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4)
        }
    },
    storyDetails: {
        textAlign: 'center'
    },
    storyLine: {
        textAlign: 'center',
        fontSize: '1.2rem',
        marginBottom: 30,
        [theme.breakpoints.down('md')]: {
            // fontSize: 15,
            height: 100,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 15,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            marginBottom: 20,
            height: 50,
            lineHeight: '21px',
        }
    },
    tmName: {
        color: '#CC0000',
        fontWeight: 'bold',
        fontSize: '1rem',
        [theme.breakpoints.down('md')]: {
            // fontSize: 15,
        },
        [theme.breakpoints.down('sm')]: {
            // fontSize: 12,
            marginTop: 0
        }
    },
    tmPosition: {
        color: '#333333',
        fontSize: '1rem',
        [theme.breakpoints.down('md')]: {
            // fontSize: 15,
        },
        [theme.breakpoints.down('sm')]: {
            lineHeight: '21px',
        }
    },
    storyLink: {
        marginTop: 30,
        borderRadius: 35,
        color: "#CC0000",
        borderColor: '#CC0000',
        // fontSize: 16,
        textTransform: 'none',
        [theme.breakpoints.down('md')]: {
            fontSize: 14,
            marginTop: 28,
        },
        [theme.breakpoints.down('sm')]: {
            // fontSize: 12,
            marginTop: 26
        }
    },
    storyBox: {
        padding: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
            padding: 0,
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3)
        },
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            paddingBottom: theme.spacing(3),
        },
        [theme.breakpoints.down('xs')]: {
            padding: 0,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            paddingBottom: theme.spacing(3),
        }
    },
    media: {
        // padding: theme.spacing(6),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(1),
            paddingTop: 0,
            paddingBottom: 0,
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            paddingTop: 0,
            paddingBottom: 0,
        },
        paddingTop: 0,
        paddingBottom: 0,
    },
    buttonHolder:{
        display: 'flex',
        justifyContent: 'center',
    }
}));



const StoryBox = props => {
    const { component, desktop } = props
    const values = idx(component, (_) => _.attributes.values)
    const { stories, spacing } = values
    const classes = useStyles()
    const [videoModelOpen, setVideoModelOpen] = useState(false)
    const [currentVideo, setCurrentVideo] = useState(null)
    const background = desktop ? `url(${encodeURI(getValueByAttribute(values, 'desktop_background_image'))})` : `url(${encodeURI(getValueByAttribute(values, 'mobile_background_image'))})`
    const backgroundStyle = {
        backgroundImage: background,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    }
    const controls = useAnimation();
    const [ref, inView] = useInView({ triggerOnce: false });

    useEffect(() => {
        if (inView) {
            setTimeout(() => {
                controls.start("visible");
            }, 300)

        }
    }, [controls, inView])

    const openVideoModel = (story) => {
        let videoData = {
            title: getValueByAttribute(story, 'video_title'),
            poster: getValueByAttribute(story, 'video_poster_image'),
            source: getValueByAttribute(story, 'video_source', true),
            tracks: getValueByAttribute(story, 'video_tracks', true),
            transcript: getValueByAttribute(story, 'video_transcript', true),
        }
        setCurrentVideo(videoData)
        setVideoModelOpen(true)
    }

    const closeVideoModel = () => {
        setVideoModelOpen(false)
        setCurrentVideo(null)
    }


    return (
        <>
         {/* {getValueByAttribute(values, 'alter_heading') && (
                        <Grid item xs={12} style={{ fontSize: '24px'}} className={classes.wrapper}>
                            <Typography className={classes.alterTextMob} variant={"h2"}>{getValueByAttribute(values, 'alter_heading')}</Typography>
                        </Grid>
                    )} */}
        
            <div
                style={backgroundStyle}
                className={classes.storyBoxBase}
                id={getValueByAttribute(values, 'heading') ? (getValueByAttribute(values, 'heading').split("")[0].toLowerCase()) : "storybox"}
            >
                <Grid
                    container
                    spacing={0}
                    direction="row"
                   
                >
                     {getValueByAttribute(values, 'heading') && (
                        <Grid item xs={12} style={{ marginTop: '60px' }} className={classes.headerSection}>
                            <Typography className={classes.headerText} variant={"h3"}>{getValueByAttribute(values, 'heading')}</Typography>
                        </Grid>
                    )}
                      {getValueByAttribute(values, 'alter_heading') && (
                        <Grid item xs={12} style={{ fontSize: '24px' }} className={classes.alterHeading} >
                            <Typography className={classes.alterText} variant={"h2"}>{getValueByAttribute(values, 'alter_heading')}</Typography>
                        </Grid>
                    )}
                   
                    {getValueByAttribute(values, 'heading') && (
                        <Grid item xs={12} className={classes.headerSection}>
                            <Typography className={classes.subText}>{getValueByAttribute(values, 'sub_heading')}</Typography>
                        </Grid>
                    )}
                    <Grid item xs={12} className={classes.storiesContainer}>
                    
                        <Grid container justify="center" ref={ref} spacing={desktop ? (spacing ? spacing : 0) : 0} className={stories.length === 2 ? classes.storyWrapperTwo : classes.storyWrapperThree}>
                            {stories && stories.length > 0 && stories.filter(story => !(story.archived && story.archived[0])).map((story, storyKey) => {
                                    return (
                                        <Grid item md={getValueByAttribute(story, 'width') ? parseInt(getValueByAttribute(story, 'width')) : 6} lg={4} sm={8} xs={11} key={storyKey} className={classes.storyBox}>
                                            {/* <AnimatedOnScroll animationIn={'fadeIn'}> */}
                                            <motion.div
                                                animate={controls}
                                                initial="hidden"
                                                variants={{
                                                    visible: { ...SmallZoomEntry.animate, opacity: 1 },
                                                    hidden: { ...SmallZoomEntry.initial, scale: 0.8, opacity: 0 }
                                                }}
                                                transition={{
                                                    duration: 1
                                                }}
                                            >
                                                <Card className={classes.storyCard} elevation={0}>
                                                    <CardMedia
                                                        component="img"
                                                        className={classes.media}
                                                        image={`${getValueByAttribute(story, 'story_image')}`}
                                                        title="Team Member Story"
                                                        alt={getValueByAttribute(story, 'team_member_name')}
                                                    />
                                                    <CardContent style={{paddingRight:"0px"}}>
                                                        <Box display="flex" justifyContent="center">
                                                            <div className={classes.storyDetails}>
                                                            <Typography className={classes.storyLine}>{getValueByAttribute(story, 'story_line')}</Typography>
                                                                <Typography className={classes.tmName}>{getValueByAttribute(story, 'team_member_name')}</Typography>
                                                                <Typography className={classes.tmPosition}>{getValueByAttribute(story, 'team_member_designation')}{getValueByAttribute(story, 'department') && (<><br/><span>{getValueByAttribute(story, 'department')} </span></>)}</Typography>
                                                                <Button variant="outlined" className={classes.storyLink} disableElevation onClick={() => openVideoModel(story)}>
                                                                    {getValueByAttribute(story, 'button_text') ? getValueByAttribute(story, 'button_text') : `${getValueByAttribute(story, 'team_member_name')}'s story`}
                                                                </Button>
                                                            </div>
                                                        </Box>
                                                    </CardContent>
                                                </Card>
                                            </motion.div>

                                        </Grid>
                                    )
                            })}
                            {getValueByAttribute(values, 'page_link') && (
                                <Grid item sm={8} xs={11} className={classes.buttonHolder}>
                                        <Button href={getValueByAttribute(values, 'page_link').slug} variant="outlined" color="primary" endIcon={<PlayArrowIcon />} className={classes.playButton}>View all stories</Button>
                                </Grid>
                                )}
                                </Grid>
                    </Grid>
                </Grid>
            </div>
            <Video open={videoModelOpen} video={currentVideo} close={closeVideoModel} />
        </>
    )
}

export default StoryBox