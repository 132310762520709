import React from 'react'
import { makeStyles, Typography, Grid } from '@material-ui/core'
import StoryTile from '../StoryTile/StoryTile'
import { getValueByAttribute } from '../../Utils/DataExtract'
import idx from 'idx'

const useStyles = makeStyles((theme) => ({
  storyBoxBase: {
    padding: theme.spacing(4)
  },
  heading: {
    textAlign: 'center',
    marginBottom: theme.spacing(8),
    color: '#333333',
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(3)
    }
  }
}))

const StoryGrid = (props) => {
  const { component, desktop } = props
  const values = idx(component, (_) => _.attributes.values)
  const { styling, tiles, spacing } = values
  const classes = useStyles()

  const defaultBoxStyle = {}
  const boxStyle =
    styling && styling.box
      ? Object.assign({}, defaultBoxStyle, styling.box)
      : defaultBoxStyle

  const background = desktop
    ? values.backgroundImage && values.backgroundImage[0]
      ? `url(${encodeURI(values.backgroundImage[0])})`
      : ''
    : values.backgroundImage && values.backgroundImage[1]
    ? `url(${encodeURI(values.backgroundImage[1])})`
    : ''

  const backgroundStyle = {
    backgroundImage: background,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    ...boxStyle
  }

  return (
    <div style={backgroundStyle} className={classes.storyBoxBase}>
      {getValueByAttribute(values, 'heading') && (
        <Typography variant={'h3'} className={classes.heading}>
          {getValueByAttribute(values, 'heading')}
        </Typography>
      )}
      {getValueByAttribute(values, 'sub_heading') && (
        <Typography className={classes.subHeading}>
          {getValueByAttribute(values, 'sub_heading')}
        </Typography>
      )}
      <Grid
        container
        className={classes.dataContainer}
        spacing={spacing ? spacing : 8}
      >
        {tiles &&
          tiles.length > 0 &&
          tiles.map((tile, index) => {
            return (
              <Grid item xs={12} md={tile.width} key={index}>
                <StoryTile values={tile} desktop={desktop} />
              </Grid>
            )
          })}
      </Grid>
    </div>
  )
}

export default StoryGrid
