import {
  FETCH_PAGE_DATA_SUCCESS,
  FETCH_PAGE_DATA_FAILED,
  FETCH_PAGE_DATA_IN_PROGRESS,
  OPEN_SEARCH_BOX,
} from './actionType'
import axios from 'axios'
import apiConfig from '../../apiConfig'
import { pageTrack } from '../../components/Utils/Tracking'

export function getPageDataInProgress() {
  return {
    type: FETCH_PAGE_DATA_IN_PROGRESS,
  }
}

export function getPageDataSuccess(data, slug) {
  return {
    type: FETCH_PAGE_DATA_SUCCESS,
    data,
    slug
  }
}

export function getPageDataFailed(error) {
  return {
    type: FETCH_PAGE_DATA_FAILED,
    error
  }
}

export function getPageData(slug) {
  return function(dispatch) {
    dispatch(getPageDataInProgress())
    const config = {
      method: 'get',
      url: apiConfig.preview ? `${apiConfig.apiHost}/pages?filter=${JSON.stringify({where: {slug: slug}})}` : `${apiConfig.apiHost}/pages`,
      headers: { 
        'Content-Type': 'application/json',
      },
    }
    if(!apiConfig.preview) {
      config.params = {
        slug,
        key: apiConfig.xApiKey
      }
    }
    return axios(config).then(response => {
      dispatch(getPageDataSuccess(apiConfig.preview && response.data && response.data[0] ? response.data[0] : response.data, slug))
      pageTrack()
    })
    .catch(error => {
      dispatch(getPageDataFailed(error))
    })
  }
}

export function openSearchBox()
{
  return function (dispatch) {
    dispatch({
      type: OPEN_SEARCH_BOX,
    })
  }
}

export function getVersionData(id, slug) {
  return function(dispatch) {
    if(apiConfig.preview) {
      dispatch(getPageDataInProgress())
      const config = {
        method: 'get',
        url: `${apiConfig.apiHost}/page-versions/${id}`,
        headers: { 
          'Content-Type': 'application/json',
        },
      }
      return axios(config).then(response => {
        dispatch(getPageDataSuccess(response.data && response.data.page_data ? response.data.page_data : null , slug))
      })
      .catch(error => {
        dispatch(getPageDataFailed(error))
      })
    }
  }
}