import React, { useState, Fragment, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import targetLogoImg from '../../images/Target_2021_Logo.png'
import { useHistory, useLocation } from 'react-router-dom'
import Grow from '@material-ui/core/Grow'
import { Divider, Grid, Paper, Chip } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Image from 'material-ui-image'
import MenuIcon from '@material-ui/icons/Menu'
import classNames from 'classnames'
import { Link as RLink } from 'react-router-dom'
import FocusTrap from 'focus-trap-react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import apiConfig from '../../apiConfig'
import SearchIcon from '@material-ui/icons/Search'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import GPTWIcon from '../../images/GPTW_Certified_RGB_HIGH_RES_PNG.png'
// import zIndex from '@material-ui/core/styles/zIndex'
// import { initial } from 'lodash'
import axios from 'axios'


const { apiHost, xApiKey } = apiConfig

const useStyles = makeStyles((theme) => ({
    title: {
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
        [theme.breakpoints.up('md')]: {
            // width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            top: '20%',
            left: '38%',
            width: '30%',
            position: 'absolute',
        },
        [theme.breakpoints.down('xs')]: {
            position: 'absolute',
            left: '35%',
            top: '32%',
            width: '30%',
        },
        width: 500,
    },
    targetLogo: {
        cursor: 'pointer',
        width: 118,
        [theme.breakpoints.up('lg')]: {
            width: 118
        },
        [theme.breakpoints.up('xl')]: {
            width: 118
        },
        [theme.breakpoints.down('sm')]: {
            width: 118
        }
    },
    logoLink: {
        '&:focus': {
            outline: 'none'
        }
    },
    skipNav: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'fixed',
        width: 1,
        '&:focus': {
            background: 'white',
            clip: 'auto',
            height: 'auto',
            left: 0,
            top: 0,
            width: 'auto',
            margin: 0,
            zIndex: 1500,
            outline: 0,
            textDecoration: 'underline',
            border: '1px dashed #d3d3d3',
            padding: 3,
        }
    },
    toolBar: {
        height: 90,
        paddingLeft: theme.spacing(10),
        paddingRight: theme.spacing(10),
        marginRight: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
        }
    },
    nav: {
        width: '100%',
        display: 'inline-block',
        textAlign: 'right',
    },
    mainMenuBlock: {
        display: 'none',
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            display: 'inline-block',
        }
    },
    menuUL: {
        padding: 0,
        marginTop: '1rem',
        marginBottom: '1rem',
    },
    menuItems: {
        color: '#707070',
        fontSize: '0.9rem',
        position: 'relative',
        display: 'inline-block',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        textDecoration: 'none',
        '&:focus': {
            outline: 'none',
            textDecoration: 'underline'
        },
        '&:hover': {
            color: '#707070',
            textDecoration: 'underline'
        },
        '&:focus:before': {
            content: '""',
            top: -35,
            width: '100%',
            height: '100%',
            position: 'absolute',
            borderTop: '4px solid #b5b5b5'
        },
        '&:hover:before': {
            content: '""',
            top: -35,
            width: '100%',
            height: '100%',
            position: 'absolute',
            borderTop: '4px solid #b5b5b5'
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 10,
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 8
        }
    },
    mobMenuItems: {
        color: '#333333',
        fontSize: '1rem',
        position: 'relative',
        fontWeight: 500,
        textDecoration: 'none',
        display: 'block',
        padding: theme.spacing(2),
        '&:focus': {
            outline: '1px dotted'
        }
    },
    mobSelectedMenu: {
        fontWeight: 'bold',
        color: '#333333',
        '&:hover': {
            color: '#333333',
        }
    },
    subMenuItems: {
        color: '#333333',
        textDecoration: 'none',
        fontSize: '0.8rem',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        '&:focus': {
            outline: '1px dotted',
            [theme.breakpoints.down('sm')]: {
                padding: 5,
            }
        },
        '&:hover': {
            textDecoration: 'underline',
            color: '#cc0000'
        },
    },
    subMenuStyle: {
        [theme.breakpoints.up('md')]: {
            width: 600,
        },
        [theme.breakpoints.down('md')]: {
            width: 550,
        },
        [theme.breakpoints.down('sm')]: {
            width: 450,
        },
        minHeight: 350,
        background: '#EFEFEF',
        padding: theme.spacing(4),
        top: '55px !important',
        position: 'absolute',
        right: theme.spacing(0),
        zIndex: 1,
        boxShadow: '0px 10px 13px -8px rgb(0 0 0 / 0.5)'
    },
    selectedMenu: {
        color: '#666666',
        // fontWeight: 'bold',
        '&::before': {
            content: '""',
            top: -35,
            width: '100%',
            height: '100%',
            position: 'absolute',
            borderTop: '4px solid #b5b5b5'
        },
        '&::after': {
            content: '""',
            width: 0,
            height: 0,
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            borderBottom: '10px solid #EFEFEF',
            position: 'absolute',
            bottom: 0,
            left: '40%',
            top: 46,
            animation: `$showArrow 1000ms ${theme.transitions.easing.easeInOut}`
        }
    },
    "@keyframes showArrow": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        }
    },
    closeSubMenu: {
        position: 'absolute',
        right: 0,
        top: 5,
        color: '#858282',
        '&:focus': {
            outline: '1px dotted'
        }
    },
    featuredImage: {
        padding: theme.spacing(2)
    },
    subMenuLinks: {
        textAlign: 'left',
    },
    groupTitle: {
        fontSize: '1rem',
        fontWeight: 'bold',
        color: '#333333',
        marginBottom: theme.spacing(1),
        textDecoration: 'none',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
            fontWeight: 'bold',
        },
        '&:hover': {
            color: '#cc0000',
            textDecoration: 'underline',
        }
    },
    menuTitle: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        color: '#333333',
        marginBottom: theme.spacing(1),
        textDecoration: 'none',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
            fontWeight: 'bold',
        },
        '&:hover': {
            color: '#cc0000',
            textDecoration: 'underline'
        }
    },
    listStyle: {
        listStyle: 'none',
        padding: 0,
        // paddingBottom: theme.spacing(2),
        // paddingTop: theme.spacing(1),
    },
    mobListStyle: {
        listStyle: 'none',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(1),
        position: 'relative',
        padding: theme.spacing(2),
    },
    groupOfMenu: {
        // minHeight: 100,
    },
    subMenuLinkArea: {
        width: 'calc(100% * 0.5)',
        display: 'inline-flex'
    },
    /** Mobile Menu */
    mobMenuButton: {
        position: 'absolute',
        left: theme.spacing(2),
        top: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    mobileMenuOverlay: {
        display: 'block',
        position: 'relative',
        padding: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
        [theme.breakpoints.down('md')]: {
            top: 90 + 'px !important',
        },
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 3,
            background: '#fff',
            listStyle: 'none',
            left: 0,
            overflow: 'auto',
            top: 74,
            paddingBottom: 400
        },
        textAlign: 'left',
        minHeight: '100vh'
    },
    expandIcon: {
        position: 'absolute',
        right: 20
    },
    mobSubMenu: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        textDecoration: 'none',
        display: 'block',
        color: '#333333',
        fontSize: 15,
        '&:focus': {
            fontWeight: 'bold',
            color: '#CC0000'
        }
    },
    mobGroupMenus: {
        paddingLeft: 0,
        listStyle: 'none',
    },
    mobGroupTitleMain: {
        textDecoration: 'none',
        width: '100%',
        display: 'inline-block',
        // fontWeight: 'bold',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        fontSize: 16,
        color: '#333333',
        '&:focus': {
            outline: 'none',
            color: '#CC0000'
        }
    },
    mobSubMenuClose: {
        textDecoration: 'none',
        width: '100%',
        display: 'inline-block',
        fontWeight: 'bold',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        fontSize: 18,
        position: 'relative',
        '&:focus': {
            outline: 'none',
            color: '#CC0000'
        }
    },
    closeGroup: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        width: 1,
        '&:focus': {
            position: 'absolute',
            background: 'white',
            clip: 'auto',
            height: 'auto',
            left: 0,
            top: 0,
            width: 'auto',
            margin: 0,
            zIndex: 1500,
            outline: 0,
            textDecoration: 'underline',
            border: '1px dashed #d3d3d3',
            padding: 3,
        }
    },
    groupMenuTile: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    mobGroupTitle: {
        textDecoration: 'none',
        width: '80%',
        display: 'inline-block',
        fontWeight: 'bold',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        fontSize: 18,
        // verticalAlign: 'top',
        // marginLeft: theme.spacing(5),
        color: '#000',
        '&:focus': {
            outline: 'none',
            color: '#CC0000'
        }
    },
    backButton: {
        verticalAlign: 'middle',
        display: 'inline-block',
        color: '#333333',
        '&:focus': {
            outline: 'none',
            color: '#CC0000'
        }
    },
    rightArrow: {
        position: 'absolute',
        right: '5%'
    },
    collapseArrow: {
        verticalAlign: 'bottom',
        fontSize: '1.2rem'
    },
    mainMenuLink: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    currentMenu: {
        color: '#cc0000 !important',
        '&::before': {
            content: '""',
            top: -35,
            width: '100%',
            height: '100%',
            position: 'absolute',
            borderTop: '4px solid',
        },
        '&:focus:before': {
            content: '""',
            top: -35,
            width: '100%',
            height: '100%',
            position: 'absolute',
            borderTop: '4px solid #cc0000',
        },
        '&:hover:before': {
            content: '""',
            top: -35,
            width: '100%',
            height: '100%',
            position: 'absolute',
            borderTop: '4px solid #cc0000',
        }
    },
    showSearch: {
        display: 'block',
    },
    hideSearch: {
        display: 'none',
    },
    searchContainer: {
        width: '80%',
        margin: '10px auto 30px auto'
    },
    searchField: {
        width: '100%',
        background: '#fafafa',
        borderRadius: 0,
        '&:focus': {
            outline: 'initial',
        }
    },
    searchResult: {
        width: '100%',
        padding: '15px',
        background: '#fff',
        borderTop: 0,
        borderBottom: '1px solid',
        borderLeft: '1px solid',
        borderRight: '1px solid',
        borderColor: '#d3d3d3 !important',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    searchIcon: {
        [theme.breakpoints.down('sm')]: {
            top: 24,
            right: 16,
            position: 'absolute'
        }
    },
    notchedOutline: {
        borderWidth: '1px !important',
        borderColor: 'rgb(0 0 0 / 23%) !important',
    },
    gptw: {
        height: 154,
        right: 41,
        top: 0,
        paddingLeft: 20,
        position: 'absolute',
        [theme.breakpoints.down("md")]: {
            height: 58,
            right: 75,
            top: 25,
        },
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            height: 58,
        },
        [theme.breakpoints.down('xs')]: {
            height: 58,
            right: 75,
            top: 25,
        }
    }
}))

const renderMenus = (props) => {
    const { menuItems, classes, mainMenuClick, subMenuOpen, subMenuClose, selectedMenu, desktopMenu, currentMenu } = props
    const getMenuClasses = (selectedMenu, menu, currentMenu, classes) => {
        let menuClasses = [
            classes.menuItems
        ]
        if (selectedMenu && selectedMenu.slug === menu.slug) {
            menuClasses.push(classes.selectedMenu)
        }
        if (currentMenu && currentMenu.indexOf(menu.slug) === 0) {
            menuClasses.push(classes.currentMenu)
        }
        return classNames(...menuClasses)
    }
    return (
        <>
            {desktopMenu && (
                <ul className={classes.menuUL}>
                    {menuItems && menuItems.map((menu, menuIndex) => {
                        return (
                            <Fragment key={menuIndex}>
                                <li className={classes.mainMenuBlock}>
                                    <RLink to={'#'} aria-expanded={selectedMenu && selectedMenu.slug === menu.slug ? true : false} className={getMenuClasses(selectedMenu, menu, currentMenu, classes)} onClick={(event) => mainMenuClick(event, menu)}>
                                        {menu.title.toUpperCase()}
                                        {menu.children && (selectedMenu && selectedMenu.slug === menu.slug ? <KeyboardArrowUpIcon className={classes.collapseArrow} /> : <KeyboardArrowDownIcon className={classes.collapseArrow} />)}
                                    </RLink>
                                    {subMenuOpen && selectedMenu && selectedMenu.slug === menu.slug && (
                                        <Fragment key={menuIndex}>
                                            <FocusTrap focusTrapOptions={{ clickOutsideDeactivates: true }}>
                                                <Grow in={true}>
                                                    <Paper className={classes.subMenuStyle} square elevation={0}>
                                                        <Grid container>
                                                            <Grid item xs={7} className={classes.subMenuLinks}>
                                                                <div className={classes.mainMenuLink}>
                                                                    <RLink to={menu.slug ? menu.slug : '#'} className={classes.menuTitle} onClick={subMenuClose}>{menu.title}</RLink>
                                                                </div>
                                                                <Divider />
                                                                {menu.children && menu.children.map((groupMenu, gIndex) => {
                                                                    return (
                                                                        <div key={gIndex} className={classes.groupOfMenu}>
                                                                            {/* <Typography className={classes.groupTitle}>{groupMenu.title}</Typography> */}
                                                                            <div className={classes.groupMenuTile}>
                                                                                <RLink to={groupMenu.slug ? groupMenu.slug : '#'} className={classes.groupTitle} onClick={subMenuClose}>{groupMenu.title}</RLink>
                                                                            </div>

                                                                            {groupMenu.children && groupMenu.children.length > 0 &&
                                                                                <ul className={classes.listStyle}>
                                                                                    {groupMenu.children && groupMenu.children.map((subMenu, index) => {
                                                                                        if (subMenu.display_in_main_nav) {
                                                                                            return (
                                                                                                <li className={classes.subMenuLinkArea} key={`${menuIndex}-${gIndex}-${index}`}>
                                                                                                    <RLink to={subMenu.slug} className={classes.subMenuItems} key={`${subMenu.slug}-${index}-${gIndex}`} onClick={subMenuClose}>
                                                                                                        {`${subMenu.title}`}
                                                                                                    </RLink>
                                                                                                </li>
                                                                                            )
                                                                                        } else {
                                                                                            return ''
                                                                                        }
                                                                                    })}
                                                                                </ul>
                                                                            }
                                                                            <Divider />
                                                                        </div>
                                                                    )
                                                                })}
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <div className={classes.featuredImage}>
                                                                    <Image
                                                                        src={`${menu.feature_image}`}
                                                                        disableSpinner
                                                                        style={{ background: 'transparent' }}
                                                                        alt={menu.feature_alt_text ? menu.feature_alt_text : ''}
                                                                    />
                                                                </div>
                                                                <div className={classes.featuredImage}>
                                                                    <Typography variant="caption">{menu.feature_text}</Typography>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                        <IconButton aria-label="close sub menu" color="primary" className={classes.closeSubMenu} onClick={() => subMenuClose()}>
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </Paper>
                                                </Grow>
                                            </FocusTrap>
                                        </Fragment>
                                    )}
                                </li>
                            </Fragment>
                        )
                    })}
                </ul>
            )}
        </>
    )
}

const renderMobileMenu = (props) => {
    const { classes, setMobileMenuOpen, mobMenuOpen, menuItems, selectedMenu, mainMenuClick, subMenuOpen, subMenuClose, activeGroupMenu, setActiveGroupMenu, setSelectedMenu, history } = props
    const mobileMenuLinkClick = () => {
        setMobileMenuOpen(false)
        setActiveGroupMenu(null)
        setSelectedMenu(null)
    }

    const clickGroupMenu = (menu) => {
        if (havingChild(menu)) {
            setActiveGroupMenu(menu)
        } else {
            history.push(menu.slug)
            setMobileMenuOpen(false)
            setActiveGroupMenu(null)
            setSelectedMenu(null)
        }
    }

    const havingChild = (menu) => {
        let childs = menu.children && menu.children.filter((cMenu) => cMenu.display_in_main_nav === true)
        return menu.children && menu.children.length > 0 && childs.length > 0
    }

    return (
        <>
            <IconButton aria-label={mobMenuOpen ? 'close menu' : 'open menu'} onClick={() => setMobileMenuOpen(!mobMenuOpen)} className={classes.mobMenuButton}>{mobMenuOpen ? <CloseIcon /> : <MenuIcon />}</IconButton>
            {mobMenuOpen &&
                <ul className={classes.mobileMenuOverlay}>
                    {!activeGroupMenu && menuItems && menuItems.map((menu, menuIndex) => {
                        return (
                            <Fragment key={menuIndex}>
                                <li className={classes.mobMainMenu}>
                                    <RLink to="#" aria-expanded={selectedMenu && selectedMenu.slug === menu.slug ? true : false} className={selectedMenu && selectedMenu.slug === menu.slug ? classNames(classes.mobSelectedMenu, classes.mobMenuItems) : classes.mobMenuItems} onClick={(event) => mainMenuClick(event, menu)}>
                                        {menu.title.toUpperCase()}
                                        {menu.children &&
                                            menu.children.length > 0 && (
                                                selectedMenu && selectedMenu.slug === menu.slug ? <ExpandLessIcon className={classes.expandIcon} /> : <ExpandMoreIcon className={classes.expandIcon} />
                                            )
                                        }
                                    </RLink>
                                    {subMenuOpen && selectedMenu && selectedMenu.slug === menu.slug && (
                                        <Fragment key={menuIndex}>
                                            <FocusTrap focusTrapOptions={{ clickOutsideDeactivates: true }}>
                                                <Grow in={true}>
                                                    <ul className={classes.mobListStyle}>
                                                        <li>
                                                            <ul className={classes.mobGroupMenus}>
                                                                <li>
                                                                    <div className={classes.mobMainMenu}>
                                                                        <RLink to="#" onClick={() => clickGroupMenu(menu)} className={classes.mobGroupTitleMain}>
                                                                            {menu.title + ' Overview'}
                                                                        </RLink>
                                                                        <Divider />
                                                                    </div>
                                                                </li>
                                                                {menu.children && menu.children.map((groupMenu, gIndex) => {
                                                                    return (
                                                                        <li key={`${menuIndex}-${gIndex}`} className={classes.mobMainMenu}>
                                                                            <div key={gIndex} className={classes.mobGroupOfMenu}>
                                                                                <RLink to="#" aria-expanded={selectedMenu && selectedMenu.title === groupMenu.title} onClick={() => clickGroupMenu(groupMenu)} className={classes.mobGroupTitleMain}>
                                                                                    {groupMenu.title}
                                                                                    {havingChild(groupMenu) === true && <ChevronRightIcon className={classes.rightArrow} />}
                                                                                </RLink>
                                                                                <Divider />
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </li>
                                                        <li className={classes.mobSubMenuClose}>
                                                            <RLink to={'#'} className={classes.closeGroup} onClick={subMenuClose}>Close</RLink>
                                                        </li>
                                                    </ul>
                                                </Grow>
                                            </FocusTrap>
                                        </Fragment>
                                    )}
                                </li>
                                <Divider />
                            </Fragment>
                        )
                    })}
                    {activeGroupMenu && (
                        <FocusTrap focusTrapOptions={{ clickOutsideDeactivates: true }}>
                            <Grow in={true}>
                                <ul className={classes.mobGroupMenus}>
                                    <li>
                                        <RLink to="#" aria-expanded={true} onClick={() => setActiveGroupMenu(null)} alt={'go back to previous menu'} className={classes.mobGroupTitle}>
                                            <ChevronLeftIcon className={classes.backButton} />
                                            {activeGroupMenu.title}
                                        </RLink>
                                        {/* <RLink to={activeGroupMenu.slug} className={classes.mobGroupTitle} onClick={() => mobileMenuLinkClick(null)}>{activeGroupMenu.title}</RLink> */}
                                        {/* <Typography className={classes.mobGroupTitle}>
                                            {activeGroupMenu.title}
                                        </Typography> */}
                                        <Divider />
                                    </li>
                                    <li>
                                        <ul className={classes.mobGroupMenus}>
                                            {activeGroupMenu.children && activeGroupMenu.children.map((subMenu, index) => {
                                                return (
                                                    <li key={`${subMenu.slug}-${index}`}>
                                                        <RLink className={classes.mobSubMenu} to={subMenu.slug} key={`${subMenu.slug}-${index}`} onClick={() => mobileMenuLinkClick(null)}>
                                                            {`${subMenu.title}`}
                                                        </RLink>
                                                        <Divider />
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </li>
                                </ul>
                            </Grow>
                        </FocusTrap>
                    )}
                </ul>
            }
        </>

    )
}


export const SkipNavLink = ({ id, classes, children = 'Skip to main content', ...props }) => {
    return (
        <a {...props} href={`#${id}`} className={classes.skipNav}>
            {children}
        </a>
    );
}

export default function HeaderSection(props) {

    const { menu } = props
    const history = useHistory()
    const location = useLocation()
    const classes = useStyles()
    const [subMenuOpen, setSubMenuOpen] = useState(false)
    const [selectedMenu, setSelectedMenu] = useState(null)
    const [mobMenuOpen, setMobileMenuOpen] = useState(false)
    const [activeGroupMenu, setActiveGroupMenu] = useState(null)
    const [currentMenu, setCurrentMenu] = useState(null)
    const [searchOpen, setSearchOpen] = useState(false)
    const [searchPage, setSearchPage] = useState(false)
    // const [gptwShow, setGptwShow] = useState(false)
    const gotoHome = () => {
        history.push('/')
    }
    const [values, setValues] = useState({
        search: ''
    })
    const [searchResult, setSearchResult] = useState([]);


    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value })
    }

    const handleSearch = () => {
        axios
            .get(
                `${apiHost}/search/autocomplete?keyword=${values.search.trim()}&key=${xApiKey}`
            )
            .then(function (response) {
                // handle success
                let rawData = response.data
                let searchResultAry = []
                let searchhResultCount = rawData.length

                for (let i = 0; i < searchhResultCount; i++) {
                    searchResultAry.push(rawData[i].key)
                }
                // setSearchResult(oldArray => [...oldArray, newElement]);
                setSearchResult(searchResultAry)
            })
            .catch(function (error) {
                // handle error
            })
    }

    useEffect(() => {
        if (values.search.length > 0) {
            handleSearch()
        }
        else if (values.search.length === 0) {
            setSearchResult([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.search]);

    useEffect(() => {
        const regex = new RegExp('/search*')
        if (regex.test(location.pathname)) {
            setSearchPage(true)
        } else {
            setSearchPage(false)
        }


        // const gptwRegex = new RegExp('/careers*')
        // if(gptwRegex.test(location.pathname)) {
        //     setGptwShow(true)
        // } else {
        //     setGptwShow(false)
        // }
    }, [location])

    useEffect(() => {
        return history.listen((location) => { 
           setSearchOpen(searchOpen)
        }) 
        // eslint-disable-next-line react-hooks/exhaustive-deps
     },[history]) 

    const chipSelect = (tag) => {
        history.push('/search/' + tag)
        handleToggle()
    }

    const handleClickSearch = () => {
        if (values.search) {
            history.push('/search/' + values.search)
            handleToggle()
        }
    }

    const handleToggle = () => {
        setSearchOpen(!searchOpen)
    }

    const desktopMenu = useMediaQuery(theme => theme.breakpoints.up('md'));

    const mainMenuClick = (event, menu) => {
        event.preventDefault()
        if (menu.children) {
            if (selectedMenu && menu.slug === selectedMenu.slug) {
                subMenuClose()
            } else {
                setSelectedMenu(menu)
                setSubMenuOpen(true)
            }
        } else {
            setMobileMenuOpen(false)
            history.push(menu.slug)
        }

    }

    const subMenuClose = () => {
        setSubMenuOpen(false)
        setSelectedMenu(null)
    }

    const onKeyDown = (e) => {
        if (e.keyCode === 13 && values.search) {
            e.preventDefault()
            handleClickSearch()
            // searchButton.current.trigger('click')
        }
    }

    const menuProps = {
        menuItems: menu ? menu.items : [],
        classes,
        history,
        selectedMenu,
        subMenuOpen,
        setSubMenuOpen,
        mainMenuClick,
        subMenuClose,
        mobMenuOpen,
        setMobileMenuOpen,
        desktopMenu,
        activeGroupMenu,
        setActiveGroupMenu,
        setSelectedMenu,
        currentMenu
    }

    useEffect(() => {
        if (mobMenuOpen) {
            document.body.classList.add('mob-menu-open-body')
        } else {
            document.body.classList.remove('mob-menu-open-body')
        }
    }, [mobMenuOpen])

    const havingChildPages = (menu) => {
        let childs = menu ? menu.children.filter((cMenu) => cMenu.display_in_main_nav === true) : 0
        return menu && menu.children && menu.children.length > 0 && childs.length > 0
    }

    useEffect(() => {
        if (!activeGroupMenu && !havingChildPages(activeGroupMenu)) {
            subMenuClose()
            setMobileMenuOpen(false)
            setCurrentMenu(location.pathname)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    return (
        <>
            <SkipNavLink id="main-content" classes={classes}>
                Skip to main content
            </SkipNavLink>
            <SkipNavLink id="footer" classes={classes}>
                Skip to footer
            </SkipNavLink>
            <AppBar position="fixed" style={{ zIndex: 1203 }} >
                <Toolbar className={classes.toolBar}>
                    <Typography className={classes.title} component="h1">
                        <RLink to="/" className={classes.logoLink}>
                            <img
                                onClick={gotoHome}
                                className={classes.targetLogo}
                                src={targetLogoImg}
                                alt={'Target Logo'}
                            />
                        </RLink>
                    </Typography>
                    <nav className={classes.nav}>
                        {menu && menu.items && (
                            <>
                                {renderMenus(menuProps)}
                                {!desktopMenu && renderMobileMenu(menuProps)}
                            </>
                        )}
                    </nav>
                    {!searchPage && (
                        <IconButton aria-label="search" color="inherit" onClick={handleToggle} className={classes.searchIcon}>
                            {searchOpen ? <CloseIcon /> : <SearchIcon />}
                        </IconButton>
                    )}
                    
                </Toolbar>
                <img
                        onClick={gotoHome}
                        className={classes.gptw}
                        src={GPTWIcon}
                        alt={'Great Place to work award Jul 2021 - Jul 2022 india'}
                    />
                {searchOpen && !searchPage && (
                    <FocusTrap focusTrapOptions={{ clickOutsideDeactivates: true }}>
                        <div className={classNames(classes.searchContainer, (searchOpen === true ? classes.showSearch : classes.hideSearch))}>
                            <OutlinedInput
                                id="outlined-adornment-search"
                                value={values.search}
                                className={classes.searchField}
                                onChange={handleChange('search')}
                                placeholder="Search"
                                onKeyDown={onKeyDown}
                                endAdornment={<InputAdornment position="end" ><SearchIcon style={{ cursor: 'pointer' }} onClick={handleClickSearch} /></InputAdornment>}
                                aria-describedby="type your search term and enter"
                                inputProps={{
                                    'aria-label': 'search',
                                }}
                                classes={{
                                    notchedOutline: classes.notchedOutline,
                                }}
                                labelWidth={0}
                            />
                            {searchResult.length > 0 && (
                                <div className={classes.searchResult}>
                                    Related Search:
                                    {searchResult.map((result, resultIndex) => {
                                        return (
                                            <Chip key={resultIndex} label={result} onClick={() => chipSelect(result)} style={{ cursor: 'pointer' }} />
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    </FocusTrap>
                )}
            </AppBar>
        </>
    )

}
