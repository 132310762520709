import React from 'react'
import { makeStyles, TextField, Button, Grid } from '@material-ui/core'
import {getValueByAttribute} from '../../Utils/DataExtract'
import idx from 'idx'

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: '#1592E6',
            },
            borderRadius: 0,
            height: 44.6
        },
        '& .MuiGrid-item': {
            padding: 0
        }

    },
    jobGrid: {
        textAlign: 'center'
    },
    textField: {
        padding: 0,
        [theme.breakpoints.down('xs')]: {
            marginBottom: theme.spacing(2)
        }
    },
    searchContainer: {
        marginTop: 30,
        // marginBottom: 30,
        paddingTop: 30,
        paddingBottom: 20
    },
    searchButton: {
        color: '#fff',
        background: '#cc0000',
        borderRadius: 0,
        padding: '10px 31px',
        maxHeight: 44.6,
        '&:hover': {
            color: '#fff',
            background: '#e40303',
        }
    },
    heading: {
        paddingBottom: theme.spacing(5)
    },
    fields: {
        padding: 0
    },
    gridOne: {
        padding: 0
    },
    gridTwo: {
        padding: 0
    }
}))

const SearchJobs = (props) => {
    const {component} = props
	const values = idx(component, (_) => _.attributes.values)
    const classes = useStyles()

    return (
        <>
            <div className={classes.searchContainer}>
                <Grid container justify="center" alignItems="center">
                    <Grid item md={8} xs={10} className={classes.jobGrid}>
                        <form className={classes.root} noValidate autoComplete="off" action={getValueByAttribute(values, 'job_portal_url')} method={'get'} target={'_blank'}>
                            <Grid container justify="center" alignItems="center" spacing={2} className={classes.fields}>
                                    <Grid item md={4} xs={12} className={classes.gridOne}>
                                        <TextField 
                                            variant={'outlined'}
                                            id="tb-search-form"
                                            name="k"
                                            placeholder={getValueByAttribute(values, 'textbox_placeholder') ? getValueByAttribute(values, 'textbox_placeholder') : 'Search jobs by keyword'}
                                            className={classes.textField}
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item md={1} xs={12} className={classes.gridTwo}>
                                        <input type="hidden" name="fl" value="1269750" />
                                        <Button variant="contained" className={classes.searchButton} type={'submit'}>
                                            {getValueByAttribute(values, 'search_button_text')  ? getValueByAttribute(values, 'search_button_text') : 'Search'}
                                        </Button>
                                    </Grid>
                                </Grid>
                        </form>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default SearchJobs
