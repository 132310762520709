import React, { Fragment } from 'react'
import { Typography, Link, makeStyles, Grid } from '@material-ui/core'
import { Link as RLink } from 'react-router-dom'
import targetLogo from '../../images/target-logo.png'
import twitter from '../../images/twitter.svg'
import linkedin from '../../images/linkedin.svg'
import instagram from '../../images/instagram.svg'

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    backgroundColor: '#000000',
    color: '#ffffff',
    textAlign: 'center',
    paddingTop: '4%',
    paddingBottom: '3%',
    padding: '9%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      padding: '80px 142px 50px 0px'
    }
  },

  footerSubMenu: {
    paddingTop: '8px',
    paddingBottom: 40,
    // font: 'Bold 14px/17px HelveticaForTarget',
    fontWeight: 'bold',
    // marginLeft: '-144px',
    // width: '100vw',
    [theme.breakpoints.down('sm')]: {
      padding: '26px 0px'
    }
  },
  footerGridContainer: {
    textAlign: 'left'
  },
  footerLink: {
    display: 'block',
    textAlign: 'left',
    marginBottom: '18px',
    color: '#fff',
    textDecoration: 'none',
    // font: 'Bold 18px/22px HelveticaForTarget',
	fontWeight: 'bold',
	[theme.breakpoints.down('sm')]: {
		padding: '0px 0px 0px 100px',
		textAlign: 'center'
	  }
  },
  footerLogo: {
    height: '78px'
  },
  tagline: {
    color: '#FFFFFF',
    textAlign: 'center',
    fontSize: '0.8rem'
  },
  socialIconContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 28px'
  },
  followusbox: {
    marginTop: '20px',
    [theme.breakpoints.down('lg')]: {
    //   marginTop: '60px',
      textAlign: 'center'
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '60px',
      textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '60px',
      textAlign: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '0px',
      textAlign: 'center'
    }
  },
  footerLinkFollow: {
    display: 'block',
    marginBottom: '18px',
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'center'
  }
}))

export default function FooterSection(props) {
  const classes = useStyles()
  const { footerMenu, footerSubMenu, companyAndSocialMenu } = props.menu
  return (
    <div className={classes.footerContainer} id='footer'>
      <div>
        <Grid
          container
          spacing={3}
          className='footerGridContainer'
          justify='center'
          alignItems={'center'}
        >
          {/* Logo div */}
          <Grid
            item
            xs={12}
            lg={2}
            role='button'
            aria-pressed='false'
            tabIndex='0'
            href='/'
          >
            <img
              src={targetLogo}
              className={classes.footerLogo}
              alt='Target logo'
            />
            {/* <Typography className={classes.tagline}>
              work somewhere you love
            </Typography> */}
          </Grid>
          {/* second div */}
          {footerMenu && (
            <Grid item xm={12} lg={6}>
              <Grid container>
              <Fragment>
                {footerMenu.items && footerMenu.items.map((menu, key) => {
                  return (
                    <Grid item xm={12} lg={4} key={`${key}-footermenu`}>
                      {menu.target !== '' ? (
                        <Link className={classes.footerLink} href={menu.slug} target={menu.target}>
                          {menu.title}
                        </Link>
                      ) : (
                          <RLink className={classes.footerLink} to={menu.slug}>
                            {menu.title}
                          </RLink>
                        )}
                    </Grid>
                  )
                })}
              </Fragment>
              </Grid>
            </Grid>
          )}
          {/* Follow us div */}
          {companyAndSocialMenu && (
            <Fragment>
              <Grid item xm={12} lg={2} className={classes.followusbox}>
                <Typography className={classes.footerLinkFollow}>
                  {companyAndSocialMenu.social.heading}
                </Typography>
                <hr style={{ backgroundColor: '#fff', height: 1, marginBottom: '16px', width: '148px' }} />
                <div className={classes.socialIconContainer}>
                  <Link href={companyAndSocialMenu.social.links.twitter.url} target={'_blank'}>
                    <img
                      className='social-icons'
                      src={twitter}
                      alt='Follow us on Twitter'
                    />
                  </Link>
                  <Link href={companyAndSocialMenu.social.links.linkedin.url} target={'_blank'}>
                    <img
                      className='social-icons'
                      src={linkedin}
                      alt='Follow us on LinkedIn'
                    />
                  </Link>
                  <Link href={companyAndSocialMenu.social.links.instagram.url} target={'_blank'}>
                    <img
                      className='social-icons'
                      src={instagram}
                      alt='Follow us on Instagram'
                    />
                  </Link>
                </div>
              </Grid>
            </Fragment>
          )}
        </Grid>
        {/* Privacy policy div */}
        {footerSubMenu && (
          <div className={classes.footerSubMenu}>
            {footerSubMenu && footerSubMenu.items && footerSubMenu.items.map((subMenu, index) => {
              return (
                <Fragment key={index}>
                  {index !== 0 && <span>&nbsp;|&nbsp;</span>}
                  <Link href={subMenu.slug}>{subMenu.title}</Link>
                </Fragment>
              )
            })}
          </div>
        )}
      </div>
      {/* Copy rights div */}
      {companyAndSocialMenu && (
        <Typography className={classes.tagline} variant='caption'>
          {companyAndSocialMenu.company_info}
        </Typography>
      )}
    </div>
  )
}
