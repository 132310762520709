import React, {useEffect} from 'react'
import {
  makeStyles,
  Typography,
} from '@material-ui/core'
import { Link as RLink } from 'react-router-dom'
import { motion, useAnimation } from 'framer-motion'
import { BannerZoom } from '../../Utils/AnimationEffects'
import { useInView } from "react-intersection-observer"


const useStyles = makeStyles((theme) => ({
  bannerBox: {
    height: '631px',
    backgroundColor: '#F7F7F7',
    display: 'flex',
    position: 'relative',
    [theme.breakpoints.down('xs')]:{
      minHeight: 350,
      backgroundColor: '#fff',
      height: '600px !important',
    }
  },
  headingText: {
    color: '#454545',
    fontWeight: 'bold',
    fontSize: '1.7rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
      textAlign: 'center'
    }
  },
  desc: {
    color: '#454545',
    fontSize: '1rem',
    lineHeight: '2rem',
    paddingRight: theme.spacing(2),
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      // marginTop: 300,
      marginBottom: 10,
      textAlign: 'center',
      fontSize: '0.85rem'
    }
  },
  linkButton: {
    border: '1px solid #CC0000',
    borderRadius: 20,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    color: '#CC0000',
    textDecoration: 'none',
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  textContainer: {
    margin: 'auto',
    position: 'absolute',
    right: 0,
    width: '55%',
    [theme.breakpoints.up('md')]: {
      top: '15%',
      paddingRight: '10%',
      background: 'transparent',
    },
    [theme.breakpoints.down('md')]: {
      bottom: 0,
      left: 0,
      background: 'rgb(249 249 249)',
      padding: 32,
      width: '100%',
      top: 370
      
    },
    [theme.breakpoints.down('xs')]: {
      // top: theme.spacing(3),
      textAlign: 'center',
      background: 'transparent'
    }
  },
  personName: {
    textAlign: 'left',
    lineHeight: '1rem',
    letterSpacing: '0.72px',
    color: '#CC0000',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    }
  },
  personRole: {
    textAlign: 'left',
    lineHeight: '2rem',
    letterSpacing: '0.72px',
    color: '#707070',
    opacity: '1',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    }
  },
  author: {
    color: '#CC0000'
  }
}))

export default function GreyImageTextBox(props) {
  const { values, desktop } = props
  const { items, styling } = values
  const greyImageTextBox = items[0]
  const {
    heading,
    desc,
    slug,
    linkText,
    backgroundImage,
    personName,
    personRole,
    author
  } = greyImageTextBox

  const controls = useAnimation();
  const [ref, inView] = useInView({triggerOnce: false});

  const deafultBoxStyle = {}

  const boxStyle = styling && styling.box ? Object.assign({}, deafultBoxStyle, styling.box) : deafultBoxStyle

  useEffect(() => {
      if (inView) {
          setTimeout(() => {
              controls.start("visible");
          }, 300)
        
      }
  }, [controls, inView])

  const classes = useStyles()

  return (
    <div
      className={classes.bannerBox}
      ref={ref}
      style={{
        backgroundImage: `url(${desktop ? backgroundImage[0] : backgroundImage[1]})`,
        backgroundSize: desktop ?  'cover' : '100%',
        backgroundRepeat: 'no-repeat',
        minHeight: 350,
        ...boxStyle
      }}
    >
      {desktop && (
        <motion.div                                     
            animate={controls}
            initial="hidden"
            {...BannerZoom.desktop}
            className={classes.textContainer}
        >
          <Typography className={classes.headingText}>{heading}</Typography>
          {desc && <Typography className={classes.desc}>{desc}</Typography> }
          {personName && <Typography className={classes.personName}>{personName}</Typography>}
          {personRole && <Typography className={classes.personRole}>{personRole}</Typography>}
          {author && <Typography className={classes.author}>{author}</Typography>}
          <br />
          {slug && 
            <RLink className={classes.linkButton} to={slug}>
              {linkText}
            </RLink>
          }
        </motion.div>
      )}
      {!desktop && (
        <motion.div                                     
            animate={controls}
            initial="hidden"
            {...BannerZoom.mobile}
            className={classes.textContainer}
        >
          <Typography className={classes.headingText}>{heading}</Typography>
          <Typography className={classes.desc}>{desc}</Typography>
          <Typography className={classes.personName}>{personName}</Typography>
          <Typography className={classes.personRole}>{personRole}</Typography>
          <br />
          {slug && 
            <RLink className={classes.linkButton} to={slug}>
              {linkText}
            </RLink>
          }
        </motion.div>
      )}
    </div>
  )
}
