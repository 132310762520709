import React from 'react'
import { makeStyles, Card, CardMedia, Typography } from '@material-ui/core'
import idx from 'idx'
import { motion } from 'framer-motion'
import { SmoothLeftToRight, SmallZoomEntry } from '../../Utils/AnimationEffects'

const useStyles = makeStyles((theme) => ({
    heroBaseStyle: {
        width: '100%',
        height: 500,
        position: 'relative',
        borderRadius: 0,
        overflow: 'visible',
        marginBottom: 110,
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
            marginBottom: 0,
        }
	},
	overlayBox: {
        position: 'absolute',
        bottom: -110,
        minWidth: '90%',
        backgroundColor: 'rgba(256,256,256,0.9)',
        left: '5%',
        right: '5%',
        padding: theme.spacing(6),
        height: 230,
        border: '1px solid #ccc',
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2),
        },
        [theme.breakpoints.down('xs')]: {
            position: 'relative',
            bottom: 0,
            left: 0,
            right: 0,
            paddingTop: theme.spacing(5),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            border: 0,
            height: 'auto',
        }
	},
    cardMedia: {
        height: '100%',
        [theme.breakpoints.down('xs')]: {
            height: 424,
            position: 'relative'
        }
    },
    overlay: {
        position: 'absolute',
        color: 'white',
        [theme.breakpoints.up('sm')]: {
            top: '25%',
            left: '5%',
        },
        [theme.breakpoints.down('xs')]: {
            bottom: 0,
            padding: 12,
            width: '100%',
            backdropFilter: 'blur(20px)',
            background: 'rgba(0,0,0,0.1)',
            position: 'absolute'
        }
    },
    heroText: {
        fontWeight: 'bold',
        fontSize: '2rem',
        lineHeight: '4rem',
        width:'100%',
        color: '#fff',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.4rem',
            lineHeight: '3rem',
            width:'100%',
        }
    },
    bigText: {
        fontSize: '4.85rem',
        lineHeight: '6rem',
        [theme.breakpoints.down('xs')]: {
            fontSize: 50,
            lineHeight: '2rem',
        }
    },
	overlayBoxHeader: {
        color: '#454545',
        marginBottom: 15,
        fontSize: '1.4rem',
        fontWeight: 'bold',
        lineHeight: '1.5rem'
	},
	overlayBoxDes: {
        width:'100%',
        fontSize: '1rem',
        color: '#707070'
    }
}));



const HeroWithText = props => {
    const { values, desktop } = props
    const classes = useStyles()
    const {style} = values

    const getValue = (key, index = 0, defaultVal) => {
        const value = idx(values, (_) => _[key][index])
        return value ? value : defaultVal ? defaultVal : ''
    }

    const heroTextstyling = Object.assign({}, desktop && style && style.hero ? style.hero : {})

    return (
        <Card className={classes.heroBaseStyle} elevation={0}>
            {getValue('backgroundImage') &&
                <CardMedia className={classes.cardMedia} image={`${encodeURI(getValue('backgroundImage', desktop ? 0 : 1))}`}>
                    {getValue('primaryHeading') && (
                        <div className={classes.overlay}>
                            <motion.div {...SmoothLeftToRight}>
                                <Typography className={classes.heroText} variant={"h3"} style={heroTextstyling}>
                                    {getValue('primaryHeading')}
                                    {getValue('secondaryHeading') && (
                                        <>
                                            <br /> <span className={classes.bigText}>{getValue('secondaryHeading')}</span>
                                        </>
                                    )}
                                </Typography>
                            </motion.div>
                        </div>
                    )}
                </CardMedia>
            }
            <motion.div {...SmallZoomEntry}>
                <div className={classes.overlayBox}>
                    <Typography className={classes.overlayBoxHeader}>{values.textBoxOverlay.heading }</Typography>
                    <Typography className={classes.overlayBoxDes}>{values.textBoxOverlay.desc }</Typography>
                </div>
            </motion.div>
        </Card>
		
    )
}

export default HeroWithText