import React, { useEffect, useState } from 'react'
import { makeStyles, Typography, Grid, Button } from '@material-ui/core'
import { useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import {getValueByAttribute} from '../../Utils/DataExtract'
import idx from 'idx'
import {Link as RLink} from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  linktoArchive:{
    textDecoration: 'none',
    color: '#555',

    '&:hover':{
      textDecoration: 'none',
      color: '#555 !important'
    },
  },
  moreStories:{
    marginTop: '10px',
  },
  tmImg:{
    // backgroundSize: 'cover',
    minHeight: 250,
    [theme.breakpoints.up('lg')]: {
      backgroundSize: 'contain',
    },
    [theme.breakpoints.down('lg')]: {
      backgroundSize: 'cover',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundSize: '52%',
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: 350,
      backgroundSize: '100%',
    },
  },
  border:{
    borderBottom: '1px solid #707070', 
    [theme.breakpoints.down('sm')]: {
      borderBottom: 'none', 
  }
  },
  ditloWrapLeft:{
    display: 'flex',
    flexDirection: 'inherit',
    backgroundColor: '#f7f7f7',
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'inherit',
    }
  },
  headingStyle: {
    paddingLeft: theme.spacing(1),
    color: '#333333',
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
        fontSize: '1.25rem',
        paddingLeft: 0,
    }
},
  viewAll:{
      textDecoration: 'none',
      backgroundColor: '#CC0000',
      borderRadius: '50%',
      height: '130px',
      width: '130px',
      color:'#fff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '27px',
      marginBottom: '0px',

      '&:hover':{
        color: '#fff !important',
      },
      [theme.breakpoints.down('sm')]: {
        margin: '50px auto',
        borderRadius: 0,
        minWidth: '300px',
        height: '50px',
    }
  },
  description:{
    marginTop:'13px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '29px',
      lineHeight: '0.8rem',
      textAlign: 'left',
    }  },
  circle: {
    borderRadius: '50%',
    boxSizing: 'border-box',
    margin: '20px',
    marginBottom: '0px',
    cursor: 'pointer',
    // border: '10px solid #fff',
    '&:before': {
      content: " ",
      position: 'absolute',
      zIndex: '1',
      top: '25px',
      left: '25px',
      right: '25px',
      bottom: '25px',
      // border: '20px solid #ffea00',
    },
    '&:hover': {
      // border: '10px solid #cc0000',
    },
  },
  imgGrid: {
    display: 'flex',
    paddingBottom: '40px',
    [theme.breakpoints.down('sm')]: {
        paddingBottom: '0px',
        flexDirection: 'column',
    },

  },
  storyCircle: {
    fontSize:'15px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'initial',
        alignItems: 'center',
        // justifyContent: 'center',
    }
  },
  desc: {
    color: '#454545',
    fontSize: '1rem',
    paddingRight: theme.spacing(2),
    marginBottom: 10,
    marginTop: 15,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      marginBottom: 10,
      textAlign: 'left',
      lineHeight: '22px',
      fontSize: '1rem',
    }
  },
  teamMemberName: {
    textAlign: 'left',
    fontSize: '18px',
    lineHeight: '1rem',
    color: '#CC0000',
    fontWeight: 'Bold',
  },
  secondaryTeamMemberName:{
    fontWeight: 'bolder',
  },
  media: {
    height: 0,
    paddingTop: '70%',
    backgroundSize: '58%',
    backgroundPositionX: '60%',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '72.25%',
      backgroundSize: '60%',
      backgroundPositionX: '50%',
    }
  },
  textBlock: {
    padding: '30px',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0px'
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '30px'
    }
  },
  container: {
    padding: theme.spacing(5),
    height: '100%'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingRight: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(0),
    }
  },
 
  storyLink: {
    width: 'max-content',
    borderRadius: 35,
    color: "#CC0000",
    borderColor: '#CC0000',
    textTransform: 'none',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  },
  readMoreCont: {
	  paddingTop: '1.2rem',
	[theme.breakpoints.down('sm')]: {
	  paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
	}
  }
}))

export default function SolveforTarget(props) {
	const {component, desktop } = props
	const values = idx(component, (_) => _.attributes.values)
  const controls = useAnimation()
  const [inView] = useInView({ triggerOnce: false })
  const [readMoreFlag, setReadMoreFlag] = useState(false)


  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        controls.start('visible')
      }, 300)
    }
  }, [controls, inView])

  const classes = useStyles()

const toggleReadMore = () => {
	setReadMoreFlag(!readMoreFlag)
}
  return (<div style={{marginTop: desktop ? 50 : 0, marginBottom: '30px'}} id={getValueByAttribute(values, 'heading') ? (getValueByAttribute(values, 'heading').split(" ")[0].toLowerCase()) : "ditlo"}>
      <Grid container className={classes.ditloWrapLeft}>  
      {getValueByAttribute(values, 'desktop_image') &&
            <Grid item xs={12} md={5}>
              <div
        style={{
          backgroundImage: `url(${desktop ? getValueByAttribute(values, 'desktop_image') : getValueByAttribute(values, 'mobile_img') 
            })`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: '#f7f7f7',
          marginBottom:  0,
          cursor: 'auto',
          backgroundPosition: !desktop ? 'right top' : '',  
        }}
        className={classes.tmImg}
      ></div>
            </Grid>
          }
           <Grid item xs={12} md={7} className={classes.textBlock}>
           {getValueByAttribute(values, 'heading') && (
            <Typography className={classes.teamMemberName}>
                {getValueByAttribute(values, 'heading')}
            </Typography>
            )}
            {getValueByAttribute(values, 'description') && (
                <Typography className={classes.desc}>
                {getValueByAttribute(values, 'description')}
                </Typography>
            )}
            <br />
            {getValueByAttribute(values, 'button_text') && (
                <Button variant="outlined" className={classes.storyLink} disableElevation onClick={toggleReadMore}>
                  <RLink style={{textDecoration: 'none'}} color="textSecondary" to={getValueByAttribute(values, 'url')}> 
                  {getValueByAttribute(values, 'button_text')}
                {!readMoreFlag ? (<ArrowRightIcon />) : (<ArrowRightIcon />)}
											</RLink>
                </Button>
            )}
            </Grid>
      </Grid>  
      
  </div>) 
}
