import React, { useState } from 'react'
import { makeStyles, Grid,IconButton, Typography } from '@material-ui/core'
import TwitterIcon from '@material-ui/icons/Twitter'
import LinkIcon from '@material-ui/icons/Link'
import GitHubIcon from '@material-ui/icons/GitHub'
import idx from 'idx'

const useStyles = makeStyles((theme) => ({
    shareWrap:{
        backgroundColor: '#f5f5f5',
        padding: '20px 0px 10px 20px',
        margin: '20px 0',
        display: 'flex',
        width: '18%',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            width: '100%',
          },
          [theme.breakpoints.down('sm')]: {
            padding: '20px 0px 10px 0px',
          },
          [theme.breakpoints.down('xs')]: {
            alignItems: 'center',
          }
    },
    shareArea: {
        position: 'relative',
        right: 0,
        display: 'flex',
        flexDirection: 'row',
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
          top: 0,
          position: 'relative',
          display: 'flex',
          flexDirection: 'row'
        }
      },
      shareIcons: {
        color: '#cc0000',
        fontSize: '2rem'
      },
      copyMsg:{
        position: 'absolute',
        top: '41px',
        fontSize: '13px',
        fontWeight: 'bold',
        color: '#cc0000',
        left:'121px',
      },
}))



export default function ShareComponent(props) {

  const [copySuccess, setCopySuccess] = useState('');
  const [visible, setVisible] = useState(true)

  const classes = useStyles()
	const { post, component } = props
	const values = idx(component, (_) => _.attributes.values)
  const url = values.url

  function copyToClipboard(e) {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopySuccess('copied');
    setVisible(true);
    setTimeout(() => {
      setVisible(false);
    }, 2000);
  }

  const shareThePost = (action) => {
    switch (action) {
      case 'twitter':
        const twitter = `https://twitter.com/intent/tweet?text=exercise&url=${window.location.href}&related=`
        window.open(twitter)
        break;
      case 'linkedIn':
        const linkedIn = `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=${post.title}&ro=false&summary=&source=`
        window.open(linkedIn)
        break;
        case 'github':
        const github = `${url}`
        window.open(github)
        break;
      case 'copyURL':
        // copy(window.location.href)
        break;
      default:
        break;
    }
  }

  return (
      <Grid container className={classes.shareWrap}>  
      <Typography variant={'h3'} style={{display:'block', fontSize:'1.6rem'}}>Invite Friends Via</Typography>
      <div className={classes.shareArea}>
              
                <IconButton aria-label="Share this post on Twitter" className={classes.shareButtons} onClick={() => shareThePost('twitter')}>
                  <TwitterIcon className={classes.shareIcons} />
                </IconButton>

                <IconButton aria-label="Share this post on GitHub" className={classes.shareButtons} onClick={() => shareThePost('github')}>
                  <GitHubIcon className={classes.shareIcons} />
                </IconButton>

                <IconButton style={{position:'relative'}} aria-label="Copy this post URL" onClick={copyToClipboard} className={classes.shareButtons} >
                  <LinkIcon className={classes.shareIcons} />
                </IconButton>
                {visible ? <div className={classes.copyMsg}>{copySuccess}</div> : ''}
              </div>
       </Grid>  
      ) 
}

