import React from 'react'
import { makeStyles } from '@material-ui/core'
import {getValueByAttribute} from '../../Utils/DataExtract'
import idx from 'idx'
import { Link } from 'react-scroll'
import RichTextBox from '../RichTextBox/RichTextBox'

const useStyles = makeStyles((theme) => ({
    linkContainer: {
        paddingTop: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        }
      },
    topLinks: {
        cursor: 'pointer',
        marginRight: theme.spacing(4),
        textDecoration: 'underline',
        [theme.breakpoints.down('xs')]: {
          marginRight: theme.spacing(2),
        }
    }
}))

const FAQLinks = (props) => {
    const {component} = props
	const values = idx(component, (_) => _.attributes.values)
    const classes = useStyles()
    const faqs = getValueByAttribute(values, 'faqs', true)
    
    return (
        <>
            <div className={classes.linkContainer}>
            {faqs && faqs.length > 0 && faqs.map((faq, index) => {
                const id = getValueByAttribute(faq, 'heading').replace(' ', '-').toLowerCase()
                return (
                <Link
                key={index}
                activeClass='active'
                to={id}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className={classes.topLinks}
              >
                  {getValueByAttribute(faq, 'heading')}
                </Link>
                )
            })}
            </div>
            {faqs && faqs.length > 0 && faqs.map((faq, index) => {
                return (
                    <div key={index + '_cont'} id={getValueByAttribute(faq, 'heading').replace(' ', '-').toLowerCase()}>
                        <RichTextBox values={{heading: getValueByAttribute(faq, 'heading'), desc: getValueByAttribute(faq, 'content')}} />
                    </div>
                )
            })}
        </>
    )
}

export default FAQLinks
