import React, {useEffect, useState} from 'react'
import {
  makeStyles,
  Typography,
  Grid,
  Button,
  Box,
  Card,
  CardMedia,
  CardContent,
} from '@material-ui/core'
// import { AnimatedOnScroll } from 'react-animated-css-onscroll'
import { motion, useAnimation } from 'framer-motion'
import { SmallZoomEntry } from '../../Utils/AnimationEffects'
import { useInView } from "react-intersection-observer"
import renderHTML from 'react-render-html'
import Video from '../Video/Video'
import idx from 'idx'
import {getValueByAttribute} from '../../Utils/DataExtract'

const useStyles = makeStyles((theme) => ({
    storyBoxBase: {
        width: '100%',
        position: 'relative',
        borderRadius: 0,
        paddingBottom: theme.spacing(2),
    },
    storiesContainer: {
        marginLeft: '10%',
        marginRight: '10%'
    },
    storyCard: {
        background: 'transparent'
    },
    headerSection: {
        marginTop: 60,
        [theme.breakpoints.down('md')]: {
            marginTop: 35,
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: 20,
        }
    },
    headerText: {
        textAlign: 'center',
    },
    subText: {
		textAlign: 'center',
		paddingBottom: '20px',
        [theme.breakpoints.down('md')]: {
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4)
        }
    },
    storyDetails: {
        textAlign: 'center'
    },
    storyLine: {
        textAlign: 'center',
        // fontWeight: '300',
        // height: 90,
        fontSize: '1.2rem',
        marginBottom: 30,
        [theme.breakpoints.down('md')]: {
            // fontSize: 15,
            // height: 100,
        },
        [theme.breakpoints.down('sm')]: {
            // fontSize: 12,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            // height: 50
        }
    },
    tmName: {
        color: '#CC0000',
        fontWeight: 'bold',
        fontSize: '1rem',
        [theme.breakpoints.down('md')]: {
            // fontSize: 15,
        },
        [theme.breakpoints.down('sm')]: {
            // fontSize: 12,
            marginTop: 20
        }
    },
    tmPosition: {
        color: '#333333',
        fontSize: '1rem',
        [theme.breakpoints.down('md')]: {
            // fontSize: 15,
        },
        [theme.breakpoints.down('sm')]: {
            // fontSize: 12,
        }
    },
    storyLink: {
        marginTop: 30,
        borderRadius: 35,
        color: "#CC0000",
        borderColor: '#CC0000',
        // fontSize: 16,
        textTransform: 'none',
        [theme.breakpoints.down('md')]: {
            fontSize: 14,
            marginTop: 28,
        },
        [theme.breakpoints.down('sm')]: {
            // fontSize: 12,
            marginTop: 26
        }
    },
    storyBox: {
        padding: theme.spacing(4),
        paddingTop: 0,
        [theme.breakpoints.down('md')]: {
            padding: 0,
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3)
        },
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            paddingBottom: theme.spacing(3),
        },
        [theme.breakpoints.down('xs')]: {
            padding: 0,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            paddingBottom: theme.spacing(3),
        }
    },
    media: {
        padding: theme.spacing(6),
        [theme.breakpoints.down('md')]: {
          padding: theme.spacing(1),
          paddingTop: 0,
          paddingBottom: 0,
        },
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(2),
          paddingTop: 0,
          paddingBottom: 0,
        },
        paddingTop: 0,
        paddingBottom: 0,
    },
    moreStories:{
        display:'flex',
        flexDirection:'column-reverse',
        height: '10px',
        position:' absolute',
        right: '81px',
        bottom:'76px',
    },
    rightArrow:{
        borderRadius: '50px',
        border: '1px solid #444',
        color: '#cc0000',
        background: 'white',
        marginLeft: '5px',
    }
}));



const ArchivedStory = props => {
    const { component, desktop } = props
    const values = idx(component, (_) => _.attributes.values)
    const { archived_stories, spacing } = values
    const classes = useStyles()
    const [videoModelOpen, setVideoModelOpen] = useState(false)
    const [currentVideo, setCurrentVideo] = useState(null)
    const background = desktop ? `url(${encodeURI(getValueByAttribute(values, 'desktop_background_image'))})`: `url(${encodeURI(getValueByAttribute(values, 'mobile_background_image'))})`
    const backgroundStyle = {
        backgroundImage: background,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    }
    const controls = useAnimation();
    const [ref, inView] = useInView({triggerOnce: false});

    useEffect(() => {
        if (inView) {
            setTimeout(() => {
                controls.start("visible");
            }, 300)
          
        }
    }, [controls, inView])

    const openVideoModel = (story) => {
        let videoData = {
            title: getValueByAttribute(story, 'video_title'),
            poster: getValueByAttribute(story, 'video_poster_image'),
            source: getValueByAttribute(story, 'video_source', true),
            tracks: getValueByAttribute(story, 'video_tracks', true),
            transcript: getValueByAttribute(story, 'video_transcript', true),
        }
        setCurrentVideo(videoData)
        setVideoModelOpen(true)
    }

    const closeVideoModel = () => {
        setVideoModelOpen(false)
        setCurrentVideo(null)
    }

    
    return (
        <>
        <div
            style={backgroundStyle}
            className={classes.storyBoxBase}
        >
            <Grid
                container
                spacing={0}
                direction="row"
            >
                <Grid item xs={12} className={classes.storiesContainer}>
                    <Grid container ref={ref} spacing={desktop ? (spacing ? spacing : 0) : 0} className={classes.storyWrapper}>
                        {archived_stories && archived_stories.length > 0 && archived_stories.map((story, storyKey) => {
                            
                            return (
                                <Grid item md={getValueByAttribute(story, 'width') ? parseInt(getValueByAttribute(story, 'width')) : 4} sm={8} xs={11} key={storyKey} className={classes.storyBox}>
                                    {/* <AnimatedOnScroll animationIn={'fadeIn'}> */}
                                    <motion.div                                     
                                        animate={controls}
                                        initial="hidden"
                                        variants={{
                                            visible: {...SmallZoomEntry.animate,  opacity: 1 },
                                            hidden: {...SmallZoomEntry.initial, scale: 0.8, opacity: 0 }
                                        }}
                                        transition={{
                                            duration: 1
                                        }}
                                    >
                                    <Card className={classes.storyCard} elevation={0}>
                                        <CardMedia
                                            component="img"
                                            className={classes.media}
                                            image={`${getValueByAttribute(story, 'story_image')}`}
                                            title="Team Member Story"
                                            alt={getValueByAttribute(story, 'team_member_name')}
                                        />
                                        <CardContent>
                                            <Box display="flex" justifyContent="center">
                                                <div className={classes.storyDetails}>
                                                    <Typography className={classes.storyLine}>{renderHTML(getValueByAttribute(story, 'story_line'))}</Typography>
                                                    <Typography className={classes.tmName}>{getValueByAttribute(story, 'team_member_name')}</Typography>
                                                    <Typography className={classes.tmPosition}>{getValueByAttribute(story, 'team_member_designation')}</Typography>
                                                    <Button variant="outlined" className={classes.storyLink} disableElevation onClick={() => openVideoModel(story)}>
                                                        {getValueByAttribute(story, 'button_text') ? getValueByAttribute(story, 'button_text') : `${getValueByAttribute(story, 'team_member_name')}'s story`}
                                                    </Button>
                                                </div>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                    </motion.div>
                                    
                                </Grid>
                            )
                          
                        })}
                        
                    </Grid>
                   
                </Grid>
                
            </Grid>
        </div>
        <Video open={videoModelOpen} video={currentVideo} close={closeVideoModel}/>
        </>
    )
}

export default ArchivedStory
