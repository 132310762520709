import React from 'react'
import { Filmstrip } from 'nicollet-react'
import styled from 'styled-components'
import {
  makeStyles,
  Typography,
  Button,
  Link,
  Card,
  CardMedia,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  storyBoxBase: {
    width: '100%',
    minHeight: 900,
    position: 'relative',
    borderRadius: 0,
    background: '#efefef',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      minHeight: 810,
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 700,
    },
  },
  storyCard: {
    background: 'transparent',
  },
  headerSection: {
    marginTop: 80,
    [theme.breakpoints.down('md')]: {
      marginTop: 35,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  headerText: {
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      // font: 'Bold 24px/50px HelveticaForTarget',
    },
    [theme.breakpoints.down('xs')]: {
      // font: 'Bold 20px/50px HelveticaForTarget',
    },
  },
  subText: {
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      // font: '19px/50px HelveticaForTarget',
    },
    [theme.breakpoints.down('xs')]: {
      // font: '14px/18px HelveticaForTarget',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  carouselHeading: {
    fontWeight: 'medium',
    fontSize: 28,
    textAlign: 'left',
    marginBottom: '30px',
  },
  carouselPara: {
    textAlign: 'left',
    fontWeight: 'regular',
    fontSize: 16,
  },
  storyLink: {
    marginTop: 30,
    borderRadius: 35,
    color: '#FFFFFF',
    borderColor: '#FFFFFF',
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  storyDetails: {
    textAlign: 'center',
  },
  storyLine: {
    textAlign: 'center',
    fontWeight: 'lighter',
    fontSize: 22,
    height: 130,
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
      height: 100,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      height: 50,
    },
  },
  tmName: {
    color: '#CC0000',
    fontWeight: 'bold',
    fontSize: 22,
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      marginTop: 20,
    },
  },
  tmPosition: {
    color: '#333333',
    fontSize: 22,
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  // storyLink: {
  //   marginTop: 30,
  //   borderRadius: 35,
  //   color: '#CC0000',
  //   borderColor: '#CC0000',
  //   fontSize: 16,
  //   [theme.breakpoints.down('md')]: {
  //     fontSize: 14,
  //     marginTop: 28,
  //   },
  //   [theme.breakpoints.down('xs')]: {
  //     fontSize: 12,
  //     marginTop: 26,
  //   },
  // },
  storyBox: {
    padding: theme.spacing(6),
    paddingTop: 0,
    [theme.breakpoints.down('md')]: {
      padding: 0,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  media: {
    padding: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
      paddingTop: 0,
      paddingBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      paddingTop: 0,
      paddingBottom: 0,
    },
    paddingTop: 0,
    paddingBottom: 0,
  },
}))

const RegularItem = styled.div`
  ${'' /* width: 90vw;
  height: 504px;
  margin: 40px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1); */}
`

const style = {
  '--filmstrip-button-width': '40px',
  '--filmstrip-button-backgroundcolor': 'red',
}

export default function TopStories(props) {
  const { values } = props
  const { carousel } = values
  const classes = useStyles()

  const FilmstripWithCustomButtonWidth = () => (
    <Filmstrip hasPaginationDots Nope style={style} carousel={carousel}>
      {carousel.map((item) => (
        <RegularItem key={item.primaryHeading} className='carousel-box'>
          <div className='filmstrip-card'>
            <div className='card-content'>
              <Typography className={classes.carouselHeading} variant={'h3'}>
                {item.primaryHeading && item.primaryHeading[0]
                  ? item.primaryHeading[0]
                  : ''}
              </Typography>
              <Typography className={classes.carouselPara}>
                {item.secondaryHeading && item.secondaryHeading[0]
                  ? item.secondaryHeading[0]
                  : ''}
              </Typography>
              <Link
                target='_blank'
                href={item.link && item.link[0] ? item.link[0] : ''}
              >
                <Button
                  variant='outlined'
                  className={classes.storyLink}
                  disableElevation
                >
                  Learn More
                </Button>
              </Link>
            </div>

            <Card className='topstories-img'>
              <CardMedia
                component='img'
                style={{
                  minHeight: '100%',
                  minWidth: '100%',
                }}
                alt={
                  item.carouselImageAltText && item.carouselImageAltText[0]
                    ? item.carouselImageAltText[0]
                    : ''
                }
                image={
                  item.carouselImage && item.carouselImage[0]
                    ? item.carouselImage[0]
                    : ''
                }
                // onClick={() => window.open(props.data.link)}
                onClick={() => window.open('https://target.com')}
              />
            </Card>

            {/* <img
                alt={
                  item.carouselImageAltText && item.carouselImageAltText[0]
                    ? item.carouselImageAltText[0]
                    : ''
                }
                src={
                  item.carouselImage && item.carouselImage[0]
                    ? item.carouselImage[0]
                    : ''
                }
              /> */}
          </div>
        </RegularItem>
      ))}
    </Filmstrip>
  )

  return (
    <div className='carousel-container'>
      <div className='department-main-container'>
        <div className='department-heading-container'>
          <Typography className={classes.headerText} variant={'h3'}>
            {values.primaryHeading && values.primaryHeading[0]
              ? values.primaryHeading[0]
              : ''}
          </Typography>

          <Typography className={classes.subText}>
            {values.secondaryHeading && values.secondaryHeading[0]
              ? values.secondaryHeading[0]
              : ''}
          </Typography>
        </div>
      </div>
      <div className='filmstrip-container'>
        <FilmstripWithCustomButtonWidth carousel={carousel} />
      </div>
    </div>
  )
}
