import React, { Fragment } from 'react'
import { makeStyles, Breadcrumbs, Typography } from '@material-ui/core'
import { Link as RLink } from 'react-router-dom'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import idx from 'idx'

const useStyles = makeStyles((theme) => ({
    activeNode: {
        color: '#CC0000',
        fontSize: '1rem',
        maxWidth: 500,
        whiteSpace: 'nowrap',
        overflow: 'hidden !important',
        textOverflow: 'ellipsis',
        [theme.breakpoints.down('md')]: {
            maxWidth: 120,
        }
    },
    breadCrumbArea: {
        paddingBottom: theme.spacing(3),
        '& a': {
            textDecoration: 'none'
        },
        [theme.breakpoints.down('sm')]: {
            paddingBottom: theme.spacing(2),
        }
    },
    links: {
        fontSize: '1rem',
        color: '#707070',
    }
}))

const Breadcrumb = props => {
    const { component, desktop } = props
    const values = idx(component, (_) => _.attributes.values)
    const { navigation_links } = values
    const classes = useStyles()

    return (
        <Fragment>
            {navigation_links && navigation_links.length > 0 && (
                <div>
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadCrumbArea} maxItems={desktop ? 5 : 3}>
                        {navigation_links.map((node, nodeIndex) => {
                            return (
                                nodeIndex === (navigation_links.length-1) ?
                                    <Typography key={nodeIndex} className={classes.activeNode}>{node.name}</Typography>
                                :
                                <RLink key={nodeIndex} to={node.slug} className={classes.links}>{node.slug === '/' ? 'Home' : node.name}</RLink>
                            )
                        })}
                    </Breadcrumbs>
                </div>
            )}
        </Fragment>
    )
}

export default Breadcrumb
