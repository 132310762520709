import React, { Fragment } from 'react'
import { makeStyles, Grid, Card, CardContent, Typography, Link } from '@material-ui/core'
import { Link as RLink, useHistory } from 'react-router-dom'
import { AnimatedOnScroll } from 'react-animated-css-onscroll'
import { motion } from "framer-motion"
import {getValueByAttribute} from '../../Utils/DataExtract'
import idx from 'idx'
import renderHTML from 'react-render-html'

const useStyles = makeStyles((theme) => ({
    gridTile: {
        borderRadius: 0,
        border: '1px solid #eaeaea',
        height: '100%',
        color: '#333333',
        background: '#F7F7F7 0% 0% no-repeat padding-box',
        padding: theme.spacing(4),
        transition: 'color 2s, background 2s, border 9s',
        cursor: 'pointer',
        '&:hover': {
            // border: 0,
            // paddingLeft: theme.spacing(4.1),
            // paddingRight: theme.spacing(4.1),
            // borderBottom: theme.spacing(1) + 'px solid #CC0000',

            // color: '#FFFFFF',
            // background: '#CC0000',
            // '& hr': {
            //     color: '#ffffff !important'
            // },
            // '& a': {
            //     color: '#ffffff !important'
            // },
            // '& p': {
            //     color: '#ffffff !important'
            // },
            // '& h3': {
            //     color: '#ffffff !important'
            // }
        },
        '&:hover:before': {
            content: '""',
            top: 0,
            left: 0,
            width: '100%',
            position: 'absolute',
            borderTop: '8px solid #cc0000',
        },
        '& hr': {
            color: '#CC0000'
        },
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2),
        },
        [theme.breakpoints.down('xs')]: {
            height: 'auto !important'
        }
    },
    animateAreaBox: {
        // border: 0,
        // '&:hover': {
        //     borderBottom: theme.spacing(1) + 'px solid #CC0000',
        // }
    },
    gridTileArea: {
        marginTop: 40,
        marginBottom: 40,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            marginTop: 30,
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: 30,
            marginTop: 30,
        }
    },
    tileHeader: {
        width: '100%',
        fontSize: '1.37rem',
        // fontWeight: 'normal',
        lineHeight: '2rem',
        [theme.breakpoints.down('md')]: {
            // fontSize: 15,
            // lineHeight: '1rem',
        },
    },
    descArea: {
        // height: 170,
        // paddingTop: theme.spacing(2),
        paddingBottom: 30,
        [theme.breakpoints.down('xs')]: {
            // height: 120,
            paddingTop: 0,
        }
    },
    tileDesc: {
        width: '100%',
        marginTop: 20,
        lineHeight: '1.7rem',
        [theme.breakpoints.down('md')]: {
            // fontSize: 13,
            // lineHeight: '1.3rem',
            marginTop: '20px !important',
        },
        '& p': {
            color: '#333',
            fontSize: '1rem'
        }
    },
    tileLink: {
        width: '100%',
        // paddingTop: 55,
        color: '#CC0000',
        textDecoration: 'none',
        textTransform: 'none',
        '&:hover':{
            textDecoration: 'underline',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 13
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: 0,
        }
    },
    hr: {
        width: 90,
        textAlign: 'left',
        marginLeft: 0,
        height: 3,
        border: 0,
        // border: '1.5px solid',
        background: '#cc0000',
        display: 'flex',
        // marginTop: 20,
        [theme.breakpoints.down('xs')]: {
            marginTop: '20px !important',
        }
    },
    topSpace: {
        paddingTop: theme.spacing(5)
    },
    headingStyle: {
        color: '#333333',
        textAlign: 'left',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.25rem',
        }
    },
    onlyHeading: {
        background: '#cc0000',
        color: '#fff',
        padding: theme.spacing(2),
        fontSize: '2rem',
    },
    descStyle: {
        color: '#333333',
        textAlign: 'left',
        letterSpacing: 0,
        fontSize: '1rem',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1rem',
            lineHeight: '30px',
        },
        '& p': {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            color: '#333333'
        }
    },
}))

const GridTile = props => {
    const {component} = props
    const values = idx(component, (_) => _.attributes.values)
    const tiles = getValueByAttribute(values, 'tiles', true)
    const classes = useStyles()
    const history = useHistory()

    const cardClick = (slug, document) => {
        if(slug) {
            history.push(slug)
        }
        if(document) {
            window.open(document)
        }
    }

    const noOfTilePerRow = getValueByAttribute(values, 'no_of_tiles_per_row') ? parseInt(getValueByAttribute(values, 'no_of_tiles_per_row')) : 3
    const desc = getValueByAttribute(values, 'description') ? true : false

    return (
        <Fragment>
            <div className={classes.topSpace}>
                {getValueByAttribute(values, 'heading') && (
                    <Typography  className={desc ? classes.headingStyle : classes.onlyHeading} variant={"h3"}>{getValueByAttribute(values, 'heading')}</Typography>
                )}
                {getValueByAttribute(values, 'description') && <div className={classes.descStyle}>{renderHTML(getValueByAttribute(values, 'description'))}</div>}
            </div>
            {tiles && tiles.length > 0 && (
                <div className={classes.gridTileArea}>
                    <Grid container spacing={2}>
                    {tiles.map((tile, tileIndex) => {
                        const pageLink = getValueByAttribute(tile, 'page_link')
                        const pageSlug = pageLink ? pageLink.slug : ''
                        const document = getValueByAttribute(tile, 'document')
                        return (
                            <Grid key={tileIndex} item xs={12} md={12/noOfTilePerRow} onClick={() => cardClick(pageSlug, document)}>
                                <AnimatedOnScroll animationIn={'fadeInUp'} style={{height: '100%'}} >
                                <motion.div whileHover={{ scale: 0.99 }} style={{height: '100%'}} transition={{duration: 0.1}} className={classes.animateAreaBox}>
                                <Card className={classes.gridTile} elevation={0}>
                                    <CardContent>
                                        <div>
                                        <Typography gutterBottom className={classes.tileHeader} variant={'h3'}>
                                            {getValueByAttribute(tile, 'title')}
                                        </Typography>
                                        </div>
                                        <div>
                                            <hr className={classes.hr}/>
                                        </div>
                                        <div className={classes.descArea}>
                                        {getValueByAttribute(tile, 'description') && <div className={classes.tileDesc}>
                                            {renderHTML(getValueByAttribute(tile, 'description'))}
                                        </div>
                                        }
                                        </div>
                                        {pageSlug && (
                                            <div>
                                                <RLink to={pageSlug} className={classes.tileLink}>{getValueByAttribute(tile, 'page_link_label')}</RLink>
                                            </div>
                                        )}
                                        {document && (
                                            <div>
                                                <Link href={document} target={'_blank'} className={classes.tileLink}>{getValueByAttribute(tile, 'page_link_label')}</Link>
                                            </div>
                                        )}
                                    </CardContent>
                                </Card>
                                </motion.div>
                                </AnimatedOnScroll>
                            </Grid>
                        )
                    })}
                    </Grid>
                </div>
            )}
        </Fragment>
    )
}

export default GridTile
