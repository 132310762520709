import React, { Fragment, useState } from 'react'
import {
  makeStyles,
  Grid,
  Card,
  CardMedia,
  Typography,
  IconButton,
  Button
} from '@material-ui/core'
import { Link as RLink } from 'react-router-dom'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import { AnimatedOnScroll } from 'react-animated-css-onscroll'
import ShowMore from '@tedconf/react-show-more'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import renderHTML from 'react-render-html'
import Video from '../../ComponentsList/Video/Video'
import { getValueByAttribute } from '../../Utils/DataExtract'
import idx from 'idx'

const useStyles = makeStyles((theme) => ({
  gridMedia: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      padding: 0
    }
  },
  heading: {
    fontWeight: 'bold',
    lineHeight: '3rem',
    [theme.breakpoints.down('xs')]: {
      fontWeight: 'bold'
    }
  },
  dataContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  },
  gridTile: {
    background: 'transparent'
  },
  media: {
    height: 300,
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      height: 200,
      backgroundSize: '100%'
    }
  },
  playButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
    height: 50,
    width: 50,
    background: '#fff',
    opacity: 0.9,
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#fff',
      opacity: 1
    }
  },
  playButtonIcon: {
    opacity: '0.8'
  },
  title: {
    color: '#333333',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    fontWeight: 'bold',
    fontSize: '1.3rem'
  },
  tileDesc: {
    fontSize: '1rem',
    lineHeight: '1.8rem',
    // height: 130,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
      lineHeight: '1.4rem',
      height: 'auto',
      marginBottom: theme.spacing(2)
    }
  },
  readMore: {
    color: '#CC0000',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  loadMore: {
    marginTop: 30,
    borderRadius: 35,
    color: '#CC0000',
    borderColor: '#CC0000',
    fontSize: 16
  },
  loadMoreHidden: {
    display: 'none'
  },
  subHeading: {
    marginTop: 20
  }
}))

const VideoGrid = (props) => {
  const {
    classes,
    poster,
    // content,
    // title,
    openVideoModel,
    video,
    slug,
    linkText
  } = props
  return (
    <Card className={classes.gridTile} elevation={0}>
      <CardMedia className={classes.media} image={poster}>
        <IconButton
          aria-label='play'
          className={classes.playButton}
          onClick={() => openVideoModel(video)}
        >
          <PlayArrowIcon className={classes.playButtonIcon} />
        </IconButton>
      </CardMedia>
      <div className={classes.descArea}>
        <Typography className={classes.title}>
          {getValueByAttribute(props, 'heading')}
        </Typography>
        <div className={classes.tileDesc}>
          {renderHTML(getValueByAttribute(props, 'sub_heading'))}
        </div>
        {slug && (
          <RLink
            to={getValueByAttribute(props, 'slug')}
            className={classes.readMore}
          >
            {linkText ? linkText : 'READ MORE'}
          </RLink>
        )}
      </div>
    </Card>
  )
}

const ImageGrid = (props) => {
  const { classes, slug, linkText } = props
  return (
    <Card className={classes.gridTile} elevation={0}>
      <CardMedia
        className={classes.media}
        image={getValueByAttribute(props, 'image')}
        alt={getValueByAttribute(props, 'heading')}
        component={'img'}
      ></CardMedia>
      <div className={classes.descArea}>
        <Typography className={classes.title}>
          {getValueByAttribute(props, 'heading')}
        </Typography>
        <div className={classes.tileDesc}>
          {renderHTML(getValueByAttribute(props, 'sub_heading'))}
        </div>
        {slug && (
          <RLink to={slug} className={classes.readMore}>
            {linkText ? linkText : 'READ MORE'}
          </RLink>
        )}
      </div>
    </Card>
  )
}

const loadType = (props, classes, openVideoModel) => {
  const types = {
    video: VideoGrid,
    image: ImageGrid
  }
  const SelectedType = types[props.type]
  return (
    <SelectedType
      {...props}
      classes={classes}
      openVideoModel={openVideoModel}
    />
  )
}

const GridMedia = (props) => {
  const { component, desktop } = props
  const values = idx(component, (_) => _.attributes.values)
  const { items } = values
  const classes = useStyles()
  const [videoModelOpen, setVideoModelOpen] = useState(false)
  const [currentVideo, setCurrentVideo] = useState(null)

  const openVideoModel = (currentVideo) => {
    setCurrentVideo(currentVideo)
    setVideoModelOpen(true)
  }

  const closeVideoModel = () => {
    setVideoModelOpen(false)
    setCurrentVideo(null)
  }

  return (
    <Fragment>
      <div className={classes.gridMedia}>
        {getValueByAttribute(values, 'heading') && (
          <Typography variant={'h3'} className={classes.heading}>
            {getValueByAttribute(values, 'heading')}
          </Typography>
        )}
        {getValueByAttribute(values, 'sub_heading') && (
          <Typography className={classes.subHeading}>
            {getValueByAttribute(values, 'sub_heading')}
          </Typography>
        )}
        {items && (
          <Grid
            container
            className={classes.dataContainer}
            spacing={
              desktop
                ? getValueByAttribute(values, 'spacing')
                  ? getValueByAttribute(values, 'spacing')
                  : 10
                : 4
            }
          >
            <ShowMore
              items={items}
              by={getValueByAttribute(values, 'show_more')}
            >
              {({ current, onMore }) => (
                <>
                  {current.map((item, index) => {
                    return (
                      <Grid item md={item.width} key={index}>
                        <AnimatedOnScroll animationIn={'fadeInUp'}>
                          {loadType(item, classes, openVideoModel)}
                        </AnimatedOnScroll>
                      </Grid>
                    )
                  })}
                  <div className='department-viewmore-container'>
                    <Button
                      variant='outlined'
                      className={
                        !onMore ? classes.loadMoreHidden : classes.loadMore
                      }
                      disableElevation
                      onClick={() => {
                        if (!!onMore) onMore()
                      }}
                    >
                      View more
                      <ArrowDropDownIcon />
                    </Button>
                  </div>
                </>
              )}
            </ShowMore>
          </Grid>
        )}
      </div>
      <Video
        open={videoModelOpen}
        video={currentVideo}
        close={closeVideoModel}
      />
    </Fragment>
  )
}

export default GridMedia
