import React, { useEffect } from 'react'
import { makeStyles, Typography, Grid, CardMedia } from '@material-ui/core'
import { motion, useAnimation } from 'framer-motion'
import { BrandBannerEffect } from '../../Utils/AnimationEffects'
import { useInView } from 'react-intersection-observer'
import { getValueByAttribute } from '../../Utils/DataExtract'
import idx from 'idx'

const useStyles = makeStyles((theme) => ({
  storyTile: {
    padding: '6%',
    paddingBottom: 80,
    background: '#F7F7F7',
    position: 'relative',
    width: '100%',
    height: '100%',
    boxShadow: '0px 0px 3px 0px rgb(216 216 216)',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4),
      width: '100%',
      marginBottom: 0
    },
    marginBottom: 80
  },
  boxContainer: {
    height: '75%',
    [theme.breakpoints.down('sm')]: {
      height: '100%'
    }
  },
  overlay: {
    bottom: -64,
    position: 'absolute',
    width: '100%',
    height: 120,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      width: '100%',
      bottom: 0,
      height: 'auto',
      paddingTop: 20,
      paddingBottom: 20
    }
  },
  image: {
    height: 90,
    width: 90,
    [theme.breakpoints.down('sm')]: {
      height: 70,
      width: 70
    }
  },
  textBlock: {
    paddingLeft: theme.spacing(2),
    width: '74%'
  },
  tmName: {
    fontSize: '1rem',
    fontWeight: 'bold',
    color: '#CC0000'
  },
  tmPosition: {
    fontSize: '0.7rem',
    color: '#707070'
  },
  heading: {
    color: '#454545',
    fontSize: '1.6rem',
    lineHeight: '3rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      lineHeight: '1.5rem'
    }
  },
  desc: {
    color: '#333333',
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
      paddingTop: 10
    }
  },
  tmLocation: {
    fontSize: '0.7rem',
    color: '#333333',
    fontWeight: 'bold'
  }
}))

const StoryTileCampusRecruitment = (props) => {
  const { component } = props
  const values = idx(component, (_) => _.attributes.values)
  const classes = useStyles()
  const controls = useAnimation()
  const [ref, inView] = useInView({ triggerOnce: false })

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        controls.start('visible')
      }, 300)
    }
  }, [controls, inView])

  return (
    <>
      <div ref={ref} className={classes.boxContainer}>
        <motion.div
          animate={controls}
          initial='hidden'
          {...BrandBannerEffect}
          className={classes.storyTile}
        >
          {getValueByAttribute(values, 'description') && (
            <Typography className={classes.desc}>
              {getValueByAttribute(values, 'description')}
            </Typography>
          )}
          <div className={classes.overlay}>
            <Grid container>
              <Grid item className={classes.image}>
                <CardMedia
                  component={'img'}
                  image={getValueByAttribute(values, 'tm_image')}
                  alt={getValueByAttribute(values, 'tm_name')}
                />
              </Grid>
              <Grid item className={classes.textBlock}>
                {getValueByAttribute(values, 'tm_name') && (
                  <Typography className={classes.tmName}>
                    {getValueByAttribute(values, 'tm_name')}
                  </Typography>
                )}
                {getValueByAttribute(values, 'tm_position') && (
                  <Typography className={classes.tmPosition}>
                    {getValueByAttribute(values, 'tm_position')}
                  </Typography>
                )}
                {getValueByAttribute(values, 'tm_location') && (
                  <Typography className={classes.tmPosition}>
                    {getValueByAttribute(values, 'tm_location')}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </div>
        </motion.div>
      </div>
    </>
  )
}

export default StoryTileCampusRecruitment
