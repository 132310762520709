import { FETCH_PAGE_DATA_IN_PROGRESS, FETCH_PAGE_DATA_FAILED, FETCH_PAGE_DATA_SUCCESS, OPEN_SEARCH_BOX } from './actionType'

export const initialState = {
  getPageData: {
    inProgress: false,
    error: null
  },
  searchBoxData: {
    open: false
  },
  pageCache: {}
}

export default function pageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_PAGE_DATA_IN_PROGRESS: {
      const newState = {
        ...state,
        getPageData: {
          ...state.getPageData,
          inProgress: true
        },
      }
      return newState
    }

    case FETCH_PAGE_DATA_SUCCESS: {
      const pageCache  = (action.data) ? {...state.pageCache, [action.slug]: action.data} : {...state.pageCache, [action.slug]: 404 }
      const newState = {
        ...state,
        getPageData: {
          ...state.getPageData,
          inProgress: false,
          error: null
        },
        pageCache: pageCache
      }
      return newState
    }

    case FETCH_PAGE_DATA_FAILED: {
      const newState = {
        ...state,
        getPageData: {
          ...state.getPageData,
          inProgress: false,
          error: action.error
        },
      }
      return newState
    }

    case OPEN_SEARCH_BOX: {
      const newState = {
        ...state,
        searchBoxData: {
          open: !state.searchBoxData.open
        },
      }
      return newState
    }

    default:
      return state
  }
}
