import { 
  FETCH_POST_DATA_IN_PROGRESS,
  FETCH_POST_DATA_FAILED,
  FETCH_POST_DATA_SUCCESS,
  FETCH_SIMILAR_POSTS_IN_PROGRESS,
  FETCH_SIMILAR_POSTS_FAILED,
  FETCH_SIMILAR_POSTS_SUCCESS,
  FETCH_RECENT_POSTS_IN_PROGRESS,
  FETCH_RECENT_POSTS_SUCCESS,
  FETCH_RECENT_POSTS_FAILED,
  GET_POST_CATEGORIES_IN_PROGRESS,
  GET_POST_CATEGORIES_SUCCESS,
  GET_POST_CATEGORIES_FAILED,
  GET_POSTS_BY_CATEGORIES_IN_PROGRESS,
  GET_POSTS_BY_CATEGORIES_SUCCESS,
  GET_POSTS_BY_CATEGORIES_FAILED,
  GET_CATEGORY_POSTS_IN_PROGRESS,
  GET_CATEGORY_POSTS_SUCCESS,
  GET_CATEGORY_POSTS_FAILED,
  GET_TAG_POSTS_IN_PROGRESS,
  GET_TAG_POSTS_FAILED,
  GET_TAG_POSTS_SUCCESS,
} from './actionType'

export const initialState = {
  getPostData: {
    inProgress: false,
    error: null
  },
  postCache: {},
  getSimilarPosts: {
    inProgress: false,
    error: null
  },
  similarPostsCache: {},
  recentPosts: {
    inProgress: false,
    error: null,
    posts: []
  },
  postCategories: {
    inProgress: false,
    error: null,
    categories: []
  },
  postsByCategories: {
    inProgress: false,
    error: null,
    posts: [],
    lastFetchCount: 0
  },
  byCategory: {
    inProgress: false,
    error: null,
    posts: {},
    lastFetchCount: 0
  },
  byTag: {
    inProgress: false,
    error: null,
    posts: {},
    lastFetchCount: 0
  }
}

export default function postReducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_POST_DATA_IN_PROGRESS: {
      const newState = {
        ...state,
        getPostData: {
          ...state.getPostData,
          inProgress: true
        },
      }
      return newState
    }

    case FETCH_POST_DATA_SUCCESS: {
      const postCache = (action.data) ? { ...state.postCache, [action.slug]: action.data } : { ...state.postCache, [action.slug]: 404 }
      const newState = {
        ...state,
        getPostData: {
          ...state.getPostData,
          inProgress: false,
          error: null
        },
        postCache: postCache
      }
      return newState
    }

    case FETCH_POST_DATA_FAILED: {
      const newState = {
        ...state,
        getPostData: {
          ...state.getPostData,
          inProgress: false,
          error: action.error
        },
      }
      return newState
    }

    case FETCH_SIMILAR_POSTS_IN_PROGRESS: {
      const newState = {
        ...state,
        getSimilarPosts: {
          ...initialState.getSimilarPosts,
          inProgress: true
        }
      }
      return newState
    }

    case FETCH_SIMILAR_POSTS_SUCCESS: {
      const newState = {
        ...state,
        getSimilarPosts: {
          ...initialState.getSimilarPosts,
        },
        similarPostsCache: {
          ...state.similarPostsCache,
          [action.category]: action.data
        }
      }
      return newState
    }

    case FETCH_SIMILAR_POSTS_FAILED: {
      const newState = {
        ...state,
        getSimilarPosts: {
          ...initialState.getSimilarPosts,
          error: action.error
        }
      }
      return newState
    }

    case FETCH_RECENT_POSTS_IN_PROGRESS: {
      const newState = {
        ...state,
        recentPosts: {
          ...state.recentPosts,
          inProgress: true
        }
      }
      return newState
    }

    case FETCH_RECENT_POSTS_SUCCESS: {
      const newState = {
        ...state,
        recentPosts: {
          ...initialState.recentPosts,
          posts: action.data
        }
      }
      return newState
    }

    case FETCH_RECENT_POSTS_FAILED: {
      const newState = {
        ...state,
        recentPosts: {
          ...state.recentPosts,
          inProgress: false,
          error: action.error
        }
      }
      return newState
    }

    case GET_POST_CATEGORIES_IN_PROGRESS: {
      const newState = {
        ...state,
        postCategories: {
          ...state.postCategories,
          inProgress: true
        }
      }
      return newState
    }

    case GET_POST_CATEGORIES_SUCCESS: {
      const newState = {
        ...state,
        postCategories: {
          ...state.postCategories,
          inProgress: false,
          categories: action.data
        }
      }
      return newState
    }

    case GET_POST_CATEGORIES_FAILED: {
      const newState = {
        ...state,
        postCategories: {
          ...state.postCategories,
          inProgress: false,
          error: action.error
        }
      }
      return newState
    }

    case GET_POSTS_BY_CATEGORIES_IN_PROGRESS: {
      const newState = {
        ...state,
        postsByCategories: {
          ...state.postsByCategories,
          inProgress: true
        }
      }
      return newState
    }

    case GET_POSTS_BY_CATEGORIES_SUCCESS: {
      const newState = {
        ...state,
        postsByCategories: {
          ...state.postsByCategories,
          inProgress: false,
          posts: action.offset === 0 ? action.data : [...state.postsByCategories.posts, ...action.data],
          lastFetchCount: action.data.length
        }
      }
      return newState
    }

    case GET_POSTS_BY_CATEGORIES_FAILED: {
      const newState = {
        ...state,
        postsByCategories: {
          ...state.postsByCategories,
          inProgress: false,
          error: action.error
        }
      }
      return newState
    }

    case GET_CATEGORY_POSTS_IN_PROGRESS: {
      const newState = {
        ...state,
        byCategory: {
          ...state.byCategory,
          inProgress: true,
        }
      }
      return newState
    }

    case GET_CATEGORY_POSTS_SUCCESS: {
      const newState = {
        ...state,
        byCategory: {
          ...state.byCategory,
          inProgress: false,
          posts: {
            [action.category]: action.offset === 0 ? action.data : [...state.byCategory.posts[action.category], ...action.data]
          },
          lastFetchCount: action.data.length
        }
      }
      return newState
    }

    case GET_CATEGORY_POSTS_FAILED: {
      const newState = {
        ...state,
        byCategory: {
          ...state.byCategory,
          inProgress: false,
          error: action.error,
        }
      }
      return newState
    }

    case GET_TAG_POSTS_IN_PROGRESS: {
      const newState = {
        ...state,
        byTag: {
          ...state.byTag,
          inProgress: true,
        }
      }
      return newState
    }

    case GET_TAG_POSTS_SUCCESS: {
      const newState = {
        ...state,
        byTag: {
          ...state.byTag,
          inProgress: false,
          posts: {
            [action.tag]: action.offset === 0 ? action.data : [...state.byTag.posts[action.tag], ...action.data]
          },
          lastFetchCount: action.data.length
        }
      }
      return newState
    }

    case GET_TAG_POSTS_FAILED: {
      const newState = {
        ...state,
        byTag: {
          ...state.byTag,
          inProgress: false,
          error: action.error,
        }
      }
      return newState
    }

    default:
      return state
  }
}
