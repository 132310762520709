import React, { Fragment } from 'react'
import { makeStyles, Grid, Card, CardContent, Typography } from '@material-ui/core'
import { AnimatedOnScroll } from 'react-animated-css-onscroll'
import { motion } from "framer-motion"

const useStyles = makeStyles((theme) => ({
    gridTile: {
        borderRadius: 0,
        border: '1px solid #eaeaea',
        height: '100%',
        color: '#333333',
        background: '#F7F7F7 0% 0% no-repeat padding-box',
        padding: theme.spacing(4),
        transition: 'color 0.3s, background 0.3s',
        '&:hover': {
            color: '#FFFFFF',
            background: '#CC0000',
            '& hr': {
                color: '#ffffff !important'
            },
            '& a': {
                color: '#ffffff !important'
            },
            '& p': {
                color: '#ffffff !important'
            },
            '& h3': {
                color: '#ffffff !important'
            },
            '& li':{
                color: '#ffffff !important'
            }
        },
        '& hr': {
            color: '#CC0000'
        },
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2),
        },
        [theme.breakpoints.down('xs')]: {
            height: 'auto !important'
        }
    },
    gridTileArea: {
        marginTop: 40,
        marginBottom: 40,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            marginTop: 30,
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: 30,
            marginTop: 30,
        }
    },
    tileHeader: {
        width: '100%',
        fontSize: 22,
        fontWeight: 'normal',
        [theme.breakpoints.down('md')]: {
            fontSize: 15,
            lineHeight: '1rem',
        },
    },
    descArea: {
        // height: 170,
        // paddingTop: theme.spacing(2),
        paddingBottom: 30,
        [theme.breakpoints.down('xs')]: {
            // height: 120,
            paddingTop: 0,
        }
    },
    tileDesc: {
        width: '100%',
        fontSize: 16,
        marginTop: 20,
        lineHeight: '1.7rem',
        [theme.breakpoints.down('md')]: {
            fontSize: 13,
            lineHeight: '1.3rem',
            marginTop: '20px !important',
        },
    },
    tileLink: {
        width: '100%',
        // paddingTop: 55,
        color: '#CC0000',
        textDecoration: 'none',
        '&:hover':{
            textDecoration: 'underline',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 13
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: 0,
        }
    },
    hr: {
        width: 90,
        textAlign: 'left',
        marginLeft: 0,
        height: 0,
        border: '1.5px solid',
        display: 'flex',
        marginTop: 20,
        [theme.breakpoints.down('xs')]: {
            marginTop: '20px !important',
        }
    }
}))

const ListBox = props => {
    const { values } = props
    const { tiles, spacing, styles } = values
    const classes = useStyles()

    const defaultCardStyles = {}
    const defaultHeadingStyles = {}
    const defaultSeperatorStyles = {}
    const defaultDescStyles = {}
    const defaultTileAreaStyle = {}

    const cardStyle = Object.assign({}, defaultCardStyles,  styles && styles.card ? styles.card : {} )
    const tileAreaStyle = Object.assign({}, defaultTileAreaStyle,  styles && styles.tileArea ? styles.tileArea : {} )
    const headingStyle = Object.assign({}, defaultHeadingStyles,  styles && styles.heading ? styles.heading : {} )
    const seperatorStyle = Object.assign({}, defaultSeperatorStyles,  styles && styles.seperator ? styles.seperator : {} )
    const descStyle = Object.assign({}, defaultDescStyles,  styles && styles.desc ? styles.seperator : {} )

    return (
        <Fragment>
            {tiles && tiles.length > 0 && (
                <div className={classes.gridTileArea} style={tileAreaStyle}>
                    <Grid container spacing={spacing}>
                    {tiles.map((tile, tileIndex) => {
                        return (
                            <Grid key={tileIndex} item xs={12} md={tile.width}>
                                <AnimatedOnScroll animationIn={'fadeInUp'} style={{height: '100%'}}>
                                <motion.div whileHover={{ scale: 0.95 }} style={{height: '100%'}}>
                                <Card className={classes.gridTile} elevation={0} style={cardStyle}>
                                    <CardContent>
                                        <div>
                                        <Typography gutterBottom className={classes.tileHeader} variant={'h3'} style={headingStyle}>
                                            {tile.title}
                                        </Typography>
                                        </div>
                                        <div>
                                            <hr className={classes.hr} style={seperatorStyle}/>
                                        </div>
                                        <div className={classes.descArea}>
                                            <ul className={classes.tileDesc} style={descStyle}>
                                                {tile.list && tile.list.length > 0 && tile.list.map((listItem, key) => {
                                                    return (
                                                        <li key={key}>{listItem}</li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </CardContent>
                                </Card>
                                </motion.div>
                                </AnimatedOnScroll>
                            </Grid>
                        )
                    })}
                    </Grid>
                </div>
            )}
        </Fragment>
    )
}

export default ListBox
