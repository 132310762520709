import React, { useEffect, Fragment, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { makeStyles, Typography, CardMedia, Chip, Card, CardContent, Grid, Link } from '@material-ui/core'
import { useParams } from 'react-router-dom'
// import Backdrop from '@material-ui/core/Backdrop'
// import CircularProgress from '@material-ui/core/CircularProgress'
import idx from 'idx'
import { getRecentPosts } from '../../store/post/actionCreator'
import {handleGlobalSearch} from '../../store/configuration/actionCreator'
import apiConfig from '../../apiConfig'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Checkbox from '@material-ui/core/Checkbox'
import ListSubheader from '@material-ui/core/ListSubheader'
import axios from 'axios'
import SearchIcon from '@material-ui/icons/Search'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import Container from '@material-ui/core/Container';
import Pagination from '@material-ui/lab/Pagination'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useHistory } from 'react-router-dom'
import Alert from '@material-ui/lab/Alert'
import CancelIcon from '@material-ui/icons/Cancel'
import {Link as RLink} from 'react-router-dom'
import _ from 'lodash'
import { Helmet } from 'react-helmet'


const { apiHost, xApiKey, preview } = apiConfig

const useStyles = makeStyles((theme) => ({
	fullWidth: {
		width: '100%'
	},
	postWidth: {
		paddingLeft: theme.spacing(10),
		paddingRight: theme.spacing(10),
		// paddingTop: theme.spacing(3),
		[theme.breakpoints.down('md')]: {
			paddingLeft: theme.spacing(5),
			paddingRight: theme.spacing(5)
			// paddingTop: theme.spacing(3),
		},
		[theme.breakpoints.down('xs')]: {
			padding: 0,
			paddingLeft: theme.spacing(4),
			paddingRight: theme.spacing(4)
			// paddingTop: theme.spacing(2),
		}
	},
	topSpace: {
		paddingTop: theme.spacing(3),
		[theme.breakpoints.down('md')]: {
			paddingTop: theme.spacing(3)
		},
		[theme.breakpoints.down('xs')]: {
			paddingTop: theme.spacing(2)
		}
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
		position: 'absolute',
		height: '100%',
		background: 'rgba(0,0,0,0.2)',
		marginTop: theme.spacing(3)
	},
	media: {
		height: 180,
		backgroundColor: '#d3d3d3',
		position: 'relative'
	},
	card: {
		borderRadius: 0,
	},
	category: {
		borderRadius: 0,
		background: '#cc0000',
		border: '1px solid #9a0303',
		color: '#fff',
		fontSize: '0.8rem',
		fontWeight: 'bold',
		marginBottom: theme.spacing(1),
		position: 'absolute',
		bottom: -22,
		left: 22,
		minWidth: 80
	},
	cover: {
		width: '30%',
		height: '100%',
		[theme.breakpoints.down('md')]: {
			width: '40%',
		}
	},
	content: {
		flex: '1 0 auto',
		[theme.breakpoints.up('sm')]: {
			paddingTop: 0,
		},
		'& em': {
			fontWeight: 'bold',
			fontStyle: 'normal'
		}
	},
	recentPosts: {
		background: '#efefef'
	},
	filterChips: {
		borderRadius: 0,
		fontSize: '0.9rem',
		marginRight: theme.spacing(1),
		marginBottom: theme.spacing(1),
		textTransform: 'uppercase',
	},
	selectedFilters: {
		borderRadius: 0,
		background: '#cc0000',
		color: '#fff',
		fontSize: '0.9rem',
		textTransform: 'uppercase',
		marginRight: theme.spacing(1),
		marginBottom: theme.spacing(1),
		'& :hover .MuiChip-clickable': {
			'&:hover, &:focus': {
				backgroundColor: '#cc0000',
			},
			'&:active': {
				backgroundColor: '#cc0000',
			},
		},
	},
	postList: {
		paddingTop: theme.spacing(2)
	},
	backDropParent: {
		marginTop: theme.spacing(2),
		position: "relative",
		zIndex: 0,
		height: '70vh'
	},
	postBackdrop: {
		backgroundColor: '#efefef',
		position: "absolute",
		color: '#d3d3d3',
	},
	loadMoreArea: {
		display: 'flex',
		justifyContent: 'center'
	},
	loadMore: {
		display: 'block',
		marginTop: 30,
		borderRadius: 35,
		color: '#CC0000',
		borderColor: '#CC0000',
		fontSize: 16,
		textTransform: 'none',
		paddingLeft: theme.spacing(3),
		[theme.breakpoints.down('md')]: {
			fontSize: 14,
			marginTop: 28,
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: 12,
			marginTop: 26,
		},
	},
	hideLoadMore: {
		display: 'none'
	},
	selectedFiltersClick: {
		'&:hover, &:focus': {
			backgroundColor: '#cc0000',
			color: '#fff'
		},
	},

	searchOptions: {
		width: '100%',
		// maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
		border: '1px solid #ddd',
		marginTop: 20,
	},
	cardRoot: {
		display: 'flex',
		boxShadow: 'none',
		margin: '40px 0',
		cursor: 'pointer',
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column'
		},
	},
	details: {
		width: 'calc(100% - 260px)',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		}
	},
	cardCover: {
		width: 260,
		height: 180,
		border: '1px solid #ddd',
		background: '#ddd',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			height: '260px'
		}
	},
	chipDesign: {
		paddingTop: 0,
		paddingBottom: 0,
	},
	checkBoxDesign: {
		minWidth: '35px',
	},
	searchContainer: {
		width: '60%',
		margin: '80px auto 60px auto',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			margin: '20px auto 0px auto',
		}
	},
	searchField: {
		width: '100%',
		borderRadius: 0,
		'&:focus': {
			outline: 'initial',
		}
	},
	notchedOutline: {
		borderWidth: '1px !important',
		borderColor: 'rgb(0 0 0 / 23%) !important',
	},
	searchResult: {
		width: '100%',
		padding: '15px',
		borderTop: 0,
		borderBottom: '1px solid',
		borderLeft: '1px solid',
		borderRight: '1px solid',
		'& > *': {
			margin: theme.spacing(0.5),
		},
	},
	description: {
		color: '#333',
		maxHeight: '7.15rem',
		overflow: 'hidden',
		[theme.breakpoints.down('sm')]: {
			maxHeight: '5.72rem'
		}
	},
	pagination: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: theme.spacing(2),
	},
	selectedFilter: {
		display: 'inline-block',
		'& div': {
			margin: '0 10px 10px 0',
		}
	},
	postTitle: {
		color: 'rgba(0, 0, 0, 0.54)',
		fontSize: 20,
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	checkbox: {
		color: '#cc0000 !important',
		'&$checked': {
		color: '#cc0000 !important'
		}
	},
	chipsArea: {
		paddingLeft: theme.spacing(2)
	},
	alert: {
		color: '#cc0000',
		borderColor: '#cc0000',
		'& svg': {
			color: '#cc0000'
		}
	},
	alertArea: {
		paddingBottom: theme.spacing(2)
	},
	resetLink: {
		position: 'absolute',
		right: theme.spacing(2),
		color: 'inherit',
		fontSize: '1rem',
		fontWeight: 'normal',
		'&:focus': {
			outline: 'none'
		}
	},
	resetIcon: {
		fontSize: '0.9rem',
		position: 'absolute',
		left: -16,
		top: 16,
		color: '#cc0000'
	}
}))

const SearchResult = (props) => {
	// const { recentPosts } = props
	const { searchData } = props
	const { search } = useParams()
	const classes = useStyles()
	const history = useHistory()
	// const { posts } = recentPosts
	const [category, setCategory] = useState([])
	const [searchResults, setSearchResults] = useState([])
	// const [topicCheckbox, setTopicCheckbox] = useState([]);
	const [catfilter, setCatFilter] = useState([])
	const [totalRecord, setTotalRecord] = useState(0)
	const [searchValue, setSearchValue] = useState({
		search: search
	});
	const [loading, setLoading] = useState(false)
	const [page, setPage] = useState(1);
	const configurations = useSelector((state) => state.configuration.getConfiguration.data)
	const analytics = _.find(configurations, { name: 'analytics_code' })
	const aCode = idx(analytics, (_) => _.published_value)

	const handlePageChange = (event, value) => {
		setPage(value)
	};

	const handleChange = (prop) => (event) => {
		setSearchValue({ ...searchValue, [prop]: event.target.value });
		// handleSearch(event.target.value)
	};

	// category
	useEffect(() => {
		const config = {
			method: 'get',
			url: `${apiHost}/posts/categories?key=${xApiKey}`,
			headers: { 
			  'Content-Type': 'application/json',
			},
		}
		axios(config)
			.then(function (response) {
				// handle success
				let result = response.data
				// let resultCount = result.length
				result = result.filter(item => item);
				setCategory(result)
				// let categoryArray = []
				// let categoryCheckbox = {}
				// for (let i = 0; i < resultCount; i++) {
				// 	let element = result[i]
				// 	// element = element.trim();
				// 	if (element.length > 0) {
				// 		categoryArray.push(element)
				// 		categoryCheckbox[element] = false
				// 	}
				// }
				// setTopicCheckbox(categoryCheckbox)
				// setCategory(categoryArray)
				// console.log("setTopicCheckbox", result)
			})
			.catch(function (error) {
				// handle error
			})
			.then(function () {
				// always executed
			})
	}, [])

	const handleSearch = () => {
		setLoading(true)
		// setSearchResults([])
		// setTotalRecord(0)
		props.handleGlobalSearch(searchValue.search, page, catfilter)
	}

	// const handleSearch = () => {
	// 	setLoading(true)
	// 	setSearchResults([])
	// 	const categories = catfilter.length > 0 ? catfilter.map((cat) => {
	// 		return `&categories[]=${cat}`
	// 	}).join('') : ''
	// 	// `${apiHost}/search?key=${xApiKey}&query=${searchParameter}&categories[]=company&categories[]=Test&tags[]=Test&page=1`
	// 	return axios
	// 		.get(
	// 			`${apiHost}/search?key=${xApiKey}&query=${searchValue.search.trim()}&page=${page}${categories}`
	// 		)
	// 		.then(function (response) {
	// 			// handle success
	// 			console.log(response.data, searchValue.search)
	// 			console.log(searchValue)
	// 			const { noOfResults, formattedResults } = formatSearchResults(response.data)
	// 			setSearchResults(formattedResults)
	// 			setTotalRecord(noOfResults)
	// 			setLoading(false)
	// 		})
	// 		.catch(function (error) {
	// 			setLoading(false)
	// 		})
	// }


	useEffect(() => {
		setSearchValue({ ...searchValue, search })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search])

	useEffect(() => {
		window.scrollTo(0, 0)
		handleSearch()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page])

	useEffect(() => {
		window.scrollTo(0, 0)
		handleSearch()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [catfilter])

	useEffect(() => {
		if (searchValue.search) {
			setPage(1)
			handleSearch()
		} else {
			setSearchResults([])
			setTotalRecord(0)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue])


	useEffect(() => {
		if(!searchData.inProgress) {
			if(searchData.currentKeyword === searchValue.search) {
				setSearchResults(searchData.formattedResults)
				setTotalRecord(searchData.noOfResults)
			}
			setLoading(false)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchData])

	const removeFilter = (cat) => {
		setCatFilter(catfilter.filter(acat => acat !== cat))
	}

	const resetFilter = (event) => {
		event.preventDefault()
		setCatFilter([])
	}


	const handleClickFilter = (selectedCheckBox) => {
		setCatFilter(catfilter.includes(selectedCheckBox) ? catfilter.filter(cat => cat !== selectedCheckBox) : [...catfilter, selectedCheckBox]);
	};

	return (
		<Fragment>
			{!preview && aCode && aCode.code && aCode.src && (
				<Helmet>
					<title>{'Target in india - Search'}</title>
					<meta
						name='title'
						content={'Target in india - Search'}
					/>
					<script src={aCode.src} async></script>
					<script type="text/javascript">
						{aCode.code}
					</script>
				</Helmet>
			)}
			<Container maxWidth="lg">
				<Grid container spacing={6}>
					<Grid item xs={12} style={{ borderBottom: '1px solid #ddd' }}>
						<div className={classes.searchContainer}>
							<OutlinedInput
								id="outlined-adornment-search"
								value={searchValue.search}
								className={classes.searchField}
								onChange={handleChange('search')}
								placeholder="Search"
								endAdornment={<InputAdornment position="end" ><SearchIcon
								// onClick={handleClickSearch}
								/></InputAdornment>}
								aria-describedby="outlined-search-helper-text"
								classes={{
									notchedOutline: classes.notchedOutline,
								}}
								inputProps={{
									'aria-label': 'search',
								}}
								labelWidth={0}
							/>
							{/* {searchResult.length > 0 && (
							<div className={classes.searchResult}>
								Related Search:
								{ searchResult.map((result, resultIndex) => {
									return (
										<Chip key={resultIndex} label={result} onClick={() => chipSelect(result)} style={{cursor: 'pointer'}} />
									)
								})}
							</div>
						)} */}
						</div>
					</Grid>
					<Grid item xs={12} sm={12} md={3}>
						<div className={classes.searchOptions}>
							<List component="nav" aria-label="filter" className={classes.chipsArea}>
								<Typography gutterBottom variant="h4">
									Filter
									{catfilter.length > 1 && (
										<Link href="#" onClick={resetFilter} className={classes.resetLink}>
											<CancelIcon className={classes.resetIcon} />
											Reset
										</Link>
									)}
								</Typography>
								
								<div className={classes.selectedFilter}>
									{catfilter.map((cat, i) => {
										return (
											<Chip
												label={cat}
												key={i}
												onDelete={() => removeFilter(cat)}
											/>
										)}
									)}
								</div>
							</List>
							<Divider />
							<List subheader={<ListSubheader>Topic</ListSubheader>}
								component="nav" aria-label="topic">
								{category.map((result, resultIndex) =>
									<ListItem button key={resultIndex} className={classes.chipDesign} onClick={() => handleClickFilter(result)} >
									<ListItemIcon className={classes.checkBoxDesign}>
											<Checkbox
												className={
													classes.checkbox
												}
												edge="start"
												tabIndex={-1}
												disableRipple
												name={result}
												checked={catfilter.includes(result)}
												onClick={() => handleClickFilter(result)}
											/>
										</ListItemIcon>
										<ListItemText primary={result} />
									</ListItem>
								)}
							</List>
							<Divider />
						</div>
					</Grid>
					<Grid item xs={12} sm={12} md={9} style={{position: 'relative'}}>
						<Backdrop
							className={classes.backdrop}
							open={loading}
						>
							<CircularProgress color='primary' />
						</Backdrop>
						<Grid container spacing={3}>
							<Grid item xs={12} style={{ padding: '20px 10px 12px' }}>
								<Typography variant="h3">
									{searchValue.search ? totalRecord : 0} results
								</Typography>
							</Grid>
						</Grid>
						<div>
							{searchValue.search && searchResults.map((post, postIndex) =>
								<Card className={classes.cardRoot} key={postIndex} onClick={() => history.push(post.url)}>
									<CardMedia
										className={classes.cardCover}
										image={post.image}
										title={post.imageTitle}
									/>
									<div className={classes.details}>
										<CardContent className={classes.content}>
											<RLink style={{textDecoration:'none'}} color="textSecondary" href={`${process.env.REACT_APP_ENV === 'prod' ?  'https://india.target.com' : 'https://india.perf.target.com'}${post.url}`}>
												{`${process.env.REACT_APP_ENV === 'prod' ?  'https://india.target.com' : 'https://india.perf.target.com'}${post.url}`}
											
											<Typography variant="h3" color="textSecondary" className={classes.postTitle}>
												{post.title}
											</Typography>
											<div dangerouslySetInnerHTML={{ __html: post.description }} className={classes.description} />
											</RLink>
										</CardContent>
									</div>
								</Card>
							)}
						</div>
						<div className={classes.pagination}>
							<Pagination count={searchValue.search ? Math.ceil(totalRecord / 10) : 0} page={page} onChange={handlePageChange} showFirstButton showLastButton />
						</div>
						{!searchValue.search && (
							<div className={classes.alertArea}>
								<Alert variant="outlined" severity="info" className={classes.alert}>Please enter search terms in the search box above.</Alert>
							</div>
						)}
					</Grid>
				</Grid>
			</Container >
		</Fragment>
	)
}

const mapStateToProps = (state) => {
	const recentPosts = idx(state.post, (_) => _.recentPosts)
	const searchData = idx(state.configuration, (_) => _.search)
	return {
		recentPosts,
		searchData
	}
}

const mapDispatchToProps = { getRecentPosts, handleGlobalSearch }

export default connect(mapStateToProps, mapDispatchToProps)(SearchResult)
