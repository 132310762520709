import React, { useEffect, Fragment, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import {
  HeroWithVideo,
  ResponsiveCarousel,
  StoryBox,
  ArchivedStory,
  Breadcrumb,
  InstagramFeed,
  Story,
  Hero,
  RichTextGrid,
  StickySideMenu,
  ShowCase,
  ShowCaseFour,
  GridTile,
  StoryTile,
  Gallery,
  Map,
  BrandBanner,
  Banner,
  CIOQuote,
  TextBox,
  BlockDivider,
  Accordion,
  AccordionTargettech,
  VideoComponent,
  // GridCard,
  GridMedia,
  // StoryGrid,
  // StoryGridCampusRecruitment,
  NewDitlo,
  HiringGridCard,
  Ditlo,
  TargetTechDitlo,
  CampusRecruitment,
  Info,
  SearchJobs,
  MediaGrid,
  DepartmentGrid,
  CardsGrid,
  TestimonialGrid,
  SolveforTarget,
  FAQLinks,
  DitloV1,
  ArchivedDitlo,
  TargetTechStickyNav,
  ShareComponent
} from '../ComponentsList/Components'
import Grid from '@material-ui/core/Grid'
import apiConfig from '../../apiConfig'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { getPageMenuStructure } from '../Utils/DataExtract'
import idx from 'idx'
import { Helmet } from 'react-helmet'

const { preview } = apiConfig

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  searchBackdrop: {
    zIndex: theme.zIndex.drawer + 2,
    color: '#fff'
  },
  fullWidth: {
    width: '100%'
  },
  fullPad: {
    width: '100%',
    padding: theme.spacing(10),
    paddingRight: 0,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5)
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(7)
    }
  },
  pageWidth: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    // paddingTop: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5)
      // paddingTop: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
      // paddingTop: theme.spacing(2),
    }
  },
  leftColumn: {
    width: '19%',
    [theme.breakpoints.down('md')]: {
      width: '28%',
      padding: theme.spacing(3)
    }
  },
  rightColumn: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0 !important'
    },
    [theme.breakpoints.down('sm')]: {
      padding: `0px !important`
    }
  },
  topSpace: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(3)
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2)
    }
  }
}))

const availableComponent = {
  'home-page-hero': HeroWithVideo,
  'responsive-carousel': ResponsiveCarousel,
  'instagram-feed': InstagramFeed,
  stories: StoryBox,
  'cio-quote': CIOQuote,
  'archived-stories': ArchivedStory,
  breadcrumb: Breadcrumb,
  'team-member-quote': Story,
  hero: Hero,
  'new-ditlo': NewDitlo,
  'rich-text-grid-box': RichTextGrid,
  'features-list': ShowCase,
  'features-list-four': ShowCaseFour,
  'tile-grid': GridTile,
  testimonial: StoryTile,
  gallery: Gallery,
  map: Map,
  'solve-for-target' : SolveforTarget,
  'target-tech-ditlo': TargetTechDitlo,
  'archived-ditlos': ArchivedDitlo,
  'brand-banner': BrandBanner,
  'explore-jobs': Banner,
  'text-box': TextBox,
  divider: BlockDivider,
  'share-component': ShareComponent,
  'accordion': Accordion,
  'accordion-target-tech': AccordionTargettech,
  'video-component' : VideoComponent,
  // 'grid-card': GridCard,
  'grid-media': GridMedia,
  // 'story-grid': StoryGridCampusRecruitment,
  'grid-card': HiringGridCard,
  'a-day-in-the-life-of': Ditlo,
  'ditlo': DitloV1,
  'campus-recruitment': CampusRecruitment,
  'covid-info': Info,
  'search-jobs': SearchJobs,
  'media-grid': MediaGrid,
  'departments-grid': DepartmentGrid,
  'cards-grid': CardsGrid,
  'testimonial-grid': TestimonialGrid,
  'faq-scroll-list': FAQLinks,
  'target-tech-sticky-nav' : TargetTechStickyNav,
}

const loadComponent = (attr) => {
  const ComponentLoader = availableComponent[attr.component.slug]
  return (
    <Fragment>
      {ComponentLoader && <ComponentLoader {...attr}></ComponentLoader>}
    </Fragment>
  )
}

const isComponentFullWidth = (component) => {
  const fullWidth = idx(component, (_) => _.attributes.values.full_width[0])
  return fullWidth ? true : false
}

const isComponentFullWidthForMobile = (component, desktop) => {
  const fullWidth = idx(
    component,
    (_) => _.attributes.values.full_width_mobile[0]
  )
  return fullWidth && !desktop ? true : false
}

const RenderComponents = (props) => {
  const { components, sectionIndex, section, home, desktop, sideMenu } = props
  const classes = useStyles()
  return (
    <Fragment key={sectionIndex}>
      <Grid
        container
        className={
          !home && sectionIndex === 0 && !(sideMenu && desktop)
            ? classes.topSpace
            : ''
        }
      >
        {components &&
          Object.keys(components).map((componentKey, pointer) => {
            return (
              <Grid
                item
                key={`component-${sectionIndex}-${componentKey}`}
                style={{
                  width: `${desktop ? section.percentages[pointer] : 100}%`,
                }}
                className={
                  home ||
                    isComponentFullWidth(components[componentKey]) ||
                    isComponentFullWidthForMobile(
                      components[componentKey],
                      desktop
                    )
                    ? classes.fullWidth
                    : classes.pageWidth
                }
              >
                {loadComponent({
                  component: components[componentKey],
                  sectionIndex,
                  componentIndex: componentKey,
                  desktop,
                  sideMenu
                })}
              </Grid>
            )
          })}
      </Grid>
    </Fragment>
  )
}

const RenderPageLayout = (props) => {
  const { layout, home, sideMenu, desktop, pageMenu } = props
  const classes = useStyles()
  return (
    <Fragment>
      <Grid
        container
        className={sideMenu && desktop ? classes.fullPad : classes.fullWidth}
      >
        {sideMenu && desktop && (
          <Grid item className={classes.leftColumn}>
            <StickySideMenu values={pageMenu} desktop={desktop} />
          </Grid>
        )}
        <Grid
          item
          xs
          className={sideMenu && desktop ? classes.rightColumn : ''}
        >
          {layout &&
            layout.map((section, sectionIndex) => {
              return (
                <RenderComponents
                  key={sectionIndex}
                  components={section.components}
                  sectionIndex={sectionIndex}
                  section={section}
                  home={home}
                  desktop={desktop}
                  sideMenu={sideMenu}
                />
              )
            })}
        </Grid>
      </Grid>
    </Fragment>
  )
}

const PageContainer = () => {
  const classes = useStyles()
  const { pathname } = useLocation()
  const history = useHistory()

  const currentPage = useSelector((state) => state.page.pageCache[pathname])
  const configurations = useSelector((state) => state.configuration.getConfiguration.data)
  const analytics = _.find(configurations, { name: 'analytics_code' })
  const aCode = idx(analytics, (_) => _.published_value)
  const settings = useSelector(
    (state) => state.configuration.getConfiguration.data
  )
  const pageMenu = getPageMenuStructure(settings, pathname)
  const { inProgress } = useSelector((state) => state.page.getPageData)
  const { open } = useSelector((state) => state.page.searchBoxData)
  const [backDropOpen, setBackDropOpen] = useState(false)
  const [searchBackdropOpen, setSearchBackdropOpen] = useState(false)
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const handleCloseBackdrop = () => {
    setBackDropOpen(false)
  }

  // const handleCloseSearchBackdrop = () => {
  //   setSearchBackdropOpen(false)
  // }

  useEffect(() => {
    setSearchBackdropOpen(open)
  }, [open])

  useEffect(() => {
    setBackDropOpen(inProgress)
    if(!inProgress && currentPage && currentPage === 404) {
      history.push('/not-found')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgress])

  return (
    <Fragment>
      <Backdrop
        className={classes.searchBackdrop}
        open={searchBackdropOpen}
      // onClick={handleCloseSearchBackdrop}
      >
      </Backdrop>

      <Backdrop
        className={classes.backdrop}
        open={backDropOpen}
        onClick={handleCloseBackdrop}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      {!preview && aCode && aCode.code && aCode.src && !inProgress && currentPage && currentPage !== 404 && (
        <Helmet>
          <title>{currentPage && currentPage.title ? currentPage.title : 'Target in india'}</title>
          <meta
            name='title'
            content={currentPage && currentPage.title ? currentPage.title : 'Target in india'}
          />
          {currentPage && currentPage.seo && currentPage.seo.length > 0 && currentPage.seo.map((tag, key) => {
            return (
              <meta key={key} name={tag.name} content={tag.content} />
            )
          })}
          <script src={aCode.src} async></script>
          <script type="text/javascript">
            {aCode.code}
          </script>
        </Helmet>
      )}
      <Helmet>
        {currentPage && (
          <script type="application/ld+json">{`{
            "@context": "http://schema.org/",
            "@type": "WebPage",
            "headline": "${currentPage.title}",
            "url": "https://india.perf.target.com${pathname}",
          }`}</script>
          )}
      </Helmet>

      {/* <div className={currentPage && currentPage.is_home ? classes.fullWidth : classes.pageWidth}>
        {currentPage && <RenderPageLayout layout={preview ? currentPage.layout : currentPage.published_layout} />}
      </div> */}
      <div className={classes.fullWidth}>
        {currentPage && (
          <RenderPageLayout
            desktop={desktop}
            home={currentPage.is_home}
            sideMenu={currentPage.has_sidemenu}
            layout={preview ? currentPage.layout : currentPage.published_layout}
            pageMenu={pageMenu}
          />
        )}
      </div>
    </Fragment>
  )
}

const mapDispatchToProps = {}

export default connect(null, mapDispatchToProps)(PageContainer)
