/**
 * Smooth transition from left to right
 */
export const SmoothLeftToRight = {
    initial: {
        x: -20,
        y: 0,
        opacity: 0
    },
    animate: {
        x: 0,
        y: 0,
        opacity: 1
    },
    transition: { duration: 0.7 }
}

export const SmoothZoomEntry = {
    initial: {
        scale: 0.9,
    },
    animate: {
        scale: 1,
    },
    transition: { duration: 1 }
}

export const SmallZoomEntry = {
    initial: {
        scale: 0.96,
    },
    animate: {
        scale: 1,
    },
    transition: { duration: 1 }
}

export const BannerZoom = {
    desktop: {
        variants: {
            visible: { scale: 1, opacity: 1, x: 0 },
            hidden: { scale: 0.4, opacity: 0.1, x: -250 }
        },
        transition: {
            duration: 1
        }
    },
    mobile: {
        variants: {
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0.1, y: -10 }
        },
        transition: {
            duration: 1
        }
    }
}

export const RedBanner = {
    desktop: {
        variants: {
            visible: { scale: 1, opacity: 1, x: 0 },
            hidden: { scale: 0.1, opacity: 0, x: -250 }
        },
        transition: {
            duration: 1
        }
    },
    mobile: {
        variants: {
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0.1, y: -10 }
        },
        transition: {
            duration: 1
        }
    },
    rotate: {
        variants: {
            visible: { scale: 1, opacity: 1, rotate: 0 },
            hidden: { scale: 0.1, opacity: 0, rotate: 45 }
        },
        transition: {
            duration: 1,
        }
    }
}

export const BrandBannerEffect = {
    variants: {
        visible: { scale: 1, opacity: 1 },
        hidden: { scale: 0.9, opacity: 0.6 }
    },
    transition: {
        duration: 1,
    }
}

export const TextBoxEffects = {
    text: {
        variants: {
            visible: { x: 0, opacity: 1 },
            hidden: { x: -5, opacity: 0.9 }
        },
        transition: {
            duration: 0.7,
        }
    },
    image: {
        variants: {
            visible: { y: 0, opacity: 1 },
            hidden: { y: 5, opacity: 0.8 }
        },
        transition: {
            duration: 0.7,
        }
    }
}

export const TileZoomEffects = {
    direct: {
        animate: {
            scale: 1,
            opacity: 1
        },
        initial: {
            scale: 0.95,
            opacity: 0
        },
        transition: {
            duration: 0.3,
        }
    },
    lazy: {
        variants: {
            visible: {
                scale: 1,
                opacity: 1
            },
            hidden: {
                scale: 0.95,
                opacity: 0
            }
        },
        transition: {
            duration: 1,
        }
    }
}