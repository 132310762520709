import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core'
import ImageGallery from 'react-image-gallery'
import { IconButton } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import {getValueByAttribute} from '../../Utils/DataExtract'
import idx from 'idx'
import "react-image-gallery/styles/scss/image-gallery.scss";

const useStyles = makeStyles((theme) => ({
  gallery: {
    width: '100%',
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  },
  galleryArea: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    }
  },
  nextButton: {
    marginRight: 10,
    border: '1px solid',
    color: '#000',
    background: '#fff',
    position: 'absolute',
    top: '50%',
    zIndex: 1,
    right: 0,

    transform: 'translateY(-50%)',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
    '&:hover': {
      background: 'rgba(256,256,256, 0.7)'
    }
  },
  previousButton: {
    marginLeft: 10,
    // marginLeft: 60,
    border: '1px solid',
    color: '#000',
    background: '#fff',
    position: 'absolute',
    top: '50%',
    zIndex: 1,
    transform: 'translateY(-50%)',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
    '&:hover': {
      background: 'rgba(256,256,256, 0.7)'
    }
  }
}))

const Gallery = (props) => {
  const { component } = props
  const values = idx(component, (_) => _.attributes.values)
  const images = getValueByAttribute(values, 'images', true)
  const payload = images && images.length > 0 ? images.map(image => {
    return {
      original: getValueByAttribute(image, 'image'),
      thumbnail: getValueByAttribute(image, 'thumbnail'),
    }
  }) : []
  const classes = useStyles()

  const renderRightNav = (onClick, disabled) => (
    <IconButton
      aria-label='Next Slide'
      className={classes.nextButton}
      onClick={onClick}
    >
      <NavigateNextIcon className={classes.nextIcon} />
    </IconButton>
  )

  const renderLeftNav = (onClick, disabled) => (
    <IconButton
      aria-label='Previous Slide'
      className={classes.previousButton}
      onClick={onClick}
    >
      <NavigateBeforeIcon className={classes.previousIcon} />
    </IconButton>
  )

  return (
    <Fragment>
      <div className={classes.gallery}>
        <div className={classes.galleryArea}>
          {payload && payload.length > 0 && (
            <ImageGallery
              items={payload}
              showPlayButton={false}
              renderRightNav={renderRightNav}
              renderLeftNav={renderLeftNav}
              showFullscreenButton={false}
            />
          )}
        </div>
      </div>
    </Fragment>
  )
}

export default Gallery
