import React, {useEffect} from 'react'
import { makeStyles, Typography, Divider, Card, CardMedia } from '@material-ui/core'
import { motion, useAnimation } from 'framer-motion'
import { TextBoxEffects } from '../../Utils/AnimationEffects'
import { useInView } from "react-intersection-observer"
import renderHTML from 'react-render-html'
import idx from 'idx'
import {getValueByAttribute} from '../../Utils/DataExtract'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { Link as RLink } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    bannerText:{
        marginBottom: '20px',
        [theme.breakpoints.down('xs')]: {
            marginTop: '20px',
        }
    },
    textBox: {
        paddingTop: theme.spacing(3),
        // paddingBottom: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(0),
            // paddingBottom: theme.spacing(3) + 'px !important',
            marginTop: '0 !important'
        }
    },
    headingStyle: {
        color: '#333333',
        textAlign: 'left',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.25rem',
        }
    },
    descStyle: {
        wordWrap: 'break-word',
        whiteSpace: 'pre-line',
        paddingRight: theme.spacing(3),
        color: '#333333',
        textAlign: 'left',
        letterSpacing: 0,
        fontSize: '1rem',
        [theme.breakpoints.down('xs')]: {
            paddingRight: 0,
            // paddingTop: 10,
            fontSize: '1rem',
            lineHeight: '22px',
        },
        '& p': {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            color: '#333333',
            fontSize: '1rem',
        }
    },
    dividerStyle: {
        border: '3px solid #CC0000',
        marginLeft: 5,
        width: 90,
        height: 0,
        [theme.breakpoints.down('xs')]: {
            border: '2px solid #CC0000',
            marginLeft: 2,
        }
    },
    descLink:{
        fontWeight: 'bolder',
    },
    link: {
        textAlign: 'center',
        marginBottom: theme.spacing(6),
        marginTop: theme.spacing(6)
    },
    linkButton: {
        border: '1px solid',
        color: '#CC0000',
        borderRadius: 20,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(7),
        paddingRight: theme.spacing(7),
        textDecoration: 'none',
        fontSize: 16,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
        }
    },
    rightArrow: {
        verticalAlign: 'middle'
    },
    imageBox: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(3),
        },
    },
}))

const TextBox = (props) => {
    const { component, desktop } = props
    const values = idx(component, (_) => _.attributes.values)
    const classes = useStyles()
    const controls = useAnimation()
    const [ref, inView] = useInView({triggerOnce: false});

    useEffect(() => {
        if (inView) {
            setTimeout(() => {
                controls.start("visible");
            }, 300)
        }
    }, [controls, inView])

    const deafultHeadingStyle = {}

    const deafultDescStyle = {}

    const defaultDividerStyle = {}

    const defaultTextBoxStyle = {}

    const headingStyle = /*styling && styling.heading ? Object.assign({}, deafultHeadingStyle, styling.heading) :*/ deafultHeadingStyle
    const descStyle = /*styling && styling.desc ? Object.assign({}, deafultDescStyle, styling.desc) :*/ deafultDescStyle
    const dividerStyle = /*styling && styling.divider ? Object.assign({}, defaultDividerStyle, styling.divider) :*/ defaultDividerStyle
    const textBoxStyle = /*styling && styling.textBox ? Object.assign({}, defaultTextBoxStyle, styling.textBox) :*/ defaultTextBoxStyle

    const linkToPage = getValueByAttribute(values, 'page_link') ? getValueByAttribute(values, 'page_link') : null

    return (
        <>
            <div ref={ref} id={getValueByAttribute(values, 'heading') ? (getValueByAttribute(values, 'heading').split(" ")[0].toLowerCase()) : "textbox"}>
                <motion.div                                     
                    animate={controls}
                    initial="hidden"
                    {...TextBoxEffects.text}
                    className={classes.bannerText}
                >
                    {(getValueByAttribute(values, 'heading') || getValueByAttribute(values, 'description') || getValueByAttribute(values, 'description') || getValueByAttribute(values, 'link')) && <div className={classes.textBox} style={textBoxStyle}>
                        {getValueByAttribute(values, 'heading') && <Typography style={headingStyle} className={classes.headingStyle} variant={"h3"}>{getValueByAttribute(values, 'heading')}</Typography>}
                        {getValueByAttribute(values, 'divider') && <Divider style={dividerStyle} className={classes.dividerStyle}/>}
                        {getValueByAttribute(values, 'description') && <div style={descStyle} className={classes.descStyle}>{renderHTML(getValueByAttribute(values, 'description'))}</div>}
                        {getValueByAttribute(values, 'link') && <>{renderHTML(getValueByAttribute(values, 'link'))}</>}
                    </div>}
                    <motion.div                                     
                        animate={controls}
                        initial="hidden"
                        {...TextBoxEffects.image}
                        className={classes.bannerText}
                    >
                        {getValueByAttribute(values, 'image_for_desktop') && (
                            <Card className={classes.imageBox} elevation={0}>
                                <CardMedia 
                                    component={'img'}
                                    className={classes.media}
                                    image={`${encodeURI(
                                        desktop && getValueByAttribute(values, 'image_for_desktop') ? getValueByAttribute(values, 'image_for_desktop') : getValueByAttribute(values, 'image_for_mobile') ? getValueByAttribute(values, 'image_for_mobile') : getValueByAttribute(values, 'image_for_desktop') )}`} 
                                    alt={getValueByAttribute(values, 'alternative_text_for_image') ? getValueByAttribute(values, 'alternative_text_for_image') : ''} 
                                />
                            </Card>
                        )}
                    </motion.div>
                </motion.div>
                
                <motion.div                                     
                    animate={controls}
                    initial="hidden"
                    {...TextBoxEffects.text}
                    className={classes.bannerText}
                >
                {linkToPage && (
                    <div className={classes.link}>
                        <RLink className={classes.linkButton} to={linkToPage.slug}>{getValueByAttribute(values, 'link_label')}<ArrowRightIcon className={classes.rightArrow} /></RLink>
                    </div>
                )}

                </motion.div>
            </div>
        </>
    )
}

export default TextBox