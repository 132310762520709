import React, { useState } from 'react'
import { makeStyles, Typography, CardMedia, Chip, IconButton, Card, CardActionArea, CardContent } from '@material-ui/core'
import Carousel from 'nuka-carousel'
import { useHistory } from 'react-router-dom'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import classNames from 'classnames'
import Moment from 'react-moment'
import { getValueByAttribute, getFeaturedImageURL } from '../Utils/DataExtract'
import FavoriteIcon from '@material-ui/icons/Favorite'

const useStyles = makeStyles((theme) => ({
    heading: {
        paddingBottom: theme.spacing(3)
    },
    previousButton: {
        marginLeft: -25,
        border: '1px solid',
        color: '#333333',
        background: '#fff',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        '&:hover': {
            background: 'rgba(256,256,256, 0.7)'
        },
        '&:focus': {
            outline: 'none !important'
        }
    },
    nextButton: {
        marginRight: -25,
        border: '1px solid',
        color: '#333333',
        background: '#fff',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        '&:hover': {
            background: 'rgba(256,256,256, 0.7)'
        },
        '&:focus': {
            outline: 'none !important'
        }
    },
    previousIcon: {
        color: '#333333'
    },
    nextIcon: {
        color: '#333333'
    },
    pagingDots: {
        top: '30px !important',
    },
    media: {
        height: 180,
        backgroundColor: '#d3d3d3',
        position: 'relative',
        // backgroundPosition: '10%'
    },
    card: {
        borderRadius: 0,
    },
    category: {
        borderRadius: 0,
        background: '#cc0000',
        border: '1px solid #9a0303',
        color: '#fff',
        fontSize: '0.8rem',
        fontWeight: 'bold',
        marginBottom: theme.spacing(1),
        position: 'absolute',
        bottom: -22,
        left: 22,
        minWidth: 80
    },
    likeIcon:{
		'&:hover, &:focus': {
			backgroundColor: 'transparent',
		},
		color: '#CC0000',
		display: 'inline-block'
	},
	pipe:{
		fontSize: '16px',
		padding: '0 10px',
		fontWeight: '200',
	},
    likeText: {
		display: 'inline-block',
		paddingLeft: '7px',
	  },
}))

const RenderCard = (props) => {
    const { item, classes } = props
    const history = useHistory()

    const cardClick = (url) => {
        history.push(url)
    }
    
    return (
        <Card elevation={0} className={classes.card} onClick={() => cardClick(item.url)}>
            <CardActionArea>
                <CardMedia
                    className={classes.media}
                    image={
                        getFeaturedImageURL(item.feature_data, false, item.feature_type)
                    }
                    title={
                        item.feature_type === 'image' && item.feature_data ? getValueByAttribute(item.feature_data, 'alt') : item.feature_data ? getValueByAttribute(item.feature_data, 'alt') : ''
                    }
                >
                    <Chip label={item.category} className={classes.category}/>
                </CardMedia>
                <CardContent>
                    <Typography gutterBottom variant="h4" style={{lineHeight: '2rem'}}>
                        {item.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                       {<Moment format={'MMMM D, YYYY'}>{item.published_time}</Moment>}<span className={classes.pipe}>&#124;</span>
                        <FavoriteIcon className={classes.likeIcon} /><div className={classes.likeText}>{item.no_of_likes ? item.no_of_likes : '0'}</div>
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

const RenderSlides = (props) => {
    const { carousel, classes, desktop, styles, currentSlideIndex, setCurrentSlideIndex } = props
    const { slideStyles } = styles ? styles : { slideStyles: { height: 550 } }
    const carouselProps = {
        cellSpacing: desktop ? 40 : 80,
        disableAnimation: desktop ? true : false, 
        wrapAround: true,
        cellAlign: 'center',
        slidesToShow: desktop ? 3 : 1.8,
        edgeEasing: 'easeLinear',
        autoplay: false,
        framePadding: desktop ? '0px' : '0px',
        scrollMode: desktop ? 'remainder' : 'page',
        transitionMode: desktop ? 'scroll' : 'scroll3d',
        renderCenterLeftControls: ({ previousSlide }) => (
            <IconButton
                aria-label="Previous Slide"
                className={classes.previousButton}
                onClick={previousSlide}>
                <NavigateBeforeIcon className={classes.previousIcon} />
            </IconButton>
        ),
        renderCenterRightControls: ({ nextSlide }) => (
            <IconButton
                aria-label="Next Slide"
                className={classes.nextButton}
                onClick={nextSlide}>
                <NavigateNextIcon className={classes.nextIcon} />
            </IconButton>
        ),
        defaultControlsConfig: {
            pagingDotsContainerClassName: classNames(classes.pagingDots)
        },
        slideIndex: currentSlideIndex,
        afterSlide: (index) => {
            setCurrentSlideIndex(index)
        }
    }

    if (!desktop) {
        carouselProps.animation = 'zoom'
    }
    return (
        <Carousel {...carouselProps}>
            {carousel && carousel.map((item, index) => {
                return (
                    <RenderCard item={item} key={index} classes={classes} desktop={desktop} styles={slideStyles} currentSlideIndex={currentSlideIndex} slideIndex={index} />
                )
            })
            }
        </Carousel>
    )
}

const SimilarPosts = (props) => {
    const { posts, desktop, hideHeading } = props
    const classes = useStyles()
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

    return (
        <>
            <div className={classes.similarPosts}>
                {!hideHeading && <Typography variant={"h3"} className={classes.heading}>Related stories</Typography>}
                <RenderSlides
                    carousel={posts}
                    classes={classes}
                    desktop={desktop}
                    currentSlideIndex={currentSlideIndex}
                    setCurrentSlideIndex={setCurrentSlideIndex}
                />
            </div>
        </>
    )
}

export default SimilarPosts
