import React, { useEffect, Fragment, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { makeStyles, Typography, CardMedia, Chip, Card, CardActionArea, CardContent, Grid, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';
import { getValueByAttribute, getFeaturedImageURL } from '../Utils/DataExtract';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import idx from 'idx';
import { getPostCategories, getRecentPosts, getSimilarPosts, getPostsByCategories } from '../../store/post/actionCreator';
import apiConfig from '../../apiConfig';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import { Breadcrumb } from '../ComponentsList/Components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SimilarPosts from './SimilarPosts';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FavoriteIcon from '@material-ui/icons/Favorite';

const { preview } = apiConfig;

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
  },
  blogDescription: {
    lineHeight: '2rem',
    marginTop: '20px !important',
    maxHeight: '4rem',
    wordWrap: 'break-word',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: '#333333',
    fontSize: '1.872rem',
    fontWeight: 'bold',
    textDecoration: 'none',

    '&:hover': {
      color: '#333 !important',
      textDecoration: 'none !important',
    },
  },
  blogDate: {
    lineHeight: '2rem',
  },
  blogContainer: {
    height: 140,
    borderRadius: 0,
    display: 'flex',
    position: 'relative',
    overflow: 'visible',
    marginBottom: 8,
  },
  blogMainTitle: {
    lineHeight: '2.2rem',
    height: '6rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingTop: 20,
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  blogTitle: {
    textDecoration: 'none',
    lineHeight: '2.2rem',
    height: '7rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    color: '#333333',
    fontSize: '1.872rem',
    fontWeight: 'bold',

    '&:hover': {
      color: '#333 !important',
      textDecoration: 'none',
    },
  },
  blogDateYear: {
    paddingTop: 4,
  },
  postWidth: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  topSpace: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  media: {
    height: 180,
    backgroundColor: '#d3d3d3',
    position: 'relative',
    backgroundPosition: '15%',
  },
  renderListMedia: {
    height: 280,
    backgroundColor: '#d3d3d3',
    position: 'relative',
    backgroundPosition: '10%',
    [theme.breakpoints.down('md')]: {
      height: 280,
    },
    [theme.breakpoints.down('sm')]: {
      height: 250,
    },
    [theme.breakpoints.down('xs')]: {
      height: 215,
    },
  },
  renderListContent: {
    '& h4': {
      maxHeight: '4rem',
    },
  },
  card: {
    borderRadius: 0,
  },
  category: {
    borderRadius: 0,
    background: '#cc0000',
    color: '#fff',
    fontSize: '0.9rem',
    position: 'absolute',
    bottom: -20,
    left: 22,
    minWidth: 80,
    padding: '22px 10px',
  },
  centerCategory: {
    borderRadius: 0,
    background: '#cc0000',
    color: '#fff',
    fontSize: '0.9rem',
    marginBottom: -20,
    maxWidth: '90%',
    padding: '22px 10px',
  },
  cover: {
    width: '28%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: '40%',
    },
  },
  content: {
    flex: '1 0 auto',
    height: '100%',
    width: '65%',
    paddingTop: 4,
    '& h4': {
      lineHeight: '2rem',
      marginBottom: '10px !important',
    },
    [theme.breakpoints.down('md')]: {
      width: '60%',
    },
  },
  recentPosts: {
    background: '#efefef',
  },
  likeIcon: {
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
    },
    color: '#CC0000',
    display: 'inline-block',
  },
  pipe: {
    fontSize: '16px',
    padding: '0 10px',
    fontWeight: '200',
  },
  filterChips: {
    borderRadius: 0,
    fontSize: '0.9rem',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textTransform: 'uppercase',
  },
  selectedFilters: {
    borderRadius: 0,
    background: '#cc0000',
    color: '#fff',
    fontSize: '0.9rem',
    textTransform: 'uppercase',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '& :hover .MuiChip-clickable': {
      '&:hover, &:focus': {
        backgroundColor: '#cc0000',
      },
      '&:active': {
        backgroundColor: '#cc0000',
      },
    },
  },
  backDropParent: {
    marginTop: theme.spacing(2),
    position: 'relative',
    zIndex: 0,
    height: '70vh',
  },
  postBackdrop: {
    backgroundColor: '#efefef',
    position: 'absolute',
    color: '#d3d3d3',
  },
  loadMoreArea: {
    display: 'flex',
    justifyContent: 'center',
  },
  loadMore: {
    display: 'block',
    marginTop: 30,
    borderRadius: 35,
    color: '#CC0000',
    borderColor: '#CC0000',
    fontSize: 16,
    textTransform: 'none',
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      marginTop: 28,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      marginTop: 26,
    },
  },
  hideLoadMore: {
    display: 'none',
  },
  selectedFiltersClick: {
    '&:hover, &:focus': {
      backgroundColor: '#cc0000',
      color: '#fff',
    },
  },
  filtersArea: {
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(2),
    },
  },
  likeText: {
    display: 'inline-block',
    paddingLeft: '7px',
  },
}));

const RenderCard = (props) => {
  const { item, classes, styleProps } = props;
  const history = useHistory();

  const cardClick = (url) => {
    history.push(url);
  };

  return (
    <Card elevation={0} className={classes.card} onClick={() => cardClick(item.url)} style={styleProps.card}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          style={styleProps.image}
          image={getFeaturedImageURL(item.feature_data, true, item.feature_type)}
          title={
            item.feature_type === 'image' && item.feature_data
              ? getValueByAttribute(item.feature_data, 'alt')
              : item.feature_data
              ? getValueByAttribute(item.feature_data, 'alt')
              : ''
          }
        >
          <Chip label={item.category} className={classes.category} />
        </CardMedia>
        <CardContent>
          <Typography variant='h3' className={classes.blogMainTitle}>
            {item.title}
          </Typography>
          <Typography variant='body2' color='textSecondary' component='p'>
            {<Moment format={'MMMM D, YYYY'}>{item.published_time}</Moment>} <span className={classes.pipe}>&#124;</span>
            <FavoriteIcon className={classes.likeIcon} />
            <div className={classes.likeText}>{item.no_of_likes ? item.no_of_likes : '0'}</div>
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const RenderFilters = (props) => {
  const classes = useStyles();
  const { selectedCats, setSelectedCats, availableCategories } = props;

  const clickOnFilters = (category) => {
    let updatedFilters = [];
    if (category === '') {
      updatedFilters.push('');
    } else {
      const status = selectedCats.includes(category);
      if (status) {
        const updatedOld = selectedCats.filter((val) => val !== category);
        updatedFilters = updatedOld && updatedOld.length > 0 ? [...updatedOld] : [''];
      } else {
        const updatedNew = selectedCats.filter((val) => val !== '');
        updatedFilters = updatedNew && updatedNew.length > 0 ? [...updatedNew, category] : [category];
      }
    }
    setSelectedCats([...updatedFilters]);
  };

  const checkCategoryExist = (category) => {
    const existance = selectedCats.filter((val) => val === category);
    return existance.length > 0;
  };

  return (
    <Fragment>
      <div className={classes.filtersArea}>
        {availableCategories &&
          availableCategories.length > 0 &&
          availableCategories.map((category, index) => {
            return (
              <Chip
                key={index}
                label={category !== '' ? category : 'ALL'}
                classes={{
                  root: checkCategoryExist(category) ? classes.selectedFilters : classes.filterChips,
                  clickable: checkCategoryExist(category) ? classes.selectedFiltersClick : classes.filterChips,
                }}
                onClick={() => clickOnFilters(category)}
              />
            );
          })}
      </div>
    </Fragment>
  );
};

const RenderListCard = (props) => {
  const { item, classes, desktop } = props;
  const history = useHistory();

  const cardClick = (url) => {
    history.push(url);
  };

  return (
    <Card elevation={0} className={classes.renderListCard} onClick={() => cardClick(item.url)}>
      <CardActionArea>
        <CardMedia
          className={classes.renderListMedia}
          image={getFeaturedImageURL(item.feature_data, desktop, item.feature_type)}
          title={
            item.feature_type === 'image' && item.feature_data
              ? getValueByAttribute(item.feature_data, 'alt')
              : item.feature_data
              ? getValueByAttribute(item.feature_data, 'alt')
              : ''
          }
        >
          <Chip label={item.category} className={classes.category} />
        </CardMedia>
        <CardContent className={classes.renderListContent}>
          <Typography variant='h4' className={classes.blogDescription}>
            <a className={classes.blogDescription} href={item.url}>
              {item.title}
            </a>
          </Typography>
          <Typography variant='body2' color='textSecondary' component='p' className={classes.blogDate}>
            {<Moment format={'MMMM D, YYYY'}>{item.published_time}</Moment>} <span className={classes.pipe}>&#124;</span>
            <FavoriteIcon className={classes.likeIcon} />
            <div className={classes.likeText}>{item.no_of_likes ? item.no_of_likes : '0'}</div>
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const RenderCategorizedPosts = (props) => {
  const classes = useStyles();
  const { posts, desktop, selectedCats } = props;
  return (
    <Fragment>
      <Grid container className={classes.postList} spacing={10}>
        {posts &&
          posts.length > 0 &&
          posts.map((post, index) => {
            if (selectedCats[0] === '' && index <= 3) {
              return null;
            } else {
              return (
                <Grid key={index} item xs={12} md={6}>
                  <RenderListCard item={post} classes={classes} desktop={desktop} />
                </Grid>
              );
            }
          })}
      </Grid>
    </Fragment>
  );
};

const Blogs = (props) => {
  const { getRecentPosts, recentPosts, postCategories, getPostCategories, getPostsByCategories, postsByCategories } = props;

  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const configurations = useSelector((state) => state.configuration.getConfiguration.data);
  const analytics = _.find(configurations, { name: 'analytics_code' });
  const aCode = idx(analytics, (_) => _.published_value);
  const { posts } = recentPosts;
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const [selectedCats, setSelectedCats] = useState(['']);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    getRecentPosts();
    getPostCategories();
  }, [getRecentPosts, getPostCategories]);

  useEffect(() => {
    if (recentPosts.inProgress) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [recentPosts]);

  useEffect(() => {
    getPostsByCategories(selectedCats);
    setOffset(0);
  }, [getPostsByCategories, selectedCats]);

  useEffect(() => {
    if (offset) {
      getPostsByCategories(selectedCats, offset);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  const cardClick = (url) => {
    history.push(url);
  };

  return (
    <Fragment>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color='inherit' />
      </Backdrop>
      {!preview && aCode && aCode.code && aCode.src && (
        <Helmet>
          <title>{'Target in india - Blog'}</title>
          <meta name='title' content={'Target in india - Blog'} />
          <script src={aCode.src} async></script>
          <script type='text/javascript'>{aCode.code}</script>
        </Helmet>
      )}
      <Helmet>
        <script type='application/ld+json'>
          {`{
					"@context": "http://schema.org/",
					"@type": "WebPage",
					"headline": "Target india - Blog",
					"url": "https://india.perf.target.com/blog",
				}`}
        </script>
      </Helmet>
      <div className={classes.fullWidth}>
        <Grid container className={classes.fullWidth}>
          <Grid
            item
            style={{
              width: `${100}%`,
            }}
            className={classes.postWidth}
          >
            <div className={classes.topSpace}>
              <Breadcrumb
                component={{
                  attributes: {
                    values: {
                      navigation_links: [
                        { slug: '/', name: 'Home' },
                        { name: 'Blog', active: true },
                      ],
                    },
                  },
                }}
              />
            </div>
          </Grid>
          {/* <Grid
            item
            style={{
              width: `${100}%`,
              background: desktop ? '#fff' : '#f3f2f2',
            }}
            className={classes.fullWidth}
          >
            <Info
              component={{
                attributes: {
                  values: {
                    heading: ['Responding to COVID-19'],
                    description: ['All of us at Target are working to provide you with the resources, support and information you need, as the times demand. '],
                    link: ['https://corporate.target.com/about/purpose-history/our-commitments/target-coronavirus-hub'],
                    link_label: ['Learn more'],
                  },
                },
              }}
            />
          </Grid> */}
          {desktop ? (
            <Grid
              item
              style={{
                width: `${100}%`,
              }}
              className={classes.postWidth}
            >
              <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={7}>
                  {posts && posts[0] ? (
                    <RenderCard item={posts[0]} classes={classes} styleProps={{ card: { height: '100%' }, image: { height: 450 } }} />
                  ) : (
                    <span />
                  )}
                </Grid>
                <Grid item sm={12} md={5}>
                  <Grid container spacing={6}>
                    {posts &&
                      posts.length > 0 &&
                      posts.map((post, index) => {
                        if (index === 0 || index > 3) {
                          return null;
                        } else {
                          return (
                            <Grid item xs={12} key={index}>
                              <Card className={classes.blogContainer} onClick={() => cardClick(post.url)} elevation={0}>
                                <CardActionArea style={{ display: 'flex' }}>
                                  <CardMedia
                                    className={classes.cover}
                                    image={getFeaturedImageURL(post.feature_data, false, post.feature_type)}
                                    title={
                                      post.feature_type === 'image' && post.feature_data
                                        ? getValueByAttribute(post.feature_data, 'alt')
                                        : post.feature_data
                                        ? getValueByAttribute(post.feature_data, 'alt')
                                        : ''
                                    }
                                  >
                                    <Chip label={post.category} className={classes.centerCategory} style={{ zIndex: 100 }} />
                                  </CardMedia>
                                  <CardContent className={classes.content}>
                                    <Typography variant='h3' className={classes.blogTitle}>
                                      <a className={classes.blogTitle} href={post.url}>
                                        {post.title}
                                      </a>
                                    </Typography>
                                    <Typography variant='body2' color='textSecondary' component='p' className={classes.blogDateYear}>
                                      {<Moment format={'MMMM D, YYYY'}>{post.published_time}</Moment>}
                                      <span className={classes.pipe}>&#124;</span>
                                      <FavoriteIcon className={classes.likeIcon} />
                                      <div className={classes.likeText}>{post.no_of_likes ? post.no_of_likes : '0'}</div>
                                    </Typography>
                                  </CardContent>
                                </CardActionArea>
                              </Card>
                            </Grid>
                          );
                        }
                      })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid
              item
              style={{
                width: `${100}%`,
                background: '#f3f2f2',
                paddingBottom: 50,
              }}
              className={classes.fullWidth}
            >
              <SimilarPosts posts={posts.slice(0, 4)} desktop={false} hideHeading={true} />
            </Grid>
          )}
        </Grid>
        <Grid
          item
          style={{
            width: `${100}%`,
            marginTop: desktop ? 50 : 30,
            marginBottom: desktop ? 50 : 30,
          }}
          className={classes.postWidth}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography variant={'h3'} style={{ paddingBottom: 26 }}>
                Filter by category
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <RenderFilters selectedCats={selectedCats} setSelectedCats={setSelectedCats} availableCategories={postCategories.categories} />
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              {!open && postsByCategories.inProgress && (
                <div className={classes.backDropParent}>
                  <Backdrop open={postsByCategories.inProgress} className={classes.postBackdrop}>
                    <CircularProgress color='inherit' />
                  </Backdrop>
                </div>
              )}
              {!postsByCategories.inProgress && (
                <Fragment>
                  <RenderCategorizedPosts
                    posts={postsByCategories.posts}
                    inProgress={postsByCategories.inProgress}
                    desktop={desktop}
                    selectedCats={selectedCats}
                  />
                  <div className={classes.loadMoreArea}>
                    <Button
                      variant='outlined'
                      className={postsByCategories.lastFetchCount !== 6 ? classes.hideLoadMore : classes.loadMore}
                      disableElevation
                      onClick={() => {
                        setOffset(offset + 6);
                      }}
                    >
                      View more
                      <ArrowDropDownIcon />
                    </Button>
                  </div>
                </Fragment>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  const recentPosts = idx(state.post, (_) => _.recentPosts);
  const postCategories = idx(state.post, (_) => _.postCategories);
  const postsByCategories = idx(state.post, (_) => _.postsByCategories);
  return {
    recentPosts,
    postCategories,
    postsByCategories,
  };
};

const mapDispatchToProps = { getRecentPosts, getPostCategories, getSimilarPosts, getPostsByCategories };

export default connect(mapStateToProps, mapDispatchToProps)(Blogs);
