import React, { Fragment, useState } from 'react'
import { makeStyles, Grid, Card, CardMedia, CardContent, Typography } from '@material-ui/core'
import {getValueByAttribute} from '../../Utils/DataExtract'
import idx from 'idx'
import renderHTML from 'react-render-html'

const useStyles = makeStyles((theme) => ({
    featureContainer: {
        // marginTop: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            marginTop: 0,
            paddingBottom: theme.spacing(4),
            paddingTop: theme.spacing(2),
        }
    },
    card: {
        display: 'flex',
        border: 'none',
        height: 200,
        background: 'transparent',
        position: 'relative',
        paddingLeft: 0,
        paddingRight: '46px',
        [theme.breakpoints.down('md')]: {
            paddingRight: '0px',
        },
        [theme.breakpoints.down('xs')]: {
            height: 130,
            padding: theme.spacing(0),
        }
    },
    cardWithSep: {
        display: 'flex',
        border: 'none',
        height: 200,
        background: 'transparent',
        paddingLeft: 0,
        paddingRight: '45px',
        [theme.breakpoints.up('lg')]: {
            '&::before': {
                height: 122,
                position: 'absolute',
                content: '""',
                borderLeft: '1px solid #707070',
                marginLeft: -19,
                marginTop: 38,
            }
        },

        '&::before': {
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            }
        },
        
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(0),
        },
        [theme.breakpoints.down('xs')]: {
            height: 130,
            position: 'relative',
            '&::before': {
                height: 81,
                position: 'absolute',
                content: '""',
                borderLeft: '1px solid #707070',
                right: '56%',
                top: -32,
                transform: 'rotate(90deg)',
            }
        }
    },
    lastCard: {
        display: 'flex',
        border: 'none',
        height: 200,
        background: 'transparent',
        paddingLeft: 0,
        paddingRight: 0,
        '&::before': {
            [theme.breakpoints.down('xs')]: {
                display: 'none',
        }
    },
        [theme.breakpoints.up('lg')]: {
            '&::before': {
                height: 122,
                position: 'absolute',
                content: '""',
                borderLeft: '1px solid #707070',
                marginLeft: -19,
                marginTop: 38
            }
        },
        
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(0),
        },
        [theme.breakpoints.down('xs')]: {
            height: 130,
            position: 'relative',
            '&::before': {
                height: 81,
                position: 'absolute',
                content: '""',
                borderLeft: '1px solid #707070',
                right: '56%',
                top: -32,
                transform: 'rotate(90deg)',
            }
        }
    },
    media: {
        width: '100%',
        backgroundSize: '65%',
        // [theme.breakpoints.down('md')]: {
        //     backgroundSize: '35%',
        //   },
          [theme.breakpoints.down('sm')]: {
            backgroundSize: '30%',
          },
        [theme.breakpoints.down('xs')]: {
            width: '46%',
            backgroundSize: '57%',
        }
    },
    lastCardMedia: {
        width: '70%',
        backgroundSize: '56%',
        [theme.breakpoints.down('md')]: {
            backgroundSize: '33%',
            width: '96%',
          },
          [theme.breakpoints.up('md')]: {
            backgroundSize: '65%',
          },
          [theme.breakpoints.down('sm')]: {
            backgroundSize: '30%',
          },
        [theme.breakpoints.down('xs')]: {
            width: '46%',
            backgroundSize: '49%',
        }
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        // paddingTop: '20%',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        },
        [theme.breakpoints.down('sm')]: {
            // paddingTop: '77px'
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: '27px',
            width: '75%',
        },
    },
    heading: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
        lineHeight: '1.3rem',
        textAlign: 'left',
        color: '#CC0000',

        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
            fontWeight: 'bold',
            lineHeight: '1rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem',
            lineHeight: '1.5rem',
        },
        [theme.breakpoints.down('xs')]: {
            // height: '0px',
            fontSize: '1.3rem',
            lineHeight: '1.4rem',
        }
    },
    onlyHeading: {
        fontSize: '2.5rem',
        fontWeight: 'bold',
        lineHeight: '3rem',
        textAlign: 'left',
        color: '#CC0000',
        [theme.breakpoints.down('xs')]: {
            fontSize: '2rem',
            textAlign: 'center',
            marginBottom: 10
        }
    },
    subHeading: {
        marginTop: '46px',
        fontSize: '15px',
        fontWeight: '400',
        lineHeight: '1rem',
        color: '#333333',
        textAlign: 'left',
        
        [theme.breakpoints.up('md')]: {
            marginTop: '0px !important',
            fontSize: '13px',
          },
          [theme.breakpoints.up('lg')]: {
            marginTop: '5px !important',
            // marginTop: '46px !important',
          },
        [theme.breakpoints.down('md')]: {
            // fontSize: '20px',
            marginTop: '5px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '21px',
            lineHeight: '1.5em',
        },
        [theme.breakpoints.down('xs')]: {
            // marginTop: '10px',
            // fontSize: '17px',
            // lineHeight: '18px',
            marginTop: '5px',
            fontSize: '16px',
            lineHeight: '18px',
        }
    },
    lastsubHeading:{
        marginTop: '5px',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '1rem',
        color: '#333333',
        textAlign: 'left',
      
        [theme.breakpoints.down('md')]: {
            fontSize: '20px',
            marginTop: '16px',
            lineHeight: '1.5rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '13px !important',
            marginTop: '5px !important',
            lineHeight: '1rem',
          },
        [theme.breakpoints.down('xs')]: {
            marginTop: '5px',
            fontSize: '16px',
            lineHeight: '18px',
        }
    },
    seperator: {
        position: 'absolute'
    },
    contentArea: {
        padding: '0 !important',
        [theme.breakpoints.down('xs')]: {
            paddingBottom: '24px !important',
        },
    },
    tSubHeading: {
        fontSize: '1rem',
        textAlign: 'left',
        [theme.breakpoints.down('xs')]: {
        }
    }
}))

const ShowCaseFour = props => {
    const { component, desktop } = props
    const values = idx(component, (_) => _.attributes.values)
    const color = getValueByAttribute(values, 'color')
    const cellWidth = component.active ? 3 : 4;
    const features = getValueByAttribute(values, 'features', true)
    const classes = useStyles()
    const [variant] = useState(getValueByAttribute(features[0], 'icon') ? 'image' :'text')
    return (
        <Fragment>
            <Grid container className={classes.featureContainer}>
                {features && features.length > 0 && features.map((feature, index) => {
                    const iconImage = getValueByAttribute(feature, 'icon')
                    const heading = getValueByAttribute(feature, 'heading') ? getValueByAttribute(feature, 'heading') : ''
                    const description = getValueByAttribute(feature, 'description')
                    return (
                        <Grid item md={cellWidth} xs={12} key={index}>
                            {variant === 'image' ? (
                                <Card className={index === 3 ? classes.lastCard : (index > 0 ? classes.cardWithSep : classes.card)} elevation={0}>
                                    {iconImage && <CardMedia image={iconImage} className={index === 3 ? classes.lastCardMedia : classes.media} />}
                                    <div className={classes.details}>
                                        <CardContent className={classes.contentArea}>
                                            <Typography style={{color: `${color}`}} className={classes.heading}>{renderHTML(heading)}</Typography>
                                            {description && <Typography className={index === 3 ?  classes.lastsubHeading : classes.subHeading}>{description}</Typography>}
                                        </CardContent>
                                    </div>
                                </Card>
                            ) : (
                                <Card className={index === 3 ? classes.lastCard : (index > 0 ? classes.cardWithSep : classes.card)} elevation={0}>
                                    <Grid container alignContent={'center'} alignItems={'center'} spacing={desktop ? 0 : 4}>
                                        <Grid item xs={3} md={3}>
                                            <Typography className={classes.onlyHeading}>{renderHTML(heading)}</Typography>
                                        </Grid>
                                        <Grid item xs={9} md={9}>
                                        {description && <Typography className={classes.tSubHeading}>{description}</Typography>}
                                        </Grid>
                                    </Grid>
                            </Card>
                            )}
                        </Grid>
                    )
                })}
            </Grid>
        </Fragment>
    )
}

export default ShowCaseFour
