import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  Link,
  Card,
  CardContent,
  IconButton
} from '@material-ui/core'
import targetLogo from '../../../images/targetLogo.svg'
import Carousel from 'nuka-carousel'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import classNames from 'classnames'
import axios from 'axios'
import { getValueByAttribute } from '../../Utils/DataExtract'
import idx from 'idx'

// import iglogo from '../../../assets/ig-logo.png'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary
  },
  storyLink: {
    marginTop: 30,
    borderRadius: 35,
    color: '#9e4446',
    borderColor: '#9e4446'
  },
  headerText: {
    textAlign: 'center'
  },
  pagingDots: {
    top: '30px !important',
    [theme.breakpoints.down('md')]: {
      width: 400,
      overflow: 'hidden'
    },
    [theme.breakpoints.down('xs')]: {
      width: 200,
      overflow: 'hidden'
    }
  },
  previousButton: {
    marginLeft: -9,
    // marginLeft: 60,
    border: '1px solid',
    color: '#000',
    background: '#fff',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
    '&:hover': {
      background: 'rgba(256,256,256, 0.7)'
    }
  },
  nextButton: {
    marginRight: -9,
    border: '1px solid',
    color: '#000',
    background: '#fff',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
    '&:hover': {
      background: 'rgba(256,256,256, 0.7)'
    }
  },
  instaStripContainer: {
    width: '89vw',
    paddingTop: theme.spacing(1),
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      paddingTop: '20px'
    }
  },
  bottomText: {
    fontSize: '1.2rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem'
    }
  },
  instaID: {
    color: '#333'
  },
  instagramFeedImg:
  {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    minHeight: 306,
    cursor: 'pointer',
  },
}))

const DemoItem = (props) => (
  <Card className='cardmedia-img' style={{ borderRadius: '0px' }}>
    <Link href={props.data.link} target={'__blank'}>
      <CardContent
        className={props.instagramClass}
        alt={props.data && props.data.caption ? props.data.caption : ''}
        style={{
          backgroundImage: `url(${props.data.element})`,
          backgroundSize: props.data.backgroundSize
        }}>
      </CardContent>
    </Link>
  </Card>
)

export default function InstagramFeed(props) {
  const { component, desktop } = props
  const values = idx(component, (_) => _.attributes.values)

  const classes = useStyles()
  const [igImageArray, setigImageArray] = useState([1])
  const carouselProps = {
    cellSpacing: desktop ? 20 : 60,
    wrapAround: desktop ? true : true,
    cellAlign: 'left',
    slidesToShow: desktop
      ? Math.round(((window.innerWidth / 100) * 80) / 360)
      : 1,
    edgeEasing: 'easeLinear',
    enableKeyboardControls: true,
    autoplay: false,
    framePadding: desktop ? '48px' : '20px',
    transitionMode: desktop ? 'scroll' : 'scroll3d',
    slideOffset: 0,
    // scrollMode: 'page',
    renderCenterLeftControls: ({ previousSlide }) => (
      <IconButton
        aria-label='Previous Slide'
        className={classes.previousButton}
        onClick={previousSlide}
      >
        <NavigateBeforeIcon className={classes.previousIcon} />
      </IconButton>
    ),
    renderCenterRightControls: ({ nextSlide }) => (
      <IconButton
        aria-label='Next Slide'
        className={classes.nextButton}
        onClick={nextSlide}
      >
        <NavigateNextIcon className={classes.nextIcon} />
      </IconButton>
    ),
    defaultControlsConfig: {
      pagingDotsContainerClassName: classNames(classes.pagingDots)
    }
  }

  if (!desktop) {
    carouselProps.animation = 'zoom'
  }

  useEffect(() => {
    axios
      .get(
        'https://api.massrelevance.com/4z1h2dawz9/team-target-in-india.json?tweet_mode=extended'
      )
      .then(function (response) {
        // handle success

        let instagramAPI = response.data

        let igImageArrayVar = []

        for (let index = 0; index < instagramAPI.length; index++) {
          const element = instagramAPI[index].images.standard_resolution.url
          const link = instagramAPI[index].link
          const caption = instagramAPI[index].caption.text
          // const img = new Image()
          let img = document.createElement('img');
          img.src = element
          let backgroundSize = 'cover'
          if (img.height < img.width) {
            backgroundSize = '100%'
          }
          igImageArrayVar.push({ element, link, caption, backgroundSize })
        }
        setigImageArray(igImageArrayVar)
      })
      .catch(function (error) {
        // handle error
      })
      .then(function () {
        // always executed
      })
  }, [])

  return (
    <div className='instagram-feed'>
      <div className='ig-id-logo-container'>
        <div className={classes.departmentHeadingContainer}>
          <Typography className={classes.headerText} variant={'h3'}>
            {getValueByAttribute(values, 'primary_heading')}
          </Typography>
        </div>
        <span>
          <img src={targetLogo} style={{ height: '50px' }} alt='' />
          <Link
            target='_blank'
            href={getValueByAttribute(values, 'instagram_url')}
            className={classes.instaID}
          >
            <p className='insta-id'>
              {getValueByAttribute(values, 'instagram_id')}
            </p>
          </Link>
        </span>
      </div>

      <div className={classes.instaStripContainer}>
        <Carousel {...carouselProps}>
          {igImageArray.map((igImg, index) => (
            <DemoItem key={index} data={igImg} instagramClass={classes.instagramFeedImg} />
          ))}
        </Carousel>
      </div>
    </div>
  )
}
