import React, { Fragment } from 'react'
import {
  makeStyles,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography
} from '@material-ui/core'
import { Link as RLink, useHistory } from 'react-router-dom'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { getValueByAttribute } from '../../Utils/DataExtract'
import idx from 'idx'

const useStyles = makeStyles((theme) => ({
  gridCards: {
    padding: theme.spacing(7),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(4),
    overflow: 'hidden',
    backgroundColor: '#ffffff',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2)
    }
  },
  heading: {
    fontWeight: 'bold',
    lineHeight: '3rem',
    color: '#454545',
    textAlign: 'center',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(2)
    }
  },
  tileLink: {
    width: '100%',
    color: '#CC0000',
    textDecoration: 'none',
    textTransform: 'none',
    '&:hover': {
      textDecoration: 'underline'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 13
    }
  },
  cardHeading: {
    fontSize: '1.4rem',
    color: '#333333',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      lineHeight: '4rem'
    }
  },
  cardContent: {
    fontSize: '1rem',
    color: '#333333',
    paddingBottom: 10,
    textAlign: 'center'
  },
  cardContentArea: {
    minHeight: 200
  },
  media: {
    height: 300
  },
  card: {
    height: '100%'
    // cursor: 'pointer'
  },
  link: {
    textAlign: 'center',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2)
    }
  },
  linkButton: {
    border: '1px solid',
    color: '#CC0000',
    borderRadius: 20,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    textDecoration: 'none',
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    }
  },
  rightArrow: {
    verticalAlign: 'middle'
  }
}))

const HiringGridCard = (props) => {
  const { component } = props
  const values = idx(component, (_) => _.attributes.values)
  const { items } = values

  // const styling = {
  //   card: {},
  //   heading: {
  //     textAlign: 'center'
  //   },
  //   content: {
  //     textAlign: 'center'
  //   },
  //   media: {
  //     height: 150,
  //     backgroundSize: 'auto'
  //   }
  // }
  const classes = useStyles()
  const history = useHistory()

  const cardClick = (slug) => {
    if (slug) {
      history.push(slug)
    }
  }

  return (
    <Fragment>
      <div className={classes.gridCards}>
        {getValueByAttribute(values, 'heading') && (
          <Typography variant={'h3'} className={classes.heading}>
            {getValueByAttribute(values, 'heading')}
          </Typography>
        )}
        {items && items.length > 0 && (
          <Grid
            container
            className={classes.dataContainer}
            spacing={
              getValueByAttribute(values, 'spacing')
                ? getValueByAttribute(values, 'spacing')
                : 2
            }
            justify='center'
            alignItems='center'
          >
            {items.map((item, key) => {
              return (
                <Grid
                  item
                  xs={12}
                  md={item.width ? item.width : 6}
                  key={key}
                  onClick={() => cardClick(item.slug)}
                >
                  <Card
                    className={classes.card}
                    elevation={getValueByAttribute(values, 'elevation')}
                    style={{
                      ...(item.slug ? { cursor: 'pointer' } : {})
                    }}
                  >
                    <CardMedia
                      className={classes.media}
                      image={item.image}
                      title={item.heading}
                    />
                    <CardContent className={classes.cardContentArea}>
                      <Typography
                        variant={'h3'}
                        className={classes.cardHeading}
                      >
                        {item.heading}
                      </Typography>
                      <Typography
                        variant='body2'
                        color='textSecondary'
                        component='p'
                        className={classes.cardContent}
                      >
                        {item.content}
                      </Typography>
                      {item.slug && (
                        <RLink className={classes.tileLink} to={item.slug}>
                          {item.linkText}
                        </RLink>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              )
            })}
          </Grid>
        )}
        {getValueByAttribute(values, 'link_to_page_slug') && (
          <div className={classes.link}>
            <RLink
              className={classes.linkButton}
              to={getValueByAttribute(values, 'link_to_page_slug')}
            >
              {getValueByAttribute(values, 'link_to_page_label')}
              <ArrowRightIcon className={classes.rightArrow} />
            </RLink>
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default HiringGridCard
