import { FETCH_SITE_CONF_IN_PROGRESS, FETCH_SITE_CONF_FAILED, FETCH_SITE_CONF_SUCCESS, SEARCH_IN_PROGRESS, SEARCH_SUCCESS, SEARCH_FAILED } from './actionType'

export const initialState = {
  getConfiguration: {
    inProgress: false,
    data: null,
    error: null
  },
  search: {
    currentKeyword: '',
    inProgress: false,
    noOfResults: 0,
    formattedResults: [],
    error: null
  }
}

export default function configurationReducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_SITE_CONF_IN_PROGRESS: {
      const newState = {
        ...state,
        getConfiguration: {
          ...state.getConfiguration,
          inProgress: true
        },
      }
      return newState
    }

    case FETCH_SITE_CONF_SUCCESS: {
      const newState = {
        ...state,
        getConfiguration: {
          ...state.getConfiguration,
          inProgress: false,
          data: action.data,
          error: null
        },
      }
      return newState
    }

    case FETCH_SITE_CONF_FAILED: {
      const newState = {
        ...state,
        getConfiguration: {
          ...state.getConfiguration,
          inProgress: false,
          data: null,
          error: action.error
        },
      }
      return newState
    }

    case SEARCH_IN_PROGRESS: {
      const newState = {
        ...state,
        search: {
          ...state.search,
          currentKeyword: action.keyword,
          inProgress: true,
        }
      }
      return newState
    }

    case SEARCH_SUCCESS: {
      const newState = {
        ...state,
        search: {
          ...state.search,
          currentKeyword: action.keyword,
          inProgress: false,
          error: null,
          noOfResults: action.noOfResults,
          formattedResults: action.formattedResults,
        }
      }
      return newState
    }

    case SEARCH_FAILED: {
      const newState = {
        ...state,
        search: {
          ...state.search,
          currentKeyword: action.keyword,
          inProgress: false,
          error: action.error,
          noOfResults: 0,
          formattedResults: [],
        }
      }
      return newState
    }

    default:
      return state
  }
}
