import React, { useEffect, Fragment, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import {
	makeStyles,
	Typography,
	Grid,
	Button,
	useMediaQuery
} from '@material-ui/core'
import { Breadcrumb } from '../ComponentsList/Components'
import { useLocation, useHistory } from 'react-router-dom'
import { getTagPosts } from '../../store/post/actionCreator'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import apiConfig from '../../apiConfig'
import { Helmet } from 'react-helmet'
import _ from 'lodash'
import idx from 'idx'
import RenderPostItem from './RenderPostItem'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

const { preview } = apiConfig

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	fullWidth: {
		width: '100%'
	},
	postWidth: {
		paddingLeft: theme.spacing(10),
		paddingRight: theme.spacing(10),
		[theme.breakpoints.down('md')]: {
			paddingLeft: theme.spacing(5),
			paddingRight: theme.spacing(5)
		},
		[theme.breakpoints.down('xs')]: {
			padding: 0,
			paddingLeft: theme.spacing(4),
			paddingRight: theme.spacing(4)
		}
	},
	topSpace: {
		paddingTop: theme.spacing(3),
		[theme.breakpoints.down('md')]: {
			paddingTop: theme.spacing(3)
		},
		[theme.breakpoints.down('xs')]: {
			paddingTop: theme.spacing(2)
		}
	},
	text: {
    color: '#888888',
    paddingBottom: theme.spacing(3)
  },
	button: {
    borderRadius: theme.spacing(4),
    textTransform: 'none'
	},
	loadMoreArea: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: theme.spacing(3)
	},
	loadMore: {
		display: 'block',
		marginTop: 30,
    borderRadius: 35,
    color: '#CC0000',
    borderColor: '#CC0000',
    fontSize: 16,
    textTransform: 'none',
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      marginTop: 10,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      marginTop: 26,
    },
	},
	hideLoadMore: {
		display: 'none'
	},
}))

const Tags = (props) => {
	const classes = useStyles()
	const {dispatch} = props
	const { pathname } = useLocation()
	const pathList = pathname.split('/')
	const tag = pathList[pathList.length - 1]
	const byTag = useSelector((state) => state.post.byTag)
	const configurations = useSelector((state) => state.configuration.getConfiguration.data)
	const analytics = _.find(configurations, { name: 'analytics_code' })
	const aCode = idx(analytics, (_) => _.published_value)
	const { posts, inProgress, lastFetchCount } = byTag
	const [offset, setOffset] = useState(0)
	const history = useHistory()
	const desktop = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const goHome = () => {
    history.push('/blog')
	}
	const increment = 3

	useEffect(() => {
		dispatch(getTagPosts(tag, offset, increment))
		setOffset(0)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if(offset) {
			dispatch(getTagPosts(tag, offset, increment))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [offset])

	return (
		<Fragment>
			<Backdrop
				className={classes.backdrop}
				open={ inProgress && offset === 0 }
			>
				<CircularProgress color='inherit' />
			</Backdrop>
			{!preview && aCode && aCode.code && aCode.src && (
				<Helmet>
					<title>{'Target in india - Blog'}</title>
					<meta
						name='title'
						content={'Target in india - Blog'}
					/>
					<script src={aCode.src} async></script>
					<script type="text/javascript">
						{aCode.code}
					</script>
				</Helmet>
			)}
			<div className={classes.fullWidth}>
				<Grid
					container
					className={classes.fullWidth}
				>
					<Grid
						item
						style={{
							width: `${100}%`,
						}}
						className={classes.postWidth}
					>
						<div className={classes.topSpace}>
							<Breadcrumb component={{ attributes: { values: { navigation_links: [{ slug: '/', name: 'Home' }, { name: 'Blog', slug: '/blog' }, { name: `${tag}` , active: true}] } } }} />
						</div>
					</Grid>
					<Grid
						item
						style={{
							width: `${100}%`,
						}}
						className={classes.postWidth}
					>
						<Typography variant={'h3'}>{tag}</Typography>
						<Grid container spacing={desktop ? 8 : 4}>
							{(!posts[tag] || posts[tag].length === 0) && !inProgress && (
								<Grid item xs={12} sm={12} md={12}>
									<Typography variant={'h4'} className={classes.text}>{`No articles found!`}</Typography>
									<Button variant={'outlined'} onClick={goHome} className={classes.button}>Go back to blog</Button>
								</Grid>
							)}
							{(posts[tag] && posts[tag].length > 0) && posts[tag].map((post, index) => {
								return (
									<Grid key={index} item xs={12} sm={12} md={6}>
										<RenderPostItem post={post} />
									</Grid>
								)
							})}
							{!inProgress && (
								<Grid item xs={12} sm={12} md={12}>
									<div className={classes.loadMoreArea}>
										<Button
											variant='outlined'
											className={lastFetchCount !== increment ? classes.hideLoadMore : classes.loadMore}
											disableElevation
											onClick={() => {
												setOffset(offset + increment)
											}}
										>
											View more
											<ArrowDropDownIcon />
										</Button>
									</div>
								</Grid>
							)}
						</Grid>
					</Grid>
				</Grid>
			</div>
		</Fragment>
	)
}

export default connect()(Tags)
