import React, { Fragment, useRef } from 'react'
import { makeStyles,Grid } from '@material-ui/core'
import Fade from '@material-ui/core/Fade'
import idx from 'idx'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles((theme) => ({
    videoElement: {
        width: '100%',
        height: '100%',
        maxHeight: '487px',
        [theme.breakpoints.down('xs')]: {
            padding: 0,
            width: '100%',
            // height: '298px',
        }
    },
    model: {
        width: '75%',
        [theme.breakpoints.down('sm')]: {
            width: '93%',
        }
    },
    closeButton: {
        top: 0,
        right: 0,
        position: 'absolute',
        zIndex: 2,
        color: '#333333',
        // background: 'rgba(0,0,0,0.2)',
        '&:focus': {
            // outline: '1px dotted'
        },
        [theme.breakpoints.down('sm')]: {
            color: '#fff',
            top: -30,
            right: -30,
        }
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // boxShadow: theme.shadows[5],
        position: 'relative',
        height: '545px',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            height: 'auto',
            marginLeft: '5%',
        }
    },
    skipLink: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        width: 1,
        color: '#333333',
        position: 'absolute',
        '&:focus': {
            clip: 'auto',
            height: 33,
            bottom: 35,
            right: 0,
            width: 'auto',
            margin: 0,
            zIndex: 1500,
            outline: 0,
            textDecoration: 'underline',
            border: '1px dashed #d3d3d3',
            padding: 3,
        }
    }
}))


const VideoComponent = (props) => {
    const { component } = props
    const mobile = useMediaQuery((theme) => theme.breakpoints.down("sm"))
    const values = idx(component, (_) => _.attributes.values)
    const { open, video } = props
    const classes = useStyles()
    const ref = useRef()
    const playError = (error) => {
        ref.current.currentTime = 0;
        ref.current.load();
    }
    return (
        <Grid
        container
        spacing={0}
        className={classes.container}
        style={{width:'100%',padding:'0'}}
      >
                <Grid md={12} style={{width:'100%',padding:'0'}}>
                        <Fade in={open}>
                            <Fragment>

                                <div>
                                    <video playsInline className={classes.videoElement} autoPlay poster={mobile ? values.cover_image_mob :(values.cover_image ? values.cover_image: '')} crossOrigin="anonymous" controls={true} disablePictureInPicture controlsList="nodownload" onError={playError} ref={ref}>
                                            <source src={values.source_link && values.source_link[0] ? values.source_link[0] : ''} />
                                            {/* {video.tracks && video.tracks.length > 0 && (
                                                <track label="English" kind="captions" srcLang="en" src={video.tracks[0]} />
                                            )} */}
                                        </video>
                                    {video && video &&
                                        <video playsInline className={classes.videoElement} autoPlay poster={video.cover_image ? video.cover_image : ''} crossOrigin="anonymous" controls={true} disablePictureInPicture controlsList="nodownload" onError={playError} ref={ref}>
                                            <source src={values.source_link && values.source_link[0] ? video.source_link[0] : ''} />
                                            {video.tracks && video.tracks.length > 0 && (
                                                <track label="English" kind="captions" srcLang="en" src={video.tracks[0]} />
                                            )}
                                        </video>
                                    }
                                    {/* {video.transcript && video.transcript[0] && (
                                        <div>
                                            <Link href={video.transcript[0]} target="_blank" className={classes.skipLink} aria-label="Download transcript"><GetAppIcon /></Link>
                                        </div>
                                    )} */}
                                </div>
                            </Fragment>
                        </Fade>
                </Grid>
                </Grid>
    )
}

export default VideoComponent