import React, { useState }  from 'react'
import { makeStyles, List } from '@material-ui/core'
import idx from 'idx'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { HashLink as RLink } from 'react-router-hash-link'
import { motion } from "framer-motion"
import FindInPageOutlined from '@material-ui/icons/FindInPageOutlined'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  listItem:{
    backgroundColor: '#666',
    color: '#fff',
    display: 'inline-block',
    textAlign: 'center',
    padding: '8px 21px',
    textDecoration: 'none',
    borderRadius: '17px',
    marginBottom: '10px',
    fontWeight: '400', 

    '&:hover': {
      color: '#fff !important',
      textDecoration: 'none',
    },
   },
   mobMenuButton:{
     padding: '0.6em',
     backgroundColor: '#cc0000 !important',
     color: '#fff',
     boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',

     '&:focus': {
      outline: '1px dotted white !important',
    }
   },
    container:{
      zIndex: '1000',
      position: 'fixed',
      bottom: '110px',
      right: '15px',
      textAlign:'right',
    },
    scrollmenu: {
        overflow: 'auto',
        whiteSpace: 'nowrap',
        boxShadow: '0px 14px 4px -15px #666',
      },
      noDisplay:{
          display: 'none',
      },
      scrollmenuItem: {
        display: 'inline-block',
        color: '#777',
        textAlign: 'center',
        padding: '14px',
        textDecoration: 'none',
      
    }
}))

const TargetTechStickyNav = (props) => {
    const classes = useStyles()
    const mobile = useMediaQuery((theme) => theme.breakpoints.down("sm"))
    const { component } = props
    const values = idx(component, (_) => _.attributes.values)
    const menu_items = values.nav_menu_item[0].nav_menu_item
    const [expanded, setExpaned] = useState(false)

    const toggleMenu = (incoming) => {
      setExpaned(expanded === incoming ? '' : incoming)
  }

    const scrollWithOffset = (el) => {
      const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
      const yOffset = -90;
      window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  }
    return (     
      <div className={classes.container}>
        {expanded && (
        <List >
            {menu_items.map((item,index) => (
             
              
                  <motion.li
                  animate={{ opacity: 1, y: 0 }}
                  initial={{ opacity: 0, y: 20 }}
                  exit={{ opacity: 1, y: -20 }}
                  transition={{ ease: "easeOut", duration: 0.5 }}                  
                  i={index}
                  key={index}
              ><RLink to={`#${item.split(" ")[0].toLowerCase()}`}  onClick={() => toggleMenu(false)} scroll={(el) => scrollWithOffset(el)} className={classes.listItem}>{item}</RLink>
              </motion.li>
            ))} 
             </List>
        )}
        <IconButton aria-label={expanded ? 'close menu' : 'open menu'} onClick={() => toggleMenu(true)} aria-expanded={expanded}  className={!mobile ? classes.noDisplay : classes.mobMenuButton}>{expanded ? <CloseIcon style={{fontSize:'2rem'}}/> : <FindInPageOutlined style={{fontSize:'2rem'}}/>}</IconButton>
        </div>
    )
}

export default TargetTechStickyNav