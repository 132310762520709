import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import ShowMore from '@tedconf/react-show-more'
import { Link as RLink } from 'react-router-dom'
import { Button, Typography, Card, CardMedia, Link } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { motion, useAnimation } from 'framer-motion'
import { TileZoomEffects } from '../../Utils/AnimationEffects'
import { useInView } from 'react-intersection-observer'
import { useHistory } from 'react-router-dom'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { getValueByAttribute } from '../../Utils/DataExtract'
import idx from 'idx'
import renderHTML from 'react-render-html'

const useStyles = makeStyles((theme) => ({
  tilesMainArea: {
    padding: 30,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 20
    }
  },
  headerText: {
    textAlign: 'center',
  },
  subText: {
    textAlign: 'center',
  },
  storyLink: {
    marginTop: 30,
    borderRadius: 35,
    color: '#CC0000',
    borderColor: '#CC0000',
    fontSize: 16,
    textTransform: 'none',
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      marginTop: 28,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      marginTop: 26,
    },
  },
  storyLinkHidden: {
    display: 'none',
  },
  gridText: {
    fontSize: '1.25rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.37rem',
    }
  },
  titleArea: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  },
  card: {
    background: '#D0D2D3',
    transition: 'background 1.5s, color 1.5s',
    '&:hover': {
      // background: '#333333',
      // color: '#ffffff',
    },
    '&:hover h3': {
      // color: '#ffffff',
    },
    [theme.breakpoints.up('sm')]: {
      '&:hover img': {
        filter: 'grayscale(0%) !important'
      },
      '& img': {
        filter: 'grayscale(100%) !important'
      }
    }
  },
  tilesArea: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 0,
    }
  },
  contentArea: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    }
  },
  tileLink: {
    width: '100%',
    color: '#CC0000',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 13
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
    }
  },
  content: {
    // fontSize: '0.9rem',
    lineHeight: '1.6rem'
  },
  tileContainer: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '30px !important'
    }
  },
  linkCard: {
    height: '18vw',
    background: '#cc0000',
    color: '#fff',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      height: '25vw',
    },
    [theme.breakpoints.down('sm')]: {
      height: '40vw',
    },
    [theme.breakpoints.down('xs')]: {
      height: '70vw',
    }
  },
  externalLinkText: {
    color: '#fff',
    width: '100%',
    fontWeight: 'bold',

  },
  link: {
    textAlign: 'center',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    }
  },
  linkButton: {
    border: '1px solid',
    color: '#CC0000',
    borderRadius: 20,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    textDecoration: 'none',
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    }
  },
  rightArrow: {
    verticalAlign: 'middle'
  },
  departmentViewmoreContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
}))

const Tiles = (props) => {
  const { items, maxNoItems, desktop } = props
  const classes = useStyles()
  const controls = useAnimation()
  const history = useHistory()
  const [ref, inView] = useInView({ triggerOnce: false });

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        controls.start("visible");
      }, 500)
    }
  }, [controls, inView])

  const handleCardClick = (tile) => {
    history.push(tile.slug)
  }

  let animationProps = {
    animate: TileZoomEffects.direct.animate,
    initial: TileZoomEffects.direct.initial,
    transition: TileZoomEffects.direct.transition,
  }

  if (desktop) {
    animationProps.whileHover = { scale: 1.05 }
    animationProps.whileTap = { scale: 0.9 }
  }

  return (
    <>
      <div className={classes.tilesArea} ref={ref}>
        <ShowMore items={items} by={maxNoItems ? maxNoItems : 4}>
          {({ current, onMore, all, t, s }) => (
            <>
              <Grid container spacing={desktop ? 6 : 3}>
                {current.map((item, key) => (
                  <Grid key={key} item xs={12} md={4} sm={6} lg={3} className={classes.tileContainer}>
                    <motion.div
                      {...animationProps}
                    >
                      <Card className={classes.card} style={{ cursor: 'pointer' }}>
                        <CardMedia
                          component='img'
                          style={{
                            minHeight: '100%',
                            minWidth: '100%',
                          }}
                          alt={getValueByAttribute(item, 'alt_text_of_featured_image')}
                          image={
                            getValueByAttribute(item, 'featured_image')
                          }
                          onClick={() => handleCardClick(getValueByAttribute(item, 'department_page_link'))}
                        />
                        <div className={classes.titleArea}>
                          <Typography variant={'h3'} className={classes.gridText}>
                            {getValueByAttribute(item, 'name')}
                          </Typography>
                        </div>
                      </Card>
                    </motion.div>
                    {getValueByAttribute(item, 'description') && (
                      <div className={classes.contentArea}>
                        <Typography className={classes.content}>{getValueByAttribute(item, 'description')}</Typography>
                      </div>
                    )}
                    {getValueByAttribute(item, 'department_page_link') && (
                      <RLink to={getValueByAttribute(item, 'department_page_link').slug} className={classes.tileLink}>{'Explore'}</RLink>
                    )}
                  </Grid>
                ))}
              </Grid>
              <div className={classes.departmentViewmoreContainer}>
                <Button
                  variant='outlined'
                  className={!onMore ? classes.storyLinkHidden : classes.storyLink}
                  disableElevation
                  onClick={() => {
                    if (!!onMore) onMore()
                  }}
                >
                  View more
                  <ArrowDropDownIcon />
                </Button>
              </div>
            </>
          )}
        </ShowMore>

      </div>
    </>
  )
}

const DepartmentGrid = (props) => {
  const { component, desktop } = props
  const values = idx(component, (_) => _.attributes.values)
  const classes = useStyles()
  const departments = getValueByAttribute(values, 'departments', true)
  const maxNoItems = getValueByAttribute(values, 'maximum_no_of_visible_items') ? parseInt(getValueByAttribute(values, 'maximum_no_of_visible_items')) : 4
  return (
    <div className={classes.tilesMainArea}>
      <div className={classes.departmentHeadingContainer}>
        <Typography className={classes.headerText} variant={'h3'}>
          {getValueByAttribute(values, 'heading')}
        </Typography>
        {getValueByAttribute(values, 'description') &&
          <div className={classes.subText}>
            {renderHTML(getValueByAttribute(values, 'description'))}
          </div>
        }
      </div>
      <div>
        <Tiles items={departments} maxNoItems={maxNoItems} desktop={desktop} />
      </div>
      {getValueByAttribute(values, 'external_url') && (
        <div className={classes.link}>
          <Link className={classes.linkButton} href={getValueByAttribute(values, 'external_url')} target="_blank">{getValueByAttribute(values, 'button_label')}<ArrowRightIcon className={classes.rightArrow} /></Link>
        </div>
      )}
    </div>
  )
}

export default DepartmentGrid