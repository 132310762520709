import { combineReducers } from 'redux'
import configuration from './configuration/reducer'
import page from './page/reducer'
import post from './post/reducer'

const rootReducer = combineReducers({
  configuration,
  page,
  post,
})

export default rootReducer
