import React, {Fragment} from 'react'
import {makeStyles, Grid, Typography, Divider} from '@material-ui/core'
import {getValueByAttribute} from '../../Utils/DataExtract'
import idx from 'idx'
import renderHTML from 'react-render-html'

const useStyles = makeStyles((theme) => ({
	gridCards: {
		paddingTop: theme.spacing(5),
		paddingBottom: theme.spacing(5),
		[theme.breakpoints.down("md")]: {
			padding: theme.spacing(2)
		}
	},
	dividerStyle: {
		border: '2px solid #CC0000',
		marginLeft: 5,
		width: 90,
		height: 0,
		[theme.breakpoints.down('xs')]: {
			border: '2px solid #CC0000',
			marginLeft: 2
		}
	},
	textBox: {
		paddingTop: theme.spacing(0),
		[theme.breakpoints.down('xs')]: {
			paddingTop: theme.spacing(0),
			paddingBottom: theme.spacing(3) + 'px !important',
			marginTop: '0 !important'
		}
	},
	headingStyle: {
		color: '#333333',
		textAlign: 'left',
		fontWeight: 'bold',
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.25rem',
			paddingBottom: theme.spacing(2) + 'px !important',
		}
	},
	descStyle: {
		color: '#333333',
		textAlign: 'left',
		paddingTop: 25,
		letterSpacing: 0,
		fontSize: '1rem',
		[theme.breakpoints.down('xs')]: {
			paddingTop: 10,
			fontSize: '1rem',
			lineHeight: '30px',
		},
		'& p': {
			marginTop: theme.spacing(3),
			marginBottom: theme.spacing(3),
			color: '#333333'
		}
	},
	heading: {
		fontWeight: 'bold',
		lineHeight: '3rem',
		color: '#454545',
		textAlign: 'left',
		marginBottom: theme.spacing(5),
		[theme.breakpoints.down('xs')]: {
			fontWeight: 'bold',
			marginBottom: theme.spacing(2),
		}
	},
	tileLink: {
		width: '100%',
		color: '#CC0000',
		textDecoration: 'none',
		textTransform: 'none',
		'&:hover': {
			textDecoration: 'underline',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: 13
		},
	},
	cardHeading: {
		fontSize: '1.4rem',
		color: '#333333'
	},
	cardContent: {
		fontSize: '1rem',
		color: '#333333',
		paddingBottom: 10
	},
	iBlock: {
		[theme.breakpoints.down('xs')]: {
			paddingLeft: '0 !important'
		}
	}
}))

const RichTextGrid = props => {
	const {component, desktop} = props
	const values = idx(component, (_) => _.attributes.values)
	const grid = getValueByAttribute(values, 'text_grid_box',true)
	const classes = useStyles()

	return (
		<Fragment>
			<div className={classes.textBox}>
				{getValueByAttribute(values, 'heading') && <Typography className={classes.headingStyle} variant={"h3"}>{getValueByAttribute(values, 'heading')}</Typography>}
				{getValueByAttribute(values, 'description') && <div className={classes.descStyle}>{renderHTML(getValueByAttribute(values, 'description'))}</div>}
			</div>
			<div className={classes.gridCards}>
				{grid && grid.length > 0 && (
					<Grid container className={classes.dataContainer} spacing={desktop ? 4 : 1}>
						{grid.map((item, key) => {
							return (
								<Grid className={classes.iBlock} item xs={12} md={parseInt(getValueByAttribute(values, 'columns'))} key={key}>
									{getValueByAttribute(item, 'grid_text_box_header') && (
										<Typography
											className={classes.headingStyle}
											variant={'h4'}
										>
											{getValueByAttribute(item, 'grid_text_box_header')}
										</Typography>
									)}
									{getValueByAttribute(values, 'divider') && (<Divider className={classes.dividerStyle} />)}
									{getValueByAttribute(item, 'grid_text_box_description') && (
										<div className={classes.descStyle} >
											{renderHTML(getValueByAttribute(item, 'grid_text_box_description'))}
										</div>
									)}
								</Grid>
							)
						})}


					</Grid>
				)
				}
			</div>
		</Fragment>
	)
}

export default RichTextGrid
