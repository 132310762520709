import React, { Fragment, useEffect, useState } from 'react';
import HeaderSection from './HeaderSection';
import FooterSection from './FooterSection';
import { connect, useSelector } from 'react-redux';
import { createMuiTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { getSiteConfiguration } from '../../store/configuration/actionCreator';
import { getPageData, getVersionData, openSearchBox } from '../../store/page/actionCreator';
import { getPostData } from '../../store/post/actionCreator';
import _ from 'lodash';
import { Route, Switch } from 'react-router-dom';
import PageContainer from '../PageContainer/PageContainer';
import BlogContainer from '../Blog/BlogContainer';
import NotFound from '../NotFound/NotFound';
import { useLocation, useHistory } from 'react-router-dom';
import isValid from 'is-valid-path';
import HelvetivaForTargetNormal from '../../fonts/HelveticaForTarget/Medium/b7489e1d-53c6-414e-a325-5adf8e204b8d.woff2';
import { getQueryParamByKey } from '../Utils/DataExtract';
import Blogs from '../Blog/Blogs';
import Tags from '../Blog/Tags';
import Category from '../Blog/Category';
import SearchResult from '../Blog/SearchResult';
import VideoInFullPage from '../ComponentsList/VideoComponent/VideoInFullPage';

const targeticaFont = {
  fontFamily: 'HelveticaForTarget',
  // fontWeight: 'normal',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `
    local('HelveticaForTarget'),
    url(${HelvetivaForTargetNormal}) format('woff2'),
  `,
};

let siteTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#fff',
    },
  },
  typography: {
    fontFamily: 'HelvetivaForTarget, Helvetica, Arial, sans-serif',
    body1: {
      fontSize: '1.125rem',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [targeticaFont],
      },
    },
  },
});

siteTheme = responsiveFontSizes(siteTheme, { factor: 3 });

siteTheme.typography.h2 = {
  fontSize: '1.9rem',
  lineHeight: '3rem',
  color: '#333333',
  [siteTheme.breakpoints.down('md')]: {
    fontSize: '1.6rem',
  },
  [siteTheme.breakpoints.down('xs')]: {
    fontSize: '1.6rem',
    lineHeight: '2rem',
  },
};

siteTheme.typography.h3 = {
  fontSize: '1.872rem',
  lineHeight: '3rem',
  color: '#333333',
  fontWeight: 'bold',
  [siteTheme.breakpoints.down('md')]: {
    fontSize: '1.5rem',
  },
  [siteTheme.breakpoints.down('xs')]: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
  },
};

siteTheme.typography.h4 = {
  fontSize: '1.4rem',
  marginTop: 0,
  marginBottom: 0,
  lineHeight: '3rem',
  [siteTheme.breakpoints.down('md')]: {
    fontSize: '1.25rem',
  },
  [siteTheme.breakpoints.down('xs')]: {
    fontSize: '1.1rem',
  },
};

siteTheme.typography.body1 = {
  fontSize: '1rem',
  color: '#333333',
  [siteTheme.breakpoints.down('xs')]: {
    fontSize: '1rem',
    lineHeight: '1.8rem',
  },
};

// 'Targetica', 'Helvetica', 'Arial', 'sans-serif'
const Layout = (props) => {
  const { getSiteConfiguration, getPageData, getVersionData, getPostData, openSearchBox } = props;
  const history = useHistory();
  const configurations = useSelector((state) => state.configuration.getConfiguration.data);
  const pages = useSelector((state) => state.page.pageCache);
  const posts = useSelector((state) => state.post.postCache);
  const gettingPageData = useSelector((state) => state.page.getPageData);
  const gettingPostData = useSelector((state) => state.post.getPostData);
  const { inProgress, error } = gettingPageData;
  const { pathname } = useLocation();
  const [headerMenu, setHeaderMenu] = useState(null);
  const [footerMenu, setFooterMenu] = useState(null);
  const [footerSubMenu, setFooterSubMenu] = useState(null);
  const [companyAndSocialMenu, setCompanyAndSocialMenu] = useState(null);
  /**
   * Getting site configuration and default page data
   */
  useEffect(() => {
    getSiteConfiguration();
  }, [getSiteConfiguration]);

  useEffect(() => {
    if (!isValid(pathname.replace(/[#,+()$~%.'":*<>{}&]/g, ''))) {
      history.push('not-found');
    }
    if (!(pathname.indexOf('/blog') > -1) && !pages[pathname] && !inProgress && !error && isValid(pathname)) {
      if (!getQueryParamByKey('version')) {
        getPageData(pathname);
      } else {
        getVersionData(getQueryParamByKey('version'), pathname);
      }
    }
    // Found a blog url
    if (
      !(pathname.indexOf('/blog/tag/') > -1) &&
      !(pathname.indexOf('/blog/category/') > -1) &&
      pathname.indexOf('/blog/') > -1 &&
      !posts[pathname] &&
      !gettingPostData.inProgress &&
      !gettingPostData.error &&
      isValid(pathname)
    ) {
      getPostData(pathname);
    }
  }, [getPageData, inProgress, pages, posts, pathname, error, history, getVersionData, getPostData, gettingPostData.inProgress, gettingPostData.error]);

  useEffect(() => {
    if (configurations) {
      /** Check header data present */
      const headerMenuData = _.find(configurations, { name: 'header_menu' });
      const footerMenuData = _.find(configurations, { name: 'footer_menu' });
      const footerSubMenu = _.find(configurations, { name: 'footer_sub_menu' });
      const companyAndSocialMenu = _.find(configurations, {
        name: 'company_social_menu',
      });
      if (headerMenuData) setHeaderMenu(headerMenuData.published_value);
      if (footerMenuData) setFooterMenu(footerMenuData.published_value);
      if (footerSubMenu) setFooterSubMenu(footerSubMenu.published_value);
      if (companyAndSocialMenu) setCompanyAndSocialMenu(companyAndSocialMenu.published_value);
    }
  }, [configurations]);

  const showSearchBackDrop = () => {
    openSearchBox();
  };

  return (
    <Fragment>
      <ThemeProvider theme={siteTheme}>
        <CssBaseline />
        <HeaderSection menu={headerMenu} showSearchBackDrop={showSearchBackDrop} />
        <main id='main-content'>
          <Switch>
            <Route exact path='/blog/category/*' component={Category} />
            <Route exact path='/blog/tag/*' component={Tags} />
            <Route exact path='/blog/*' component={BlogContainer} />
            <Route exact path='/blog' component={Blogs} />
            <Route exact path='/videostory' component={VideoInFullPage} />
            <Route exact path='/search/:search' component={SearchResult} />
            <Route exact path='/' component={PageContainer} />
            <Route exact path='/not-found' component={NotFound} />
            <Route exact path='*' component={PageContainer} />
            <Route exact path='/404' component={NotFound} />
          </Switch>
        </main>
        <FooterSection menu={{ footerMenu, footerSubMenu, companyAndSocialMenu }} />
      </ThemeProvider>
    </Fragment>
  );
};

const mapDispatchToProps = {
  getSiteConfiguration,
  getPageData,
  getPostData,
  getVersionData,
  openSearchBox,
};

export default connect(null, mapDispatchToProps)(Layout);
