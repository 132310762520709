import React from 'react'
import { makeStyles, Typography, Link } from '@material-ui/core'
import {getValueByAttribute} from '../../Utils/DataExtract'
import idx from 'idx'

const useStyles = makeStyles((theme) => ({
    infoBox: {
        background: '#D3D2D2',
        textAlign: 'center',
        padding: theme.spacing(4),
        marginBottom: theme.spacing(3),
    },
    headingStyle: {
        color: '#333'
    },
    descStyle: {
        fontSize: '1rem',
        color: '#333'
    },
    linkButton: {
        color: '#CC0000',
        fontWeight: 'bold'
    }
}))

const Info = (props) => {
    const {component} = props
	const values = idx(component, (_) => _.attributes.values)
    const classes = useStyles()
    
    return (
        <>
            <div>
                <div className={classes.infoBox}>
                    <Typography className={classes.headingStyle} variant={"h3"}>{getValueByAttribute(values, 'heading')}</Typography>
                    <Typography className={classes.descStyle}>
                        {getValueByAttribute(values, 'description')}
                        {getValueByAttribute(values, 'link') && <Link className={classes.linkButton} target={'_blank'} href={getValueByAttribute(values, 'link')}>{getValueByAttribute(values, 'link_label')}</Link>}</Typography>
                </div>
            </div>
        </>
    )
}

export default Info
