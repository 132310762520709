import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CardMedia, Typography, Link, Grid } from '@material-ui/core';
import { motion, useAnimation } from 'framer-motion'
import { BrandBannerEffect } from '../../Utils/AnimationEffects'
import { useInView } from "react-intersection-observer"
import {getValueByAttribute} from '../../Utils/DataExtract'
import idx from 'idx'

const useStyles = makeStyles((theme) => ({
    root: {
		backgroundColor: '#eeeded',
		color: '#CC0000',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        borderLeft: '15px solid',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            paddingLeft: '0px !important',
            margin: theme.spacing(0),
            marginBottom: theme.spacing(3),
            borderLeft: '0px solid',
            borderTop: '15px solid',
        },
    },
    card: {
        width: 387,
        overflow: 'initial',
        background: 'inherit',
        [theme.breakpoints.down('md')]: {
            paddingLeft: 40
        },
        [theme.breakpoints.down('sm')]: {
            width: 'auto',
            paddingTop: 50,
            paddingLeft: 16
        },
    },
    cardMedia: {
        width: 312,
        [theme.breakpoints.down('md')]: {
            width: 240
        },
        [theme.breakpoints.down('sm')]: {
            width: 118
        },
    },
    contentDiv: {
        color: '#484848',
        fontSize: 36,
        paddingLeft: 37,
        [theme.breakpoints.down('md')]: {
            fontSize: 26,
        },
        [theme.breakpoints.down('sm')]: {
            padding: '16px 16px 0 16px',
            fontSize: 18,
        },
    },
    image: {
        height: '100%',
        objectFit: 'contain',
        cursor: 'pointer',
    },
    mediaPlace: {
		padding: 10,
        height: 220,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: theme.spacing(2)
        }
    },
    contentPlace: {
		paddingLeft: 10,
        paddingRight: 45,
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(6),
            textAlign: 'center',
            padding: theme.spacing(4) + 'px !important',
            paddingTop: '0px !important'
        }
    },
    heading:{
		color: '#CC0000',
        lineHeight: '4rem',
        [theme.breakpoints.down('sm')]: {
            lineHeight: '2rem !important',
            fontSize: '1rem',
            textAlign: 'center'
        }
    },
    content: {
		height: 'auto',
		fontSize: '1rem',
        color: '#333333',
		paddingBottom: 20,
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8rem !important',
            textAlign: 'center'
        }
    },
    link: {
		color: '#CC0000',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8rem !important',
            textAlign: 'center'
        }
    },
    contentImage: {
        width: 150,
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
}));

export default function BrandBanner(props) {
	const {component} = props
	const values = idx(component, (_) => _.attributes.values)
    const classes = useStyles();
    const controls = useAnimation()
	const [ref, inView] = useInView({triggerOnce: false});
    useEffect(() => {
        if (inView) {
            setTimeout(() => {
                controls.start("visible");
            }, 300)
            
        }
    }, [controls, inView])

    const onClick = () => {
        if(getValueByAttribute(values, 'external_link')) {
            window.open(getValueByAttribute(values, 'external_link'))
        }
    }

    return (
        <div ref={ref}>
            <motion.div                                     
                animate={controls}
                initial="hidden"
                {...BrandBannerEffect}
                className={classes.bannerText}
            >
				<div className={classes.root}>
                    <Grid container alignContent={"center"} alignItems={'center'}>
						<Grid item xs={12} md={3} className={classes.mediaPlace} onClick={onClick}>
                            <CardMedia className={classes.image} component={'img'} image={getValueByAttribute(values, 'image')} alt={getValueByAttribute(values, 'image_alt_text')} />
                        </Grid>
						<Grid item xs={12} md={8} className={classes.contentPlace}>
                                {getValueByAttribute(values, 'heading') && <Typography variant={'h3'}  className={classes.heading}>{getValueByAttribute(values, 'heading')}</Typography>}
                                <Typography className={classes.content}>{getValueByAttribute(values, 'description')}</Typography>		
                                {getValueByAttribute(values, 'external_link') && !getValueByAttribute(values, 'content_image') && <Link href={getValueByAttribute(values, 'external_link')} target={'_blank'} className={classes.link}>{getValueByAttribute(values, 'link_label')}</Link>}
                                {getValueByAttribute(values, 'content_image') && <div className={classes.sencondaryImageArea}><Link href={getValueByAttribute(values, 'external_link')} target={'_blank'}><CardMedia alt={getValueByAttribute(values, 'content_image_alt_test')} className={classes.contentImage} component={'img'} image={getValueByAttribute(values, 'content_image')} /></Link></div>}
                        </Grid >
                    </Grid>
                    
                </div>
            </motion.div>
        </div>
    );
}
