import React from 'react'
import { makeStyles, Typography, Grid } from '@material-ui/core'
import { getValueByAttribute } from '../../Utils/DataExtract'
import idx from 'idx'
import StoryTileCampusRecruitment from '../StoryTile/StoryTileCampusRecruitment'

const useStyles = makeStyles((theme) => ({
  storyBoxBase: {
    padding: theme.spacing(4)
  },
  heading: {
    textAlign: 'center',
    marginBottom: theme.spacing(8),
    color: '#333333',
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(3)
    }
  }
}))

const StoryGridCampusRecruitment = (props) => {
  const { component, desktop } = props
  const values = idx(component, (_) => _.attributes.values)
  const { styling, spacing } = values
  const classes = useStyles()

  const defaultBoxStyle = {}
  const boxStyle =
    styling && styling.box
      ? Object.assign({}, defaultBoxStyle, styling.box)
      : defaultBoxStyle

  const background = desktop
    ? getValueByAttribute(values, 'backgroundImage') &&
      getValueByAttribute(values, 'backgroundImage', true, 0)
      ? `url(${encodeURI(
          getValueByAttribute(values, 'backgroundImage', true, 0)
        )})`
      : ''
    : getValueByAttribute(values, 'backgroundImage') &&
      getValueByAttribute(values, 'backgroundImage', true, 1)
    ? `url(${encodeURI(
        getValueByAttribute(values, 'backgroundImage', true, 1)
      )})`
    : ''

  const backgroundStyle = {
    backgroundImage: background,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    ...boxStyle
  }

  return (
    <div style={backgroundStyle} className={classes.storyBoxBase}>
      {getValueByAttribute(values, 'heading') && (
        <Typography variant={'h3'} className={classes.heading}>
          {getValueByAttribute(values, 'heading')}
        </Typography>
      )}

      {getValueByAttribute(values, 'sub_heading') && (
        <Typography className={classes.subHeading}>
          {getValueByAttribute(values, 'sub_heading')}
        </Typography>
      )}

      <Grid
        container
        className={classes.dataContainer}
        spacing={spacing ? spacing : 8}
      >
        {getValueByAttribute(values, 'tiles', true) &&
          getValueByAttribute(values, 'tiles', true).length > 0 &&
          getValueByAttribute(values, 'tiles', true).map((tile, index) => {
            return (
              <Grid item xs={12} md={tile.width} key={index}>
                <StoryTileCampusRecruitment values={tile} desktop={desktop} />
              </Grid>
            )
          })}
      </Grid>
    </div>
  )
}

export default StoryGridCampusRecruitment
