import React, { Fragment, useEffect } from 'react'
import {
  makeStyles,
  Typography,
  Grid,
  CardMedia,
  Link
} from '@material-ui/core'
import { motion, useAnimation } from 'framer-motion'
import { RedBanner } from '../../Utils/AnimationEffects'
import { useInView } from 'react-intersection-observer'
import renderHTML from 'react-render-html'
import idx from 'idx'
import { getValueByAttribute } from '../../Utils/DataExtract'

const useStyles = makeStyles((theme) => ({
  bannerText: {
    padding: theme.spacing(3),
    height: '100%'
  },
  headingText: {
    color: '#fff',
    fontSize: '1.7rem'
  },
  desc: {
    color: '#fff',
    paddingRight: theme.spacing(2),
    marginBottom: 40
  },
  linkButton: {
    border: '1px solid #FFFFFF',
    borderRadius: 20,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    color: '#CC0000',
    background: '#fff',
    textDecoration: 'none',
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    }
  },
  bannerImage: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3)
    }
  }
}))

const Text = (props) => {
  const { classes, desktop, controls, values } = props
  return (
    <>
      {desktop && (
        <motion.div
          animate={controls}
          initial='hidden'
          {...RedBanner.desktop}
          className={classes.bannerText}
        >
          <Typography className={classes.headingText} variant={'h3'}>
            {getValueByAttribute(values, 'heading')}
          </Typography>
          <div className={classes.desc}>
            {renderHTML(getValueByAttribute(values, 'description'))}
          </div>
          {getValueByAttribute(values, 'landing_url') && (
            <Link
              className={classes.linkButton}
              href={getValueByAttribute(values, 'landing_url')}
              target='_blank'
            >
              {getValueByAttribute(values, 'link_text')}
            </Link>
          )}
        </motion.div>
      )}
      {!desktop && (
        <motion.div
          animate={controls}
          initial='hidden'
          {...RedBanner.mobile}
          className={classes.bannerText}
        >
          <Typography className={classes.headingText} variant={'h3'}>
            {getValueByAttribute(values, 'heading')}
          </Typography>
          <Typography className={classes.desc}>
            {getValueByAttribute(values, 'description')}
          </Typography>
          {getValueByAttribute(values, 'landing_url') && (
            <Link
              className={classes.linkButton}
              href={getValueByAttribute(values, 'landing_url')}
              target='_blank'
            >
              {getValueByAttribute(values, 'link_text')}
            </Link>
          )}
        </motion.div>
      )}
    </>
  )
}

const Image = (props) => {
  const { image, classes, controls } = props
  return (
    <motion.div animate={controls} initial='hidden' {...RedBanner.rotate}>
      <CardMedia
        component='img'
        className={classes.bannerImage}
        image={`${image}`}
        alt={''}
      />
    </motion.div>
  )
}

const Banner = (props) => {
  const { component, desktop, sideMenu } = props
  const values = idx(component, (_) => _.attributes.values)
  const classes = useStyles()
  const images = getValueByAttribute(values, 'images', true)

  const bannerStyle = {
    background: '#CC0000',
    height: 'auto',
    marginTop: desktop ? 50 : 25,
    marginBottom: desktop ? 50 : 25,
    padding: desktop ? 20 : 10
  }

  const controls = useAnimation()
  const [ref, inView] = useInView({ triggerOnce: false })

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        controls.start('visible')
      }, 300)
    }
  }, [controls, inView])

  // let tiles = items.sort((a, b) => (a.type === (desktop ? 'text' : 'image') && b.type !== (desktop ? 'text' : 'image')) ? -1 : 1)
  return (
    <Fragment>
      <Grid
        container
        style={bannerStyle}
        justify='center'
        alignItems='center'
        ref={ref}
      >
        {desktop ? (
          <Fragment>
            <Grid
              item
              style={{ width: desktop ? (sideMenu ? '50%' : '40%') : '100%' }}
            >
              <Text
                controls={controls}
                classes={classes}
                values={values}
                desktop={desktop}
              />
            </Grid>
            {images &&
              images.length > 0 &&
              images.map((image, iIndex) => {
                if ((sideMenu && iIndex > 1) || iIndex > 2) {
                  return null
                } else {
                  return (
                    <Grid
                      key={iIndex}
                      item
                      style={{ width: desktop ? '20%' : '50%' }}
                    >
                      <Image
                        image={image}
                        controls={controls}
                        classes={classes}
                      />
                    </Grid>
                  )
                }
              })}
          </Fragment>
        ) : (
          <Fragment>
            {images &&
              images.length > 0 &&
              images.map((image, iIndex) => {
                return (
                  <Grid
                    key={iIndex}
                    item
                    style={{ width: desktop ? '20%' : '50%' }}
                  >
                    <Image
                      image={image}
                      controls={controls}
                      classes={classes}
                    />
                  </Grid>
                )
              })}
            <Grid
              item
              style={{ width: desktop ? (sideMenu ? '50%' : '40%') : '100%' }}
            >
              <Text
                controls={controls}
                classes={classes}
                values={values}
                desktop={desktop}
              />
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Fragment>
  )
}

export default Banner
