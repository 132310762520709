import React, { Fragment, useState } from 'react'
import { makeStyles, Typography, CardHeader, CardMedia, List, ListItem, Card, CardContent, Link, /*ListItemIcon*/ } from '@material-ui/core'
import { Animated } from 'react-animated-css'
import { motion } from "framer-motion";
import { HashLink as RLink } from 'react-router-hash-link'

const useStyles = makeStyles((theme) => ({
    stickyContainer: {
        height: '100%',
        width: '100%',
        position: 'relative'
    },
    stickyMenu: {
        position: 'sticky',
        minHeight: 100,
        width: '100%',
        top: 170,
        [theme.breakpoints.down('md')]: {
            top: 140
        }
    },
    menuArea: {
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(4),
        background: '#F7F7F7',
        boxShadow: '0 0 1px',
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
            padding: 0
        }
    },
    groupList: {
        display: 'inline-block',
        paddingTop: 0,
        padding: theme.spacing(4),
        paddingBottom: 0,
    },
    groupMenu: {
        color: '#000',
        fontSize: '1rem',
        fontWeight: 'bold',
        lineHeight: '2.5rem',
        textDecoration: 'none',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.8rem',
            lineHeight: '2rem',
        }
    },
    groupMenuList: {
        paddingBottom: 0,
        paddingTop: 0
    },
    subMenu: {
        fontWeight: 'normal',
        fontSize: '0.9rem',
        lineHeight: '1rem',
        color: '#666666',
        textDecoration: 'none',

        width: '100%',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.8rem',
            lineHeight: '1rem',
        }
    },
    superSubMenu: {
        fontWeight: 'normal',
        fontSize: '13px',
        lineHeight: '1rem',
        color: '#666666',
        textDecoration: 'none',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.8rem',
            lineHeight: '1rem',
        }
    },
    currentSubMenu: {
        color: '#CC0000',
        textDecoration: 'none',
        fontWeight: '600',
        fontSize: '0.9rem',
        lineHeight: '1rem',

        [theme.breakpoints.down('md')]: {
            fontSize: '0.8rem',
            lineHeight: '1rem',
        }
    },
    currentSubMenuListItemClosedRed: {
        color: '#CC0000',
        listStyleType: 'none',
        paddingLeft: theme.spacing(1.5),

        '&::marker':{
            content: '"▸"',
            fontSize: '100%',
        },

        [theme.breakpoints.down('md')]: {
        },
    },
    currentSubMenuListItemClosedGrey: {
        color: '#666',
        listStyleType: 'none',
        paddingLeft: theme.spacing(1.5),

        '&::marker':{
            content: '"▸"',
            fontSize: '100%',
        },

        [theme.breakpoints.down('md')]: {
        },
    },
    currentSubMenuListItemOpened: {
        color: '#CC0000',
        listStyleType: 'none',
        paddingLeft: theme.spacing(1.5),

        '&::marker':{
            content: '"▾"',
            fontSize: '100%',
        },

        [theme.breakpoints.down('md')]: {
        },
    },
    subMenuList: {
        listStyle: 'none',
        paddingLeft: theme.spacing(1)
    },
    subMenuListItem: {
         listStyleType: 'disc',
        padding: 0,
        paddingLeft: theme.spacing(1.5),
        paddingTop: '5px',

        '&::marker':{
            content: '"•"'
        },
    },
    currentSubMenuListItem: {
        color: '#CC0000',
        listStyleType: 'none',
        paddingTop: '5px',
        paddingLeft: theme.spacing(1.5),
        '&::marker':{
            content: '"•"'
        },

        [theme.breakpoints.down('md')]: {
        },
    },
    currentSuperSubMenuListItem: {
        color: '#666',
        listStyle: 'none',
        display: 'flex',

        '&::before':{
            content: '"-"',
            marginTop: '-4px',
            marginRight: theme.spacing(1),
        },

        [theme.breakpoints.down('md')]: {
        }
    },
    triviaCard: {
        height: 282,
        boxShadow: '0 0 1px',
        borderRadius: 0,
        background: '#F7F7F7',
        padding: theme.spacing(3),
        paddingTop: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2),
            paddingTop: theme.spacing(1),
            height: 'auto'
        }
    },
    helpCard: {
        boxShadow: '0 0 1px',
        borderRadius: 0,
        background: '#F7F7F7',
        padding: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2),
            paddingTop: theme.spacing(1),
            height: 'auto'
        }
    },
    socialCard: {
        paddingTop: theme.spacing(4),
        background: 'transparent',
        [theme.breakpoints.down('md')]: {
            paddingTop: theme.spacing(1),
            height: 'auto'
        }
    },
    triviaMedia: {
        minHeight: 148
    },
    logoImg: {
        height: 29,
        width: 29
    },
    triviaText: {
        fontSize: '0.68rem',
        lineHeight: '1rem',
        textAlign: 'left',
        color: '#454545'
    },
    triviaTextArea: {
        paddingLeft: 0
    },
    helpHeader: {
        color: '#454545',
        fontSize: '1rem',
        fontWeight: 'bold',
        lineHeight: '2rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.8rem'
        }
    },
    helpDesc: {
        marginTop: 10,
        color: '#454545',
        fontSize: '0.9rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.8rem'
        }
    },
    helpItem: {
        paddingLeft: theme.spacing(0)
    },
    helpLinks: {
        color: '#707070',
        fontSize: '1rem',
        textDecoration: 'none',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.8rem'
        }
    },
    socialHeading: {
        color: '#454545',
        fontSize: '1rem',
        fontWeight: 'bold',
        lineHeight: '2rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.8rem'
        }
    },
    socialLinks: {
        color: '#454545',
    },
    socialIconArea: {
        background: '#CC0000',
        // padding: 6,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 40,
        height: 40,
        borderRadius: 50
    },
    socialItem: {
        paddingLeft: 0
    },
    socialIcons: {
        color: '#fff'
    }
}))

const StickySideMenu = props => {
    const { values } = props
    const { menu, currentPage, currentExpanded, trivia, helpfulLinks, /*social*/ } = values
    const [expanded, setExpaned] = useState(currentExpanded)
    const [expandedSub, setExpanedSub] = useState(window.location.pathname)
    const classes = useStyles()
    // const loadIcon = (icon) => {
    //     const SelectedType = socialIcons[icon]
    //     const iconsProps = {
    //         className: classes.socialIcons
    //     }
    //     return <div className={classes.socialIconArea}><SelectedType {...iconsProps} /></div>
    // }
    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -90;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }


    const toggleMenu = (incoming) => {
        setExpaned(expanded === incoming ? '' : incoming)
    }

    const toggleSubMenu = (incoming) => {
        setExpanedSub(expandedSub === incoming ? '' : incoming)
    }
    
    const variants = {
        open: {
            y: 0,
            opacity: 1,
            transition: {
                y: { stiffness: 1000, velocity: -100 }
            }
        },
        closed: {
            y: 50,
            opacity: 0,
            transition: {
                y: { stiffness: 1000 }
            }
        }
    }
    return (
        <Fragment>
            <Animated animationIn={"fadeIn"} className={classes.stickyContainer}>
                <div className={classes.stickyMenu}>
                    <List className={classes.menuArea}>
                        {menu && menu.children && menu.children.length > 0 && menu.children.map((groupMenu, groupIndex) => {
                            return (
                                <Fragment key={groupIndex}>
                                    <ListItem className={classes.groupList}>
                                        {groupMenu.children && groupMenu.children.length > 0 ? <Link href={'#'} aria-expanded={expanded === groupMenu.slug ? true : false} onClick={() => toggleMenu(groupMenu.slug)} className={classes.groupMenu}>{groupMenu.title}</Link> : <Typography className={classes.groupMenu}>{groupMenu.title}</Typography>}
                                        {expanded === groupMenu.slug && groupMenu.children && groupMenu.children.length > 0 && (
                                            <List className={classes.subMenuList}>

                                                {groupMenu.children.map((subMenu, subMenuIndex) => {
                                                    if (subMenu.display_in_side_nav) {
                                                        return (
                                                            <motion.li
                                                                variants={variants}
                                                                whileTap={{ scale: 0.98 }}
                                                                className=
{ currentPage === subMenu.slug || currentPage.indexOf(subMenu.slug) > -1 ?
     ((subMenu.children && subMenu.children.length > 0)? (expandedSub ?
         classes.currentSubMenuListItemOpened : classes.currentSubMenuListItemClosedRed) : classes.currentSubMenuListItem) : ((subMenu.children && subMenu.children.length > 0) ?
         classes.currentSubMenuListItemClosedGrey : classes.subMenuListItem)}
                                                                i={subMenuIndex}
                                                                key={subMenuIndex}
                                                            >
                                                                
                                                                <RLink to={subMenu.slug} onClick={() => toggleSubMenu(subMenu.slug)} aria-expanded={expandedSub === subMenu.slug ? true : false} className={currentPage === subMenu.slug ? classes.currentSubMenu : classes.subMenu}>{subMenu.title}</RLink>
                                                                {(expandedSub === subMenu.slug && subMenu.children && subMenu.children.length > 0) && (
                                                                    <List className={classes.superSubMenuList}>
                                                                        {(currentPage === subMenu.slug) && subMenu.children && subMenu.children.map(superSubMenu =>
                                                                            <motion.li
                                                                            variants={variants}
                                                                            whileTap={{ scale: 0.98 }}
                                                                            className={ currentPage === subMenu.slug || currentPage.indexOf(subMenu.slug) > -1 ? classes.currentSuperSubMenuListItem : classes.subMenuListItem}
                                                                            i={subMenuIndex}
                                                                            key={subMenuIndex}
                                                                        ><RLink to={superSubMenu.slug}  scroll={(el) => scrollWithOffset(el)} className={currentPage === superSubMenu.slug ? classes.currentSubMenu : classes.superSubMenu}>{superSubMenu.title}</RLink>
                                                                        </motion.li>
                                                                        )}
                                                                    </List>    )}
                                                            </motion.li>
                                                        )
                                                    } else {
                                                        return ''
                                                    }
                                                })}

                                            </List>
                                        )
                                        }
                                    </ListItem>
                                </Fragment>
                            )
                        })}
                    </List>
                    {trivia && (
                        <Card className={classes.triviaCard} elevation={0}>
                            <CardHeader
                                avatar={
                                    <CardMedia className={classes.logoImg} image={trivia.logo} />
                                }
                                title={trivia.header ? trivia.header : 'Trivia'}
                            />
                            <CardMedia image={trivia.image} className={classes.triviaMedia} />
                            <CardContent className={classes.triviaTextArea}>
                                <Typography className={classes.triviaText}>{trivia.text}</Typography>
                            </CardContent>
                        </Card>
                    )}
                    {helpfulLinks && (
                        <Card className={classes.helpCard} elevation={0}>
                            <Typography className={classes.helpHeader}>{helpfulLinks.heading}</Typography>
                            <Typography className={classes.helpDesc}>{helpfulLinks.description}</Typography>
                            <List>
                                {helpfulLinks.links && helpfulLinks.links.map((link, index) => {
                                    return (
                                        <ListItem key={index} className={classes.helpItem}>
                                            <RLink className={classes.helpLinks} to={link.slug}>{link.title}</RLink>
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Card>
                    )}
                    {/* {social && (
                        <Card className={classes.socialCard} elevation={0}>
                            <Typography className={classes.socialHeading}>{social.heading}</Typography>
                            <List>
                                {social.items && social.items.map((item, index) => {
                                    return (
                                        <ListItem key={index} className={classes.socialItem}>
                                            {item.icon && (
                                                <ListItemIcon>
                                                    {loadIcon(item.icon, classes)}
                                                </ListItemIcon>
                                            )}
                                            <Link target={'_blank'} className={classes.socialLinks} href={item.link}>{item.title}</Link>
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Card>
                    )} */}
                </div>
            </Animated>
        </Fragment>
    )
}

export default StickySideMenu
