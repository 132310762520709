import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import ShowMore from '@tedconf/react-show-more'
import { Link as RLink } from 'react-router-dom'
import { Button, Typography, Card, CardMedia, Link } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { motion, useAnimation } from 'framer-motion'
import { TileZoomEffects } from '../../Utils/AnimationEffects'
import { useInView } from 'react-intersection-observer'
import { useHistory } from 'react-router-dom'
import { getValueByAttribute } from '../../Utils/DataExtract'
import idx from 'idx'

const useStyles = makeStyles((theme) => ({
  tilesMainArea: {
    paddingTop: 30,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 20
    }
  },
  headerText: {
    textAlign: 'center'
  },
  subText: {
    textAlign: 'center'
  },
  storyLink: {
    marginTop: 30,
    borderRadius: 35,
    color: '#CC0000',
    borderColor: '#CC0000',
    fontSize: 16,
    textTransform: 'none',
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      marginTop: 28
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      marginTop: 26
    }
  },
  storyLinkHidden: {
    display: 'none'
  },
  departmentViewmoreContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  gridText: {
    fontSize: '1.25rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.37rem'
    }
  },
  titleArea: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  },
  card: {
    background: '#D0D2D3',
    transition: 'background 1.5s, color 1.5s',
    '&:hover': {
      // background: '#333333',
      // color: '#ffffff',
    },
    '&:hover h3': {
      // color: '#ffffff',
    },
    [theme.breakpoints.up('sm')]: {
      '&:hover img': {
        filter: 'grayscale(0%) !important'
      },
      '& img': {
        filter: 'grayscale(100%) !important'
      }
    }
  },
  tilesArea: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7)
  },
  contentArea: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    }
  },
  tileLink: {
    width: '100%',
    color: '#CC0000',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 13
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0
    }
  },
  content: {
    // fontSize: '0.9rem',
    lineHeight: '1.6rem'
  },
  tileContainer: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '30px !important'
    }
  },
  linkCard: {
    height: '18vw',
    background: '#cc0000',
    color: '#fff',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      height: '25vw'
    },
    [theme.breakpoints.down('sm')]: {
      height: '40vw'
    },
    [theme.breakpoints.down('xs')]: {
      height: '70vw'
    }
  },
  externalLinkText: {
    color: '#fff',
    width: '100%',
    fontWeight: 'bold'
  }
}))

const Tiles = (props) => {
  const { items, settingsBy, settingsAnimate, desktop } = props
  const classes = useStyles()
  const controls = useAnimation()
  const history = useHistory()
  const [ref, inView] = useInView({ triggerOnce: false })

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        controls.start('visible')
      }, 500)
    }
  }, [controls, inView])

  const handleCardClick = (tile) => {
    history.push(tile.slug)
  }

  let animationProps = {
    animate:
      settingsAnimate === 'direct' ? TileZoomEffects.direct.animate : controls,
    initial:
      settingsAnimate === 'direct' ? TileZoomEffects.direct.initial : 'hidden',
    transition:
      settingsAnimate === 'direct'
        ? TileZoomEffects.direct.transition
        : TileZoomEffects.lazy.transition
  }

  if (desktop) {
    animationProps.whileHover = { scale: 1.05 }
    animationProps.whileTap = { scale: 0.9 }
  }

  if (settingsAnimate !== 'direct') {
    animationProps = { ...animationProps, ...TileZoomEffects.lazy }
  }

  return (
    <>
      <div className={classes.tilesArea} ref={ref}>
        {/* <h1>{JSON.stringify(items)}</h1> */}
        <ShowMore items={items} by={settingsBy ? settingsBy : 4}>
          {({ current, onMore, all, t, s }) => (
            <>
              <Grid container spacing={desktop ? 6 : 3}>
                {current.map((item, key) => (
                  <Grid
                    key={key}
                    item
                    xs={12}
                    md={4}
                    sm={6}
                    lg={3}
                    className={classes.tileContainer}
                  >
                    <motion.div {...animationProps}>
                      {getValueByAttribute(item, 'type') &&
                      getValueByAttribute(item, 'type') !== 'external_link' ? (
                        <Card
                          className={classes.card}
                          style={{ cursor: 'pointer' }}
                        >
                          <CardMedia
                            component='img'
                            style={{
                              minHeight: '100%',
                              minWidth: '100%'
                            }}
                            alt={getValueByAttribute(item, 'image_alt_text')}
                            image={getValueByAttribute(item, 'image')}
                            onClick={() => handleCardClick(item)}
                          />
                          <div className={classes.titleArea}>
                            <Typography
                              variant={'h3'}
                              className={classes.gridText}
                            >
                              {getValueByAttribute(item, 'text')}
                            </Typography>
                          </div>
                        </Card>
                      ) : (
                        <Link href={item.link} target='_blank'>
                          <Card className={classes.linkCard}>
                            <Typography className={classes.externalLinkText}>
                              {getValueByAttribute(item, 'linkText')}
                            </Typography>
                          </Card>
                        </Link>
                      )}
                    </motion.div>
                    {getValueByAttribute(item, 'content') && (
                      <div className={classes.contentArea}>
                        <Typography className={classes.content}>
                          {getValueByAttribute(item, 'content')}
                        </Typography>
                      </div>
                    )}
                    {getValueByAttribute(item, 'type') === 'link' && (
                      <RLink
                        to={getValueByAttribute(item, 'slug')}
                        className={classes.tileLink}
                      >
                        {getValueByAttribute(item, 'linkText')
                          ? getValueByAttribute(item, 'linkText')
                          : 'Explore'}
                      </RLink>
                    )}
                  </Grid>
                ))}
              </Grid>
              <div className={classes.departmentViewmoreContainer}>
                <Button
                  variant='outlined'
                  className={
                    !onMore ? classes.storyLinkHidden : classes.storyLink
                  }
                  disableElevation
                  onClick={() => {
                    if (!!onMore) onMore()
                  }}
                >
                  View more
                  <ArrowDropDownIcon />
                </Button>
              </div>
            </>
          )}
        </ShowMore>
      </div>
    </>
  )
}

const LoadTiles = (props) => {
  const { component, desktop } = props
  const values = idx(component, (_) => _.attributes.values)
  const styling = {
    tile: {
      padding: 30
    }
  }
  const classes = useStyles()
  const defaultTileStyle = {}

  const tileStyle = Object.assign(
    {},
    defaultTileStyle,
    styling && styling.tile ? styling.tile : {}
  )

  return (
    <div className={classes.tilesMainArea} style={desktop ? tileStyle : {}}>
      <div className={classes.departmentHeadingContainer}>
        <Typography className={classes.headerText} variant={'h3'}>
          {getValueByAttribute(values, 'heading')}
        </Typography>
        {getValueByAttribute(values, 'sub_heading') &&
          getValueByAttribute(values, 'sub_heading') && (
            <Typography className={classes.subText}>
              {getValueByAttribute(values, 'sub_heading')}
            </Typography>
          )}
        {values.secondaryHeading2 && values.secondaryHeading2[0] && (
          <Typography className={classes.subText}>
            {values.secondaryHeading2[0]}
          </Typography>
        )}
      </div>
      <div>
        <Tiles
          items={getValueByAttribute(values, 'grid_items', true)}
          settingsBy={getValueByAttribute(values, 'grid_settings_by')}
          settingsAnimate={getValueByAttribute(values, 'grid_settings_animate')}
          desktop={desktop}
        />
      </div>
    </div>
  )
}

export default LoadTiles
