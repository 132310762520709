import {
  FETCH_POST_DATA_SUCCESS,
  FETCH_POST_DATA_FAILED,
  FETCH_POST_DATA_IN_PROGRESS,
  FETCH_SIMILAR_POSTS_IN_PROGRESS,
  FETCH_SIMILAR_POSTS_SUCCESS,
  FETCH_SIMILAR_POSTS_FAILED,
  FETCH_RECENT_POSTS_IN_PROGRESS,
  FETCH_RECENT_POSTS_SUCCESS,
  FETCH_RECENT_POSTS_FAILED,
  GET_POST_CATEGORIES_IN_PROGRESS,
  GET_POST_CATEGORIES_SUCCESS,
  GET_POST_CATEGORIES_FAILED,
  GET_POSTS_BY_CATEGORIES_IN_PROGRESS,
  GET_POSTS_BY_CATEGORIES_SUCCESS,
  GET_POSTS_BY_CATEGORIES_FAILED,
  GET_CATEGORY_POSTS_IN_PROGRESS,
  GET_CATEGORY_POSTS_SUCCESS,
  GET_CATEGORY_POSTS_FAILED,
  GET_TAG_POSTS_IN_PROGRESS,
  GET_TAG_POSTS_SUCCESS,
  GET_TAG_POSTS_FAILED,
} from './actionType'
import axios from 'axios'
import apiConfig from '../../apiConfig'
import _ from 'lodash'
import { pageTrack } from '../../components/Utils/Tracking'

export function getPostDataInProgress() {
  return {
    type: FETCH_POST_DATA_IN_PROGRESS,
  }
}

export function getPostDataSuccess(data, slug) {
  return {
    type: FETCH_POST_DATA_SUCCESS,
    data,
    slug
  }
}

export function getPostDataFailed(error) {
  return {
    type: FETCH_POST_DATA_FAILED,
    error
  }
}

export function getSimilarPostsInProgress() {
  return {
    type: FETCH_SIMILAR_POSTS_IN_PROGRESS,
  }
}

export function getSimilarPostsSuccess(data, category) {
  return {
    type: FETCH_SIMILAR_POSTS_SUCCESS,
    data,
    category
  }
}

export function getSimilarPostsFailed(error) {
  return {
    type: FETCH_SIMILAR_POSTS_FAILED,
    error
  }
}

export function getRecentPostsInProgress() {
  return {
    type: FETCH_RECENT_POSTS_IN_PROGRESS,
  }
}

export function getRecentPostsSuccess(data, category) {
  return {
    type: FETCH_RECENT_POSTS_SUCCESS,
    data,
  }
}

export function getRecentPostsFailed(error) {
  return {
    type: FETCH_RECENT_POSTS_FAILED,
    error
  }
}

export function getPostCategoriesInProgress() {
  return {
    type: GET_POST_CATEGORIES_IN_PROGRESS
  }
}

export function getPostCategoriesSuccess(data) {
  return {
    type: GET_POST_CATEGORIES_SUCCESS,
    data
  }
}

export function getPostCategoriesFailed(error) {
  return {
    type: GET_POST_CATEGORIES_FAILED,
    error
  }
}

export function getPostsByCategoriesInProgress() {
  return {
    type: GET_POSTS_BY_CATEGORIES_IN_PROGRESS
  }
}

export function getPostsByCategoriesSuccess(data, offset) {
  return {
    type: GET_POSTS_BY_CATEGORIES_SUCCESS,
    data,
    offset
  }
}

export function getPostsByCategoriesFailed(error) {
  return {
    type: GET_POSTS_BY_CATEGORIES_FAILED,
    error
  }
}

export function getCategoryPostsInProgress() {
  return {
    type: GET_CATEGORY_POSTS_IN_PROGRESS
  }
}

export function getCategoryPostsSuccess(data, category, offset) {
  return {
    type: GET_CATEGORY_POSTS_SUCCESS,
    data,
    category,
    offset
  }
}

export function getCategoryPostsFailed(error) {
  return {
    type: GET_CATEGORY_POSTS_FAILED,
    error
  }
}

export function getTagPostsInProgress() {
  return {
    type: GET_TAG_POSTS_IN_PROGRESS
  }
}

export function getTagPostsSuccess(data, tag, offset) {
  return {
    type: GET_TAG_POSTS_SUCCESS,
    data,
    tag,
    offset
  }
}

export function getTagPostsFailed(error) {
  return {
    type: GET_TAG_POSTS_FAILED,
    error
  }
}

export function getPostData(slug, noLoader) {
  return function (dispatch, getState) {
    if(!noLoader){
      dispatch(getPostDataInProgress())
    }
    const config = {
      method: 'get',
      url: apiConfig.preview ? `${apiConfig.apiHost}/posts?filter=${JSON.stringify({ where: { url: slug } })}` : `${apiConfig.apiHost}/posts`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    if (!apiConfig.preview) {
      config.params = {
        slug,
        key: apiConfig.xApiKey
      }
    }
    return axios(config).then(response => {
      const data = apiConfig.preview && response.data && response.data[0] ? response.data[0] : response.data
      dispatch(getPostDataSuccess(data, slug))
      if (data.category !== '' && !getState().post.similarPostsCache[data.category]) {
        dispatch(getSimilarPosts(data.category))
      }
      pageTrack()
    })
      .catch(error => {
        dispatch(getPostDataFailed(error))
      })
  }
}

export function getSimilarPosts(category) {
  return function (dispatch) {
    dispatch(getSimilarPostsInProgress())
    const config = {
      method: 'get',
      url: apiConfig.preview ? `${apiConfig.apiHost}/posts?filter=${JSON.stringify({ where: { category: category } })}` : `${apiConfig.apiHost}/posts/search`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    if (!apiConfig.preview) {
      config.params = {
        category,
        status: 'published',
        limit: 6,
        key: apiConfig.xApiKey
      }
    }
    return axios(config).then(response => {
      dispatch(getSimilarPostsSuccess(response.data, category))
    })
      .catch(error => {
        dispatch(getSimilarPostsFailed(error))
      })
  }
}

export function getRecentPosts(offset = 0) {
  return function (dispatch) {
    dispatch(getRecentPostsInProgress())
    const config = {
      method: 'get',
      url: apiConfig.preview ? `${apiConfig.apiHost}/posts?filter=${JSON.stringify({ limit: 6, skip: offset })}` : `${apiConfig.apiHost}/posts/search`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    if (!apiConfig.preview) {
      config.params = {
        status: 'published',
        limit: 6,
        offset,
        key: apiConfig.xApiKey
      }
    }
    return axios(config).then(response => {
      dispatch(getRecentPostsSuccess(response.data))
      pageTrack()
    })
      .catch(error => {
        dispatch(getRecentPostsFailed(error))
      })
  }
}

export function getPostCategories() {
  return function (dispatch) {
    dispatch(getPostCategoriesInProgress())
    const config = {
      method: 'get',
      url: apiConfig.preview ? `${apiConfig.apiHost}/posts?filter=${JSON.stringify({ where: {status: 'published'}, fields: {category: true} })}` : `${apiConfig.apiHost}/posts/categories`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    if (!apiConfig.preview) {
      config.params = {
        key: apiConfig.xApiKey
      }
    }
    return axios(config).then(response => {
      let finalData
      if(apiConfig.preview){
        const categoryList = [
          ''
        ];
        if (response.data && response.data.length > 0) {
          categoryList.push(...response.data.map((c) => c.category));
        }
        finalData = _.uniq(categoryList);
      } else {
        finalData = response.data
      }
      dispatch(getPostCategoriesSuccess(finalData))
    })
    .catch(error => {
      dispatch(getPostCategoriesFailed(error))
    })
  }
}

export function getPostsByCategories(categories, offset=0) {
  return function (dispatch) {
    dispatch(getPostsByCategoriesInProgress())
    const config = {
      method: 'get',
      url: apiConfig.preview ? `${apiConfig.apiHost}/posts?filter=${JSON.stringify({ limit: 6, skip: offset, where: {category : categories[0] === '' ? {like: ''} : {inq: categories} } })}` : `${apiConfig.apiHost}/posts/search`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    if (!apiConfig.preview) {
      config.params = {
        status: 'published',
        categories: categories,
        limit: 6,
        offset,
        key: apiConfig.xApiKey
      }
    }
    return axios(config).then(response => {
      dispatch(getPostsByCategoriesSuccess(response.data, offset))
    })
    .catch(error => {
      dispatch(getPostsByCategoriesFailed(error))
    })
  }
}

export function getCategoryPosts(category, offset=0, limit=6) {
  return function (dispatch) {
    dispatch(getCategoryPostsInProgress())
    const config = {
      method: 'get',
      url: apiConfig.preview ? `${apiConfig.apiHost}/posts?filter=${JSON.stringify({ limit: limit, skip: offset, where: {category : category } })}` : `${apiConfig.apiHost}/posts/search`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    if (!apiConfig.preview) {
      config.params = {
        status: 'published',
        category: category,
        limit: limit,
        offset,
        key: apiConfig.xApiKey
      }
    }
    return axios(config).then(response => {
      dispatch(getCategoryPostsSuccess(response.data, category, offset))
    })
    .catch(error => {
      dispatch(getCategoryPostsFailed(error))
    })
  }
}

export function getTagPosts(tag, offset=0, limit=6) {
  return function (dispatch) {
    dispatch(getTagPostsInProgress())
    const config = {
      method: 'get',
      url: apiConfig.preview ? `${apiConfig.apiHost}/posts?filter=${JSON.stringify({ limit: limit, skip: offset, where: {tags : tag } })}` : `${apiConfig.apiHost}/posts/search`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    if (!apiConfig.preview) {
      config.params = {
        status: 'published',
        tag,
        limit: limit,
        offset,
        key: apiConfig.xApiKey
      }
    }
    return axios(config).then(response => {
      dispatch(getTagPostsSuccess(response.data, tag, offset))
    })
    .catch(error => {
      dispatch(getTagPostsFailed(error))
    })
  }
}

export function likePost(slug) {
  return function (dispatch) {
    if(!apiConfig.preview) {
      const config = {
        method: 'post',
        url: `${apiConfig.apiHost}/posts/like`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          slug
        },
        params: {
          key: apiConfig.xApiKey
        }
      }
    return axios(config).then(response => {
      if(response) {
        dispatch(getPostData(slug, true))
      }
    })
  }
  }
}