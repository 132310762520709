import React from 'react'
import { makeStyles,Card, CardMedia } from '@material-ui/core'
import idx from 'idx'

const useStyles = makeStyles((theme) => ({
    imageBox: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(3),
        },
    },
    media: {}
}))

const ImageBox = (props) => {
    const { values, desktop } = props
    const { src, styling, altText } = values
    const classes = useStyles()

    const getValue = (key, index = 0, defaultVal) => {
        const value = idx(values, (_) => _[key][index])
        return value ? value : defaultVal ? defaultVal : ''
    }

    const deafultImageBoxStyle = {}

    const imageBoxStyle = styling && styling.imageBox ? Object.assign({}, deafultImageBoxStyle, styling.imageBox) : deafultImageBoxStyle

    return (
        <>
            {src.length > 0 && (
                <Card className={classes.imageBox} style={imageBoxStyle} elevation={0}>
                    <CardMedia component={'img'} className={classes.media} image={`${encodeURI(getValue('src', desktop ? 0 : 1))}`} alt={altText ? altText : ''} />
                </Card>
            )}
        </>
    )
}

export default ImageBox
